import { useCallback, useEffect, useState } from 'react';
import EntityDataTable from '../../components/EntityDataTable';
import Backdrop from '@mui/material/Backdrop';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from 'react-bootstrap/Modal';
import Alert from '../../components/Alert';
import axios from 'axios';

const Tenor = () => 
{
  const [isLoading, setIsLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("");

  /* LIST VARIABLES */
  const [dropdowns, setDropdowns] = useState([]);

  /* DATATABLE VARIABLES */
  const [columnData, setColumnData] = useState([]);
  const [columnDataTypes, setColumnDataTypes] = useState({});
  const [columnWidths, setColumnWidths] = useState({});
  const [rows, setRows] = useState([]);
  const [toggleDisplayData, setToggleDisplayData] = useState(false);
  
  /* DATATABLE ROW VARIABLES */
  const [rowId, setRowId] = useState(null);
  const [rowStatus, setRowStatus] = useState(null);

  /* MODAL VARIABLES */
  const [showDisableModal, setShowDisableModal] = useState(false);

  useEffect(() => 
  {
    const fetchLists = async () => 
    {
      axios({
        method: "get",
        url: "/GetBusinessUnitList"
      })
      .then((response) => 
      {
        const { status, data } = response;

        if (status === 200) 
        {
          const businessUnits = data?.business_units || [];
          setDropdowns((previousDropdowns) => ({
            ...previousDropdowns,
            Business_Unit: businessUnits
          }));
        }
      })
      .catch((error) => 
      {
        console.log("Get Business Units Api: ", error);
      });
    }

    fetchLists();
  }, []);

  useEffect(() => 
  {
    const fetchData = async () => 
    {
      setIsLoading(true);
      setShowAlert(false);
      setAlertMessage("");
      setAlertType("");
      setRowId(null);
      setRowStatus(null);

      await axios({
        method: "get",
        url: "/GetTenor"
      })
      .then((response) => 
      {
        setIsLoading(false);
        const { status, data } = response;

        if (status === 200) 
        {
          const tenorData = data?.tenor_data || [];
          const { rows: rowData = [], columns = [], data_types: dataTypes = {}, max_column_lengths: widths = {} } = tenorData;
          const columnData = columns.filter((column) => !(column === "id" || column === "is_deleted")) || [];

          setColumnData(columnData);
          setColumnDataTypes(dataTypes);
          setColumnWidths(widths);
          setRows(rowData);
        } 
        else 
        {
          setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
          setAlertType("error");
          setShowAlert(true);
        }
      })
      .catch((error) => 
      {
        console.log("Update Tenor Api: ", error);
        setIsLoading(false);
        setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
        setAlertType("error");
        setShowAlert(true);
      });
    }

    fetchData();
  }, [toggleDisplayData]);

  const handleUpdateRecord = async (editedRecord) => 
  {
    setIsLoading(true);
    setShowAlert(false);
    setAlertMessage("");
    setAlertType("");

    await axios({
      method: "post",
      url: "/UpdateTenor",
      data: editedRecord
    })
    .then((response) =>
    {
      setIsLoading(false);
      const { status } = response;

      if (status === 200) 
      {
        setToggleDisplayData(!toggleDisplayData);
      } 
      else 
      {
        setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
        setAlertType("error");
        setShowAlert(true);
      }
    })
    .catch((error) => 
    {
      console.log("Update Tenor Api: ", error);
      setIsLoading(false);
      setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
      setAlertType("error");
      setShowAlert(true);
    });
  }

  const handleAddRecord = async (addedRecord) => 
  {
    setIsLoading(true);
    setShowAlert(false);
    setAlertMessage("");
    setAlertType("");

    await axios({
      method: "post",
      url: "/AddTenor",
      data: addedRecord
    })
    .then((response) => 
    {
      setIsLoading(false);
      const { status } = response;

      if (status === 200) 
      {
        setToggleDisplayData(!toggleDisplayData);
      } 
      else 
      {
        setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
        setAlertType("error");
        setShowAlert(true);
      }
    })
    .catch((error) => 
    {
      console.log("Add Tenor Api: ", error);
      setIsLoading(false);
      setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
      setAlertType("error");
      setShowAlert(true);
    });
  }

  const handleRecordStatusChangeWrapper = (id, status) => 
  {
    setRowId(id);
    setRowStatus(status);

    if (status === 1) 
    {
      setShowDisableModal(true);
    } 
    else 
    {
      handleRecordStatusChange(id, status);
    }
  }

  const handleRecordStatusChange = useCallback(async (id, status) => 
  {
    setShowDisableModal(false);
    setIsLoading(true);
    setShowAlert(false);
    setAlertMessage("");
    setAlertType("");

    await axios({
      method: "post",
      url: "/SetTenorStatus",
      data: {
        id: rowId || id,
        status: rowStatus || status
      }
    })
    .then((response) => 
    {
      setIsLoading(false);
      const { status } = response;

      if (status === 200) 
      {
        setToggleDisplayData(!toggleDisplayData);
      } 
      else 
      {
        setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
        setAlertType("error");
        setShowAlert(true);
      }
    })
    .catch((error) => 
    {
      console.log("Tenor Status Api: ", error);
      setIsLoading(false);
      setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
      setAlertType("error");
      setShowAlert(true);
    });

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [rowId, rowStatus]);

  const handleCloseModal = () => 
  {
    setShowDisableModal(false);
  }

  return (
    <div className = "content_container d-flex flex-column container entities">
      <Alert
        show = {showAlert}
        message = {alertMessage}
        type = {alertType}
        setShow = {setShowAlert}
      />

      <Typography variant = "h5" gutterBottom className = "page_heading m-0">
        Tenors
      </Typography>

      <div>
        <Backdrop
          sx = {{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open = {isLoading}
        />

        <EntityDataTable
          title = "Tenor"
          isLoading = {isLoading}
          columnData = {columnData}
          columnDataTypes = {columnDataTypes}
          columnWidths = {columnWidths}
          rows = {rows}
          dropdowns = {dropdowns}
          files = {[]}
          isEditable = {true}
          setIsLoading = {setIsLoading}
          setShowAlert = {setShowAlert}
          setAlertMessage = {setAlertMessage}
          setAlertType = {setAlertType}
          handleUpdateRecord = {handleUpdateRecord}
          handleAddRecord = {handleAddRecord}
          handleRecordStatusChange = {handleRecordStatusChangeWrapper}       
        />
      </div>

      <Modal show = {showDisableModal} onHide = {handleCloseModal} centered = {true}>
        <Modal.Header closeButton = {true}>
          <Modal.Title>Are you sure?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span>
            Disabling the tenor will remove it from the list of available
            tenors.
          </span>
        </Modal.Body>
        <Modal.Footer className = "gap-2">
          <Button variant = "outlined" size = "small" onClick = {handleCloseModal}>
            Cancel
          </Button>
          <Button
            variant = "contained"
            size = "small"
            onClick = {handleRecordStatusChange}
          >
            Disable
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Tenor;