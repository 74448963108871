import { Fragment, createContext, forwardRef, useContext, useEffect, useMemo, useRef } from 'react';
import { useTheme, styled } from '@mui/material/styles';
import { VariableSizeList } from 'react-window';
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
import useMediaQuery from '@mui/material/useMediaQuery';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Chip from '@mui/material/Chip';
import Popper from '@mui/material/Popper';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

const icon = <CheckBoxOutlineBlankIcon fontSize = "small" />;
const checkedIcon = <CheckBoxIcon fontSize = "small" />;

function renderRow(props) 
{
    const { data, index, style } = props;
    const dataSet = data[index];
    const selected = dataSet[2]?.selected || false;
    const isMultiple = dataSet[4] || false;
    const isObject = dataSet[5] || false;
    const selectedDeal = dataSet[6] || "";
    const inlineStyle = { ...style, top: style.top, borderBottom: '1px solid #f0f0f0', padding: '0 5px', height: 'fit-content' };
    const { key, ...componentProps } = dataSet[0];

    return (
        <Typography component = "li" {...componentProps} style = {inlineStyle}>
            {isMultiple && (
                <Checkbox
                    sx = {{ paddingTop: 0, paddingBottom: 0 }}
                    icon = {icon}
                    checkedIcon = {checkedIcon}
                    checked = {selected}
                />
            )}
            {isObject ? selectedDeal ? `${selectedDeal}${dataSet[1].value}` : dataSet[1].value : dataSet[1]}
        </Typography>
    );
}

const OuterElementContext = createContext({});

const OuterElementType = forwardRef((props, ref) => 
{
    const outerProps = useContext(OuterElementContext);
    return <div ref = {ref} {...props} {...outerProps} />;
});

function useResetCache(data) 
{
    const ref = useRef(null);

    useEffect(() => 
    {
        if (ref.current !== null) 
        {
            ref.current.resetAfterIndex(0, true);
        }

    }, [data]);

    return ref;
}

const ListboxComponent = forwardRef(function ListboxComponent(props, ref) 
{
    const { children, ...other } = props;
    const itemData = [];

    children.forEach((item) => 
    {
        itemData.push(item);
        itemData.push(...(item.children || []));
    });

    const theme = useTheme();
    const smUp = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
    const itemCount = itemData.length;
    const itemSize = smUp ? 20 : 30;

    const getChildSize = () => 
    {
        return itemSize;
    };

    const getHeight = () => 
    {
        if (itemCount > 8) 
        {
            return 8 * itemSize;
        }

        return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
    };

    const gridRef = useResetCache(itemCount);

    return (
        <div ref = {ref}>
            <OuterElementContext.Provider value = {other}>
                <VariableSizeList
                    ref = {gridRef}
                    itemData = {itemData}
                    height = {getHeight() + 2}
                    width = "100%"
                    outerElementType = {OuterElementType}
                    innerElementType = "ul"
                    itemSize = {(index) => getChildSize(itemData[index])}
                    overscanCount = {5}
                    itemCount = {itemCount}
                >
                    {renderRow}
                </VariableSizeList>
            </OuterElementContext.Provider>
        </div>
    );
});

const StyledPopper = styled(Popper)(
{
    [`& .${autocompleteClasses.listbox}`]: 
    {
        boxSizing: 'border-box',
        '& ul': {
            padding: 0,
            margin: 0
        }
    }
},
({ maxLength }) => (
{
    minWidth: maxLength
}));

export default function VirtualizedAutocomplete({ isLoading = false, isMultiple = false, isObject = false, isRequired = false, isDisabled = false, filterOn = "", options = [], selectedOptions = null, selectedDeal = null, handleSelectChange = () => {} }) 
{
    const getMaxLength = useMemo(() => options.reduce((max, current) => Math.max(max, (isObject ? current?.value?.length : current?.length || 0)), 0) * 0.5 * 20 + 40, [options, isObject]);
    const PopperComponent = useMemo(() => (props) => <StyledPopper {...props} maxLength = {getMaxLength} />, [getMaxLength]);
    const inputRef = useRef(null);

    return (
        <Autocomplete
            size = "small"
            sx = {{ width: '100%' }}
            // freeSolo = {true}
            loading = {isLoading}
            forcePopupIcon = {true}
            autoHighlight = {true}
            clearOnBlur = {true}
            disableClearable = {true}
            disableCloseOnSelect = {isMultiple}
            multiple = {isMultiple}
            limitTags = {1}
            disabled = {isDisabled}
            PopperComponent = {PopperComponent}
            ListboxComponent = {ListboxComponent}
            options = {options}
            value = {selectedOptions}
            renderOption = {(props, option, state) => [props, option, state, state.index, isMultiple, isObject, selectedDeal]}
            isOptionEqualToValue = {(option, value) => 
            {
                if (isObject && option !== null && value !== null) 
                {
                    return option.id === value.id;
                }
                
                return option === value;
            }}
            getOptionLabel = {(option) => 
            {
                if (isObject && option !== null) 
                {
                    return option.value ? option.value.toString() : "";
                }

                return (option || option === 0) ? option.toString() : "";
            }}
            onChange = {(event, newValue) => 
            {
                const isInvalid = isRequired ? isMultiple ? newValue.length === 0 : !newValue : false;

                if (isInvalid) 
                {
                    inputRef?.current?.setCustomValidity("Please select at least one option.");
                } 
                else 
                {
                    inputRef?.current?.setCustomValidity("");
                }
                
                inputRef?.current?.reportValidity();  
                handleSelectChange(filterOn, newValue);
            }}
            onInputChange = {(event, newValue) => 
            {
                if (!newValue)
                {
                    handleSelectChange(filterOn, newValue)
                }
            }}
            renderTags = {(value, getTagProps) => 
            {
                const { key, ...tagProps } = getTagProps(0);

                return (
                    <>
                        <Chip
                            sx = {{ height: '22px', margin: '0 2px' }}
                            size = "small"
                            label = {value ? value[0]?.value : null}
                            {...tagProps}
                        />
                        
                        {value?.length > 1 && (
                            <span className = "MuiAutocomplete-tag MuiAutocomplete-tagSizeSmall">+{value?.length - 1}</span>
                        )}
                    </>
                );
            }}
            renderInput = {(params) => (
                <TextField 
                    { ...params } 
                    sx = {{
                        '& .MuiInputBase-input': {
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                        }
                    }} 
                    inputRef = {inputRef}
                    size = "small" 
                    margin = "dense"
                    label = {filterOn || ""}
                    required = {isRequired}
                    InputProps = {{
                        ...params.InputProps,
                        endAdornment: (
                            <Fragment>
                                {isLoading ? <CircularProgress color = "inherit" size = {20} /> : null}
                                {params.InputProps.endAdornment}
                            </Fragment>
                        )
                    }}
                />
            )}       
        />
    );
}