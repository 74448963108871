import { useState, useEffect, useRef } from 'react';
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { useLocation, useNavigate } from 'react-router-dom';
import { AiOutlineMenuFold, AiOutlineMenuUnfold } from 'react-icons/ai';
import { FaTableCells } from 'react-icons/fa6';
import { FaClipboardList, FaUser } from 'react-icons/fa';
import { RiPagesLine } from 'react-icons/ri';
import { CiLogout } from 'react-icons/ci';
import { useTab } from '../hooks/useTab';
import SidePanel from './SidePanel';
import useOutsideAlerter from '../hooks/useOutsideAlerter';
import Logo from '../images/logo.png';
import Avatar from '../images/avatar.png';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/sidebar.css';

const SideBar = ({ currentUser, component, businessUnits = [], logOut }) => 
{
  const [matches, setMatches] = useState(window.matchMedia("(max-width: 992px)").matches);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  /* TAB VARIABLES */
  const { setActiveTab, setSelectedDeal, setSelectedPFI } = useTab();

  /* MOBILE VIEW VARIABLES */
  const [collapsed, setCollapsed] = useState(false);
  const [openSidePanel, setOpenSidePanel] = useState(false);
  const [selectedMenuItem, setSelectedMenuItem] = useState(pathname);
  const toggleButtonRef = useRef(null);

  /* SUB-MENU VARIABLES */
  const [isDealSubMenuOpen, setIsDealSubMenuOpen] = useState(pathname.includes("deals"));
  const [isPolymerDealSubMenuOpen, setIsPolymerDealSubMenuOpen] = useState(pathname.includes("deals/polymers") || pathname.includes("deals/sblc"));
  const [isEntitySubMenuOpen, setIsEntitySubMenuOpen] = useState(pathname.includes("entities"));
  const [isReportSubMenuOpen, setIsReportSubMenuOpen] = useState(pathname.includes("reports"));

  /* PROFILE MENU VARIABLES */
  const [openProfileMenu, setOpenProfileMenu] = useState(false);
  const profileDropDownRef = useRef(null);
  const isOutsideProfileDropDownClick = useOutsideAlerter(profileDropDownRef);

  /* BUSINESS UNIT VARIABLES */
  const polymerBusinessUnit = businessUnits.find(businessUnit => businessUnit.value === "Polymers") || null;
  const sblcBusinessUnit = businessUnits.find(businessUnit => businessUnit.value === "SBLC") || null;

  useEffect(() => 
  {
    window
      .matchMedia("(max-width: 992px)")
      .addEventListener("change", (e) => setMatches(e.matches));
  }, []);

  useEffect(() => 
  {
    if (businessUnits.length > 0 && (pathname === "/" || (pathname.includes("deals") && !pathname.includes("entities")))) 
    {
      const tokenizedPathname = pathname.split("/");
      const selectedBusinessUnit = tokenizedPathname.length !== 3
      ? tokenizedPathname.length === 4
        ? businessUnits.find(({ value }) => value.toLowerCase() === `${tokenizedPathname?.[2]?.toLowerCase()}/${tokenizedPathname?.[3]?.toLowerCase()}`)
        : businessUnits?.[0]
      : businessUnits.find(({ value }) => value.toLowerCase() === tokenizedPathname?.[2]?.toLowerCase());

      setActiveTab("Deal No.");
      setSelectedDeal(null);

      navigate(`/deals/${selectedBusinessUnit?.value?.toLowerCase()}`, {
        state: { selectedBusinessUnit: selectedBusinessUnit }
      });
    }

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [businessUnits, pathname]);

  useEffect(() => 
  {
    const timeout = setTimeout(() => 
    {
      if (isDealSubMenuOpen && !selectedMenuItem.includes("deals")) 
      {
        setIsDealSubMenuOpen(false);
      }
      else if (isPolymerDealSubMenuOpen && !selectedMenuItem.includes("deals/polymers") && !selectedMenuItem.includes("deals/sblc"))
      {
        setIsPolymerDealSubMenuOpen(false);
      }
      else if (isEntitySubMenuOpen && !selectedMenuItem.includes("entities"))
      {
        setIsEntitySubMenuOpen(false);
      }
      else if (isReportSubMenuOpen && !selectedMenuItem.includes("reports"))
      {
        setIsReportSubMenuOpen(false);
      }
    }, 10000);

    return () => clearTimeout(timeout);
  }, [selectedMenuItem, isDealSubMenuOpen, isPolymerDealSubMenuOpen, isEntitySubMenuOpen, isReportSubMenuOpen]);

  const handleMenuClick = (menuItem, state = {}, isNotSubMenu = false) => 
  {
    setSelectedMenuItem(menuItem);
    setActiveTab("Deal No.");
    setSelectedDeal(null);
    setSelectedPFI(null);
    setOpenSidePanel(false);
    navigate(menuItem, { state });

    if (isNotSubMenu)
    {
      setIsDealSubMenuOpen(false);
      setIsPolymerDealSubMenuOpen(false);
      setIsEntitySubMenuOpen(false);
      setIsReportSubMenuOpen(false);
    }
  }

  const handleDealSubMenuClick = () =>
  {
    setIsDealSubMenuOpen(!isDealSubMenuOpen);
    setIsPolymerDealSubMenuOpen(isDealSubMenuOpen ? isPolymerDealSubMenuOpen : false);
    setIsEntitySubMenuOpen(false);
    setIsReportSubMenuOpen(false);
  }

  const handlePolymerDealSubMenuClick = () =>
  {
    setIsPolymerDealSubMenuOpen(!isPolymerDealSubMenuOpen);
    setIsEntitySubMenuOpen(false);
    setIsReportSubMenuOpen(false);
  }

  const handleEntitySubMenuClick = () =>
  {
    setIsDealSubMenuOpen(false);
    setIsPolymerDealSubMenuOpen(false);
    setIsEntitySubMenuOpen(!isEntitySubMenuOpen);
    setIsReportSubMenuOpen(false);
  }

  const handleReportSubMenuClick = () =>
  {
    setIsDealSubMenuOpen(false);
    setIsPolymerDealSubMenuOpen(false);
    setIsEntitySubMenuOpen(false);
    setIsReportSubMenuOpen(!isReportSubMenuOpen);
  }

  const handleToggleProfileMenu = () => 
  {
    setOpenProfileMenu(!openProfileMenu);
  }

  const handleToggleSidePanel = () => 
  {
    setOpenSidePanel(!openSidePanel);
  }

  const handleLogoClick = () => 
  {
    setActiveTab("Deal No.");
    setSelectedDeal(null);
    navigate("/");
  }

  const handleLogOut = (event) =>
  {
    event.preventDefault();

    setActiveTab("Deal No.");
    setSelectedDeal(null);
    logOut();
  }

  return (
    <div className = "d-flex">
      {matches ? (
        <SidePanel
          currentUser = {currentUser}
          toggleButtonRef = {toggleButtonRef}
          activeTab = {pathname}
          openSidePanel = {openSidePanel}
          businessUnits = {businessUnits}
          polymerBusinessUnit = {polymerBusinessUnit}
          sblcBusinessUnit = {sblcBusinessUnit}
          isDealSubMenuOpen = {isDealSubMenuOpen}
          isPolymerDealSubMenuOpen = {isPolymerDealSubMenuOpen}
          isEntitySubMenuOpen = {isEntitySubMenuOpen}
          isReportSubMenuOpen = {isReportSubMenuOpen}
          setOpenSidePanel = {setOpenSidePanel}
          handleMenuClick = {handleMenuClick}
          handleDealSubMenuClick = {handleDealSubMenuClick}
          handlePolymerDealSubMenuClick = {handlePolymerDealSubMenuClick}
          handleEntitySubMenuClick = {handleEntitySubMenuClick}
          handleReportSubMenuClick = {handleReportSubMenuClick}
        />
      ) : (
        <Sidebar collapsed = {collapsed}>
          <div className = "logo_section">
            <img
              src = {Logo}
              alt = "Engro"
              className = "logo"
              onClick = {handleLogoClick}
            />
          </div>

          <Menu className = "mt-3">
            <SubMenu
              icon = {<FaClipboardList />}
              label = "Deals"
              open = {isDealSubMenuOpen || pathname === "/" || pathname.includes("/deals")}
              onOpenChange = {handleDealSubMenuClick}
            >
              {businessUnits.filter(businessUnit => !["Polymers", "SBLC"].includes(businessUnit.value)).map((businessUnit) => (
                  <MenuItem
                    key = {businessUnit.id}
                    active = {pathname === `/deals/${businessUnit.value.toLowerCase()}`}
                    onClick = {() => handleMenuClick(`/deals/${businessUnit.value.toLowerCase()}`, { selectedBusinessUnit: businessUnit })}
                  >
                    {businessUnit.value}
                  </MenuItem>
              ))}

              <SubMenu
                label = "Polymers"
                open = {isPolymerDealSubMenuOpen || pathname.includes("deals/polymers") || pathname.includes("deals/sblc")}
                onOpenChange = {handlePolymerDealSubMenuClick}
              >
                <MenuItem
                  key = {polymerBusinessUnit?.id}
                  active = {pathname === "/deals/polymers"}
                  onClick = {() => handleMenuClick("/deals/polymers", { selectedBusinessUnit: polymerBusinessUnit })}
                >
                  Polymers
                </MenuItem> 

                <MenuItem
                  key = {sblcBusinessUnit?.id}
                  active = {pathname === "/deals/sblc"}
                  onClick = {() => handleMenuClick("/deals/sblc", { selectedBusinessUnit: sblcBusinessUnit })}
                >
                  SBLC
                </MenuItem> 
              </SubMenu>
            </SubMenu>

            {currentUser?.role === "Administrator" && (
              <>
                <SubMenu
                  icon = {<FaTableCells />}
                  label = "Entities"
                  open = {isEntitySubMenuOpen || pathname?.includes("entities")}
                  onOpenChange = {handleEntitySubMenuClick}
                >
                  <MenuItem
                    active = {pathname === "/entities/deals"}
                    onClick = {() => handleMenuClick("/entities/deals")}
                  >
                    Deals
                  </MenuItem>

                  <MenuItem
                    active = {pathname === "/entities/deal-pfis"}
                    onClick = {() => handleMenuClick("/entities/deal-pfis")}
                  >
                    Deal PFIs
                  </MenuItem>

                  <MenuItem
                    active = {pathname === "/entities/banks"}
                    onClick = {() => handleMenuClick("/entities/banks")}
                  >
                    Banks
                  </MenuItem>

                  <MenuItem
                    active = {pathname === "/entities/products"}
                    onClick = {() => handleMenuClick("/entities/products")}
                  >
                    Products
                  </MenuItem>

                  <MenuItem
                    active = {pathname === "/entities/SBLC"}
                    onClick = {() => handleMenuClick("/entities/SBLC")}
                  >
                    SBLC
                  </MenuItem>

                  <MenuItem
                    active = {pathname === "/entities/vendors"}
                    onClick = {() => handleMenuClick("/entities/vendors")}
                  >
                    Vendors
                  </MenuItem>

                  <MenuItem
                    active = {pathname === "/entities/trader"}
                    onClick = {() => handleMenuClick("/entities/trader")}
                  >
                    Traders
                  </MenuItem>

                  <MenuItem
                    active = {pathname === "/entities/tenor"}
                    onClick = {() => handleMenuClick("/entities/tenor")}
                  >
                    Tenors
                  </MenuItem>

                  <MenuItem
                    active = {pathname === "/entities/dropdowns"}
                    onClick = {() => handleMenuClick("/entities/dropdowns")}
                  >
                    Dropdowns
                  </MenuItem>
                </SubMenu>

                <SubMenu
                  icon = {<RiPagesLine />}
                  label = "Reports"
                  open = {isReportSubMenuOpen || pathname?.includes("reports")}
                  onOpenChange = {handleReportSubMenuClick}
                >
                  <MenuItem
                    active = {pathname === "/reports/deal-report"}
                    onClick = {() => handleMenuClick("/reports/deal-report", { businessUnits: businessUnits, selectedReport: "Deal Report" })}
                  >
                    Deal Report
                  </MenuItem>

                  <MenuItem
                    active = {pathname === "/reports/finance-report"}
                    onClick = {() => handleMenuClick("/reports/finance-report", { businessUnits: businessUnits, selectedReport: "Finance Report" })}
                  >
                    Finance Report
                  </MenuItem>

                  <MenuItem
                    active = {pathname === "/reports/morning-report"}
                    onClick = {() => handleMenuClick("/reports/morning-report", { businessUnits: businessUnits, selectedReport: "Morning Report" })}
                  >
                    Morning Report
                  </MenuItem>
                </SubMenu>
                
                <MenuItem
                  icon = {<FaUser />}
                  active = {pathname === "/create-user"}
                  onClick = {() => handleMenuClick("/create-user", {}, true)}
                  className = "single_dropdown"
                >
                  Create User
                </MenuItem>
              </>
            )}
          </Menu>
        </Sidebar>
      )}

      <div className = {`content_section ${collapsed ? "collapsed" : ""}`}>
        {matches && openSidePanel && <div className = "overlay" />}

        <div className = {`top_header ${collapsed ? "collapsed" : ""}`}>
          {matches ? (
            <>
              <button
                ref = {toggleButtonRef}
                className = "openbtn"
                onClick = {handleToggleSidePanel}
              >
                ☰
              </button>

              <div className = "logo_section">
                <img
                  src = {Logo}
                  alt = "logo"
                  className = "logo"
                  onClick = {handleLogoClick}
                />
              </div>
            </>
          ) : (
            <button
              className = "btn text-white menu-button m-0 d-flex align-items-center"
              onClick = {() => setCollapsed(!collapsed)}
            >
              {collapsed ? (
                <AiOutlineMenuUnfold className = "header-icon" />
              ) : (
                <AiOutlineMenuFold className = "header-icon" />
              )}
              Menu
            </button>
          )}

          {currentUser && (
            <div className = "profile-container">
              {!matches && (
                <div className = "user-details">
                  <span>{currentUser?.name}</span>
                  <small>{currentUser?.role}</small>
                </div>
              )}

              <div className = "action" ref = {profileDropDownRef}>
                <div className = "profile" onClick = {handleToggleProfileMenu}>
                  <img src = {Avatar} alt = "User" />
                </div>

                <div className = {`menu ${openProfileMenu && !isOutsideProfileDropDownClick ? "active" : ""}`}>
                  <ul>
                    <li>
                      <CiLogout className = "icon" />

                      <a
                        href = "#/"
                        onClick = {handleLogOut}
                      >
                        Logout
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className = "content_inner">{component ? component : ""}</div>
      </div>
    </div>
  );
}

export default SideBar;