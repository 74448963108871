import { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import FileUploader from '../../components/FileUploader';
import VirtualizedAutocomplete from '../../components/Autocomplete';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ReportDownloader from '../../components/ReportDownloader';
import AddIcon from '@mui/icons-material/Add';
import Modal from 'react-bootstrap/Modal';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import axios from 'axios';
import dayjs from 'dayjs';

const Documentation = forwardRef(({ isLoading, selectedPFI, setIsUpdated, setActiveTab, setIsLoading, setShowAlert, setAlertMessage, setAlertType }, ref) => 
{
  const location = useLocation();
  const { selectedBusinessUnit } = location.state || {};
  const { isFoodsOrPolymersDeal, isSBLCDeal } = useMemo(() => 
  {
    const id = selectedBusinessUnit?.id;
    
    return {
      isFoodsOrPolymersDeal: [1, 4].includes(id),
      isSBLCDeal: id === 6,
    };

  }, [selectedBusinessUnit]);

  /* FILTER VARIABLES */
  const [tranches, setTranches] = useState([]);
  const [selectedTranche, setSelectedTranche] = useState(null);
  const [toggleFilterData, setToggleFilterData] = useState(false);

  /* LIST VARIABLES */
  const [coos, setCOOs] = useState([]);
  const receivedPayments = useMemo(() => ["Yes", "No"], []);
  const discountingOptions = useMemo(() => ["Yes", "No"], []);
  const discrepancyAcceptanceSwifts = useMemo(() => ["Yes", "No"], []);
  const buyerDocumentStatus = useMemo(() => ["Received - Clean", "Received - Discrepant"], []);
  const sellerDocumentStatus = useMemo(() => ["Dispatched - Clean", "Dispatched - Discrepant"], []);
  const buyingFileTypes = useMemo(() => [
    "Internal Approvals", 
    "Supplier Contract", 
    "PFI", 
    "Slave LC Draft", 
    "Slave LC Transmitted", 
    "Amendments", 
    "Draft BL", 
    "Original BL", 
    "Commercial Documents", 
    "Other Certificates and Documents", 
    "Bank Documents/Swift Messages"
  ], []);
  const sellingFileTypes = useMemo(() => [
    "Internal Approvals", 
    "Customer Contract", 
    "PFI", 
    "Master LC Draft", 
    "Master LC Transmitted", 
    "Amendments", 
    "Draft BL", 
    "Original BL", 
    "Commercial Documents", 
    "Other Certificates and Documents", 
    "Bank Documents/Swift Messages"
  ], []);

  /* SELECTED LIST VARIABLES */
  const [selectedCOO, setSelectedCOO] = useState(null);

  /* FILE VARIABLES */
  const [files, setFiles] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [toggleFileData, setToggleFileData] = useState(false);

  /* FORM VARIABLES */
  const dataSchema = {
    AWB_from_Supplier: "",
    Supplier_Document_Receiving_Date: null,
    Disc_Acceptance: "",
    Received_Payment: "",
    AWB_To_Customer: "",
    Dispatch_Date: null,
    Discounting_Required: "",
    Supplier_Status: "",
    Supplier_Discrepancy_Reason: "",
    Supplier_Discrepancy_Acceptance_Swift: "",
    Customer_Status: "",
    Customer_Discrepancy_Reason: "",
    Customer_Discrepancy_Acceptance_Swift: "",
    Special_Requirement: "",
    Target_Customer_PFI_Issuance_Date: null,
    Target_Supplier_LC_Issuance_Date: null,
    Target_Customer_NN_Documents: null,
    Target_Supplier_NN_Documents: null,
    Target_Supplier_Original_Documents: null,
    Target_Customer_Original_Documents: null,
    Customer_LC_Deadline: null
  };
  const [initialFormData, setInitialFormData] = useState(dataSchema);
  const [formData, setFormData] = useState(dataSchema);
  const [isExistingDocumentation, setIsExistingDocumentation] = useState(false);
  const [isDataUpdated, setIsDataUpdated] = useState(false);
  const [isFormSaved, setIsFormSaved] = useState(false);
  const [isNewForm, setIsNewForm] = useState(false);
  const [isFormSavedOrDeleted, setIsFormSavedOrDeleted] = useState(false);
  const formRef = useRef(null);
  const submitButtonRef = useRef(null);

  /* DIALOG VARIABLES */
  const [showWarningDialog, setShowWarningDialog] = useState(false);
  
  /* MODAL VARIABLES */
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);

  useEffect(() => 
  {
    const fetchCOOLists = async () => 
    {
      axios({
        method: "post",
        url: "/GetCertificatesOfOrigin",
        data: {
          Tranche_Id: selectedTranche?.id
        }
      })
      .then((response) => 
      {
        const { status, data } = response;

        if (status === 200) 
        {
          setCOOs(data);
        }
      })
      .catch((error) => 
      {
        console.log("Get cooOptionsList Api: ", error);
      });
    }

    if (selectedTranche?.id) 
    {
      fetchCOOLists();
    }
  }, [selectedTranche]);
  
  useEffect(() => 
  {
    if (!isFormSavedOrDeleted)
    {
      // Check if form data is different from the initial values
      const isFormUpdated = Object.keys(initialFormData).some((key) => 
      {
        const formDataValue = formData[key];
        const initialFormDataValue = initialFormData[key];

        return (JSON.stringify(formDataValue) !== JSON.stringify(initialFormDataValue));
      });

      setIsUpdated(isFormUpdated);
    }

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [formData]);

  useEffect(() => 
  {
    const fetchFilters = async () => 
    {
      setIsLoading(true);
  
      await axios({
        method: "post",
        url: "/GetDocumentationDetailFilters",
        data: { Deal_PFI_Id: selectedPFI }
      })
      .then((response) => 
      {
        setIsLoading(false);
        const { status, data } = response;
  
        if (status === 200) 
        {
          if (isNewForm) 
          {
            setIsNewForm(false);
          }
  
          const tranches = data?.tranches || [];
  
          if (tranches.length > 0) 
          {
            if (formData?.Tranche_Name)
            {
              const latestTranche = tranches.find(tranche => tranche.value === formData?.Tranche_Name);
              setSelectedTranche(latestTranche);
            }
            else
            {
              setSelectedTranche(tranches[0]);
            }
          }
  
          setTranches(tranches);
        } 
        else if (status === 400)
        {
          setShowAlert(true);
          setAlertMessage(data?.error || "An error occurred while processing your request. Please try again later or contact the site administrator.");
          setAlertType("error");
  
          if (data?.is_deal_locked)
          {
            setIsUpdated(false);
            setActiveTab("Deal No.");
          }
        }
        else 
        {
          setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
          setAlertType("error");
          setShowAlert(true);
        }
      })
      .catch((error) => 
      {
        console.log("Get Documentation Detail Filters Api: ", error);
        setIsLoading(false);
        setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
        setAlertType("error");
        setShowAlert(true);
      });
    }
    
    if (selectedPFI) 
    {
      fetchFilters();
    }

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [selectedPFI, toggleFilterData]);

  useEffect(() => 
  {
    const fetchDocumentationData = async () => 
    {
      setIsLoading(true);
      setShowAlert(false);
      setAlertMessage("");
      setAlertType("");
      
      await axios({
        method: "post",
        url: "/GetDocumentation",
        data: {
          Deal_PFI_Id: selectedPFI,
          Tranche_Id: selectedTranche?.id || ""
        }
      })
      .then((response) => 
      {
        setIsLoading(false);
        const { status, data } = response;

        if (status === 200)
        {
          const isEmpty = typeof data === "object" ? selectedTranche ? false : true : true;
          
          if (!isEmpty) 
          {
            setIsExistingDocumentation(true);
          } 
          else 
          {
            setIsNewForm(true);
          }

          let updatedData = { ...data };
          
          updatedData["Supplier_Document_Receiving_Date"] = updatedData["Supplier_Document_Receiving_Date"] ? dayjs(updatedData["Supplier_Document_Receiving_Date"]).format("MM/DD/YYYY") : updatedData["Supplier_Document_Receiving_Date"];
          updatedData["Dispatch_Date"] = updatedData["Dispatch_Date"] ? dayjs(updatedData["Dispatch_Date"]).format("MM/DD/YYYY") : updatedData["Dispatch_Date"];
          updatedData["Target_Customer_PFI_Issuance_Date"] = updatedData["Target_Customer_PFI_Issuance_Date"] ? dayjs(updatedData["Target_Customer_PFI_Issuance_Date"]).format("MM/DD/YYYY") : updatedData["Target_Customer_PFI_Issuance_Date"];
          updatedData["Target_Supplier_LC_Issuance_Date"] = updatedData["Target_Supplier_LC_Issuance_Date"] ? dayjs(updatedData["Target_Supplier_LC_Issuance_Date"]).format("MM/DD/YYYY") : updatedData["Target_Supplier_LC_Issuance_Date"];
          updatedData["Target_Customer_NN_Documents"] = updatedData["Target_Customer_NN_Documents"] ? dayjs(updatedData["Target_Customer_NN_Documents"]).format("MM/DD/YYYY") : updatedData["Target_Customer_NN_Documents"];
          updatedData["Target_Supplier_NN_Documents"] = updatedData["Target_Supplier_NN_Documents"] ? dayjs(updatedData["Target_Supplier_NN_Documents"]).format("MM/DD/YYYY") : updatedData["Target_Supplier_NN_Documents"];
          updatedData["Target_Supplier_Original_Documents"] = updatedData["Target_Supplier_Original_Documents"] ? dayjs(updatedData["Target_Supplier_Original_Documents"]).format("MM/DD/YYYY") : updatedData["Target_Supplier_Original_Documents"];
          updatedData["Target_Customer_Original_Documents"] = updatedData["Target_Customer_Original_Documents"] ? dayjs(updatedData["Target_Customer_Original_Documents"]).format("MM/DD/YYYY") : updatedData["Target_Customer_Original_Documents"];
          updatedData["Customer_LC_Deadline"] = updatedData["Customer_LC_Deadline"] ? dayjs(updatedData["Customer_LC_Deadline"]).format("MM/DD/YYYY") : updatedData["Customer_LC_Deadline"];
          
          if (selectedTranche) 
          {
            updatedData["Tranche_Name"] = selectedTranche?.value;
          }

          setInitialFormData(JSON.parse(JSON.stringify(updatedData)));
          setFormData(updatedData);
        } 
        else if (status === 400)
        {
          setShowAlert(true);
          setAlertMessage(data?.error || "An error occurred while processing your request. Please try again later or contact the site administrator.");
          setAlertType("error");
  
          if (data?.is_deal_locked)
          {
            setIsUpdated(false);
            setActiveTab("Deal No.");
          }
        }
        else 
        {
          setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
          setAlertType("error");
          setShowAlert(true);
        }
      })
      .catch((error) => 
      {
        console.log("Get Documentation Data Api: ", error);
        setIsLoading(false);
        setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
        setAlertType("error");
        setShowAlert(true);
      });
    }

    if (selectedPFI && ((selectedTranche && !isNewForm) || (!selectedTranche && isNewForm))) 
    {
      fetchDocumentationData();
    }

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [selectedPFI, selectedTranche, isNewForm]);

  useEffect(() =>
  {
    const fetchDocumentationFiles = async () =>
    {
      await axios({
        method: "post",
        url: "/GetDocument",
        data: {
          Deal_PFI_Id: selectedPFI,
          TrancheID: selectedTranche?.id
        }
      })
      .then((response) => 
      {
        setIsLoading(false);
        const { status, data } = response;

        if (status === 200) 
        {
          const files = data?.map((file) => (
          {
            file: {
              name: file.File_Name,
              id: file.File_Id,
              location: file.File_Location
            },
            docName: file.Doc_Type
          })) || [];

          setFiles(files);
        } 
        else if (status === 400)
        {
          setShowAlert(true);
          setAlertMessage(data?.error || "An error occurred while processing your request. Please try again later or contact the site administrator.");
          setAlertType("error");
  
          if (data?.is_deal_locked)
          {
            setIsUpdated(false);
            setActiveTab("Deal No.");
          }
        }
        else 
        {
          setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
          setAlertType("error");
          setShowAlert(true);
        }
      })
      .catch((error) => 
      {
        console.log("Get Documentation Documents Api: ", error);
        setIsLoading(false);
        setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
        setAlertType("error");
        setShowAlert(true);
      });
    }

    if (selectedPFI && selectedTranche) 
    {
      fetchDocumentationFiles();
    }

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [selectedPFI, selectedTranche, isNewForm, toggleFileData]);
  
  useImperativeHandle(ref, () => ({
    handleSubmit
  }));

  const convertToDayJsObject = (date) => 
  {
    const convertedDate = date ? dayjs(date, "MM/DD/YYYY") : null;
    return convertedDate;
  }

  const handleUpload = async (Tranche_Id) => 
  {
    setIsLoading(true);
    setShowAlert(false);
    setAlertMessage("");
    setAlertType("");

    const fileFormData = new FormData();
    const TrancheID = Tranche_Id || selectedTranche?.value;

    for (let i = 0; i < uploadedFiles.length; i++) 
    {
      const file = uploadedFiles[i];
      const fileObj = {
        DealPFIId: selectedPFI,
        TrancheID: TrancheID || selectedTranche?.id,
        fileName: file.name,
        docType: `Documentation - ${TrancheID}`,
        fileExtension: file.name.split(".").pop()
      };

      fileFormData.append(`file${i}`, JSON.stringify(fileObj));
      fileFormData.append(`file${i}`, file);
    }

    await axios({
      method: "post",
      url: "/UploadDocument",
      data: fileFormData
    })
    .then((response) => 
    {
      setIsLoading(false);
      const { status } = response;

      if (status === 200) 
      {
        setAlertMessage("Data saved successfully.");
        setAlertType("success");
        setShowAlert(true);
      
        setTimeout(() => 
        {
          if (isNewForm) 
          {
            setToggleFilterData(!toggleFilterData);
          }

          setUploadedFiles([]);
          setIsExistingDocumentation(true);
          setIsDataUpdated(false);
          setIsFormSaved(true);
          setToggleFileData(!toggleFileData);
        }, 2000);
      } 
      else 
      {
        setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
        setAlertType("error");
        setShowAlert(true);
      }
    })
    .catch((error) => 
    {
      console.log("Upload Documentation Documents Api: ", error);
      setIsLoading(false);
      setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
      setAlertType("error");
      setShowAlert(true);
    });
  }

  const handleSelectTranche = (newValue) =>
  {
    setSelectedTranche(newValue);
    setIsNewForm(false);
    setFormData(dataSchema);
  }

  const handleChange = (field, value, isDate = false) => 
  {
    let newValue = value;

    if (isDate)
    {
      newValue = dayjs(newValue).format("MM/DD/YYYY");
    }

    setFormData((previousFormData) => ({
      ...previousFormData,
      [field]: typeof newValue === "string" ? newValue.trim() === "" ? null : newValue : newValue ? newValue : null
    }));

    if (isExistingDocumentation) 
    {
      setIsDataUpdated(true);
    }

    setIsFormSaved(false);
  }

  const handleSubmit = async (event) => 
  {
    event?.preventDefault();

    setIsLoading(true);
    setShowAlert(false);
    setAlertMessage("");
    setAlertType("");

    let updatedFormData = {
      ...formData,
      Deal_PFI_Id: selectedPFI,
      ...(!isExistingDocumentation && { Deal_PFI_Id: selectedPFI })
    };

    updatedFormData = Object.fromEntries(Object.entries(updatedFormData).map(([key, value]) => [
      key,
      typeof value === "object" && value?.hasOwnProperty("value") ? value.id : value
    ]));

    const apiUrl = isExistingDocumentation ? "/UpdateDocumentation" : "/AddDocumentation";

    await axios({
      method: "post",
      url: apiUrl,
      data: updatedFormData
    })
    .then((response) => 
    {
      const { status, data } = response;

      if (status === 200) 
      {
        if (!isExistingDocumentation) 
        {
          setToggleFilterData(!toggleFilterData);
        } 
        else 
        {
          // Update tranches with the new tranche name if it is different
          setTranches((previousTranches) => 
          {
            let updatedTranches = previousTranches.map((tranche) => 
            {
              if (tranche.id === selectedTranche?.id && tranche.value !== formData?.Tranche_Name) 
              {
                return { ...tranche, value: formData?.Tranche_Name };
              }

              return tranche;
            });

            // Set selected tranche value if updated
            if (selectedTranche?.id && formData?.Tranche_Name) 
            {
              const selectedTrancheUpdated = updatedTranches.find((tranche) => tranche.id === selectedTranche.id);
              
              if (selectedTrancheUpdated?.value !== selectedTranche.value) 
              {
                setSelectedTranche({
                  ...selectedTranche,
                  value: formData?.Tranche_Name
                });
              }
            }

            return updatedTranches;
          });
        }

        if (uploadedFiles.length > 0) 
        {
          const Tranche_Id = response?.data?.Tranche_Id || selectedTranche?.id;

          if (Tranche_Id) 
          {
            handleUpload(Tranche_Id);
          }
        } 
        else 
        {
          setIsLoading(false);
          setAlertMessage("Data saved successfully.");
          setAlertType("success");
          setShowAlert(true);

          setTimeout(() => 
          {
            if (isNewForm) 
            {
              setToggleFilterData(!toggleFilterData);
            }

            setUploadedFiles([]);
            setIsExistingDocumentation(true);
            setIsDataUpdated(false);
            setIsFormSaved(true);
            setToggleFileData(!toggleFileData);
          }, 2000);
        }

        setIsFormSavedOrDeleted(true);
        setIsUpdated(false);
      } 
      else if (status === 400)
      {
        setShowAlert(true);
        setAlertMessage(data?.error || "An error occurred while processing your request. Please try again later or contact the site administrator.");
        setAlertType("error");

        if (data?.is_deal_locked)
        {
          setIsUpdated(false);
          setActiveTab("Deal No.");
        }
      }
      else 
      {
        setIsLoading(false);
        setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
        setAlertType("error");
        setShowAlert(true);
      }
    })
    .catch((error) => 
    {
      console.log("Add/Update Documentation Data Api: ", error);
      setIsLoading(false);
      setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
      setAlertType("error");
      setShowAlert(true);
    });
  }

  const handleAddTrancheWrapper = () => 
  {
    const isEmpty = Object.entries(formData)?.every(([key, value]) => value === "" || value === null || value === undefined) && uploadedFiles.length === 0;
    const isAnyFormFieldFilled = Object.values(formData).some(value => 
    {
      if (Array.isArray(value)) 
      {
        return value.some(nestedObject => Object.values(nestedObject).some(nestedValue => Boolean(nestedValue)));
      }
      
      return Boolean(value);
    });

    if (!isAnyFormFieldFilled)
    {
      setShowWarningDialog(true);
    }
    else if (!isEmpty && !isFormSaved && (!isExistingDocumentation || isDataUpdated)) 
    {
      setShowAddModal(true);
    } 
    else 
    {
      handleAddTranche();
    }
  }

  const handleAddTranche = () => 
  {
    setShowAddModal(false);

    const resetFormData = {
      AWB_from_Supplier: "",
      Supplier_Document_Receiving_Date: null,
      Disc_Acceptance: "",
      Received_Payment: "",
      AWB_To_Customer: "",
      Dispatch_Date: null,
      Discounting_Required: "",
      Supplier_Status: null,
      Supplier_Discrepancy_Reason: "",
      Supplier_Discrepancy_Acceptance_Swift: "",
      Customer_Status: null,
      Customer_Discrepancy_Reason: "",
      Customer_Discrepancy_Acceptance_Swift: "",
      Special_Requirement: "",
      Target_Customer_PFI_Issuance_Date: null,
      Target_Supplier_LC_Issuance_Date: null,
      Target_Customer_NN_Documents: null,
      Target_Supplier_NN_Documents: null,
      Target_Supplier_Original_Documents: null,
      Target_Customer_Original_Documents: null,
      Customer_LC_Deadline: null
    };
    formRef?.current?.reset();

    setSelectedTranche(null);
    setFiles([]);
    setUploadedFiles([]);
    setInitialFormData(JSON.parse(JSON.stringify(resetFormData)));
    setFormData(resetFormData);
    setIsExistingDocumentation(false);
    setIsDataUpdated(false);
    setIsNewForm(true);
    setIsUpdated(false);
  }

  const handleDelete = async () => 
  {
    setShowDeleteModal(false);
    setIsLoading(true);
    setShowAlert(false);
    setAlertMessage("");
    setAlertType("");

    const fileIds = files.map((file) => file.file.id);

    await axios({
      method: "post",
      url: "/DeleteDocumentation",
      data: {
        Deal_PFI_Id: selectedPFI,
        Tranche_Id: selectedTranche?.id
      }
    })
    .then(async (response) => 
    {
      const { status, data } = response;

      if (status === 200) 
      {
        await axios({
          method: "post",
          url: "/DeleteDocument",
          data: { fileIds }
        })
        .then((response) => 
        {
          setIsLoading(false);
          const { status } = response;

          if (status === 200) 
          {
            const updatedtranches = tranches.filter((tranche) => tranche?.id !== selectedTranche?.id) || [];
            formRef?.current?.reset();

            if (updatedtranches.length > 0) 
            {
              setSelectedTranche(updatedtranches[0]);
            } 
            else 
            {
              setSelectedTranche(null);
            }

            setTranches(updatedtranches);
            setFormData((previousFormData) => ({
              ...previousFormData,
              AWB_from_Supplier: "",
              Supplier_Document_Receiving_Date: null,
              Disc_Acceptance: "",
              Received_Payment: "",
              AWB_To_Customer: "",
              Dispatch_Date: null,
              Discounting_Required: "",
              Supplier_Status: "",
              Supplier_Discrepancy_Reason: "",
              Supplier_Discrepancy_Acceptance_Swift: "",
              Customer_Status: "",
              Customer_Discrepancy_Reason: "",
              Customer_Discrepancy_Acceptance_Swift: "",
              Special_Requirement: "",
              Target_Customer_PFI_Issuance_Date: null,
              Target_Supplier_LC_Issuance_Date: null,
              Target_Customer_NN_Documents: null,
              Target_Supplier_NN_Documents: null,
              Target_Supplier_Original_Documents: null,
              Target_Customer_Original_Documents: null,
              Customer_LC_Deadline: null
            }));
            setFiles([]);
            setUploadedFiles([]);
            setIsExistingDocumentation(false);
            setIsDataUpdated(false);
            setIsFormSaved(false);
            setIsFormSavedOrDeleted(true);
            setIsUpdated(false);
          } 
          else 
          {
            setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
            setAlertType("error");
            setShowAlert(true);
          }
        })
        .catch((error) => 
        {
          console.log("Delete Documentation Documents Api: ", error);
          setIsLoading(false);
          setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
          setAlertType("error");
          setShowAlert(true);
        });
      } 
      else if (status === 400)
      {
        setShowAlert(true);
        setAlertMessage(data?.error || "An error occurred while processing your request. Please try again later or contact the site administrator.");
        setAlertType("error");

        if (data?.is_deal_locked)
        {
          setIsUpdated(false);
          setActiveTab("Deal No.");
        }
      }
      else 
      {
        setIsLoading(false);
        setAlertMessage(data?.data || "");
        setAlertType("error");
        setShowAlert(true);
      }
    })
    .catch((error) => 
    {
      console.log("Delete Documentation Data Api: ", error);
      setIsLoading(false);
      setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
      setAlertType("error");
      setShowAlert(true);
    });
  }  

  const handleResetSelectedCOO = () => 
  {
    setSelectedCOO(null);
  }

  const handleCloseDialog = () => 
  {
    setShowWarningDialog(false);
  }
  
  const handleCloseModal = () => 
  {
    setShowDeleteModal(false);
    setShowAddModal(false);
  }

  return (
    <div>
      <div className = "d-flex align-items-center justify-content-between gap-2 w-100">
        <Typography variant = "h5" gutterBottom className = "page_heading m-0">
          Documentation Information
        </Typography>

        <div className = "report-buttons d-flex align-items-center gap-1">
          <ReportDownloader
            apiMethod = "post"
            api = "GeneratePFI"
            apiData = {{
              Deal_PFI_Id: selectedPFI,
              Tranche_Id: selectedTranche?.id,
              is_foods_and_polymers_deal: isFoodsOrPolymersDeal,
              is_sblc_deal: isSBLCDeal
            }}
            fileName = "PFI"
            buttonText = "Generate PFI"
            setIsLoading = {setIsLoading}
            setShowAlert = {setShowAlert}
            setAlertMessage = {setAlertMessage}
            setAlertType = {setAlertType}
          />

          <ReportDownloader
            apiMethod = "post"
            api = "GenerateSI"
            apiData = {{
              Deal_PFI_Id: selectedPFI,
              Tranche_Id: selectedTranche?.id,
              is_foods_and_polymers_deal: isFoodsOrPolymersDeal,
              is_sblc_deal: isSBLCDeal
            }}
            fileName = "SI"
            buttonText = "Generate SI"
            isDisabled  =  {!selectedTranche}
            setIsLoading = {setIsLoading}
            setShowAlert = {setShowAlert}
            setAlertMessage = {setAlertMessage}
            setAlertType = {setAlertType}
          />

          <ReportDownloader
            apiMethod = "post"
            api = "GenerateCI"
            apiData = {{
              Deal_PFI_Id: selectedPFI,
              Tranche_Id: selectedTranche?.id,
              is_foods_and_polymers_deal: isFoodsOrPolymersDeal,
              is_sblc_deal: isSBLCDeal
            }}
            buttonText = "Generate CI"
            fileName = "CI"
            isDisabled = {!selectedTranche}
            setIsLoading = {setIsLoading}
            setShowAlert = {setShowAlert}
            setAlertMessage = {setAlertMessage}
            setAlertType = {setAlertType}
          />

          <ReportDownloader
            apiMethod = "post"
            api = "GenerateSA"
            apiData = {{
              Deal_PFI_Id: selectedPFI,
              Tranche_Id: selectedTranche?.id,
              is_foods_and_polymers_deal: isFoodsOrPolymersDeal,
              is_sblc_deal: isSBLCDeal
            }}
            buttonText = "Generate SA"
            fileName = "SA"
            isDisabled = {!selectedTranche}
            setIsLoading = {setIsLoading}
            setShowAlert = {setShowAlert}
            setAlertMessage = {setAlertMessage}
            setAlertType = {setAlertType}
          />

          <ReportDownloader
            apiMethod = "post"
            api = "GeneratePL"
            apiData = {{
              Deal_PFI_Id: selectedPFI,
              Tranche_Id: selectedTranche?.id,
              is_foods_and_polymers_deal: isFoodsOrPolymersDeal,
              is_sblc_deal: isSBLCDeal
            }}
            buttonText = "Generate PL"
            fileName = "PL"
            isDisabled = {!selectedTranche}
            setIsLoading = {setIsLoading}
            setShowAlert = {setShowAlert}
            setAlertMessage = {setAlertMessage}
            setAlertType = {setAlertType}
          />

          <ReportDownloader
            apiMethod = "post"
            api = "GenerateOtherCertificates"
            apiData = {{
              Deal_PFI_Id: selectedPFI,
              Tranche_Id: selectedTranche?.id,
              COO_ID: selectedCOO?.id
            }}
            buttonText = "Generate Other Certificate"
            fileName = "COO"
            modalTitle = "Select COO"
            modalContent = {
              <VirtualizedAutocomplete
                isMultiple = {false}
                isObject = {true}
                filterOn = "Select COO"
                options = {coos}
                selectedOptions = {selectedCOO || null}
                handleSelectChange = {(filterOn, newValue) => setSelectedCOO(newValue)}
              />
            }
            isDisabled  =  {!selectedTranche}
            isModalRequired = {true}
            isButtonDisabled = {!selectedCOO}
            handleResetSelectedCOO = {handleResetSelectedCOO}
            setIsLoading = {setIsLoading}
            setShowAlert = {setShowAlert}
            setAlertMessage = {setAlertMessage}
            setAlertType = {setAlertType}
          />
        </div>
      </div>

      <div className = "input-fields my-2 p-2">
        <div className = "d-flex justify-content-end align-items-center my-4">
          <div className = "multi-form autocomplete">
            <VirtualizedAutocomplete
              isMultiple = {false}
              isObject = {true}
              filterOn = "Select Tranche Name"
              options = {tranches}
              selectedOptions = {selectedTranche || null}
              handleSelectChange = {(filterOn, newValue) => handleSelectTranche(newValue)}
            />

            <IconButton
              className = "add_button"
              color = "primary"
              aria-label = "add"
              onClick = {handleAddTrancheWrapper}
            >
              <AddIcon />
            </IconButton>
          </div>
        </div>

        <form
          className = "progress_form_con multi-form-container"
          onSubmit = {handleSubmit}
        >
          <div className = "d-flex flex-column gap-3">
            <Grid
              container
              spacing = {2}
              className = "align-items-start autocomplete"
            >
              <Grid item xs = {4} className = "form-text-field"></Grid>

              <Grid item xs = {4} className = "form-text-field">
                <TextField
                  label = "Tranche Name"
                  id = "Tranche_Name"
                  value = {formData?.Tranche_Name || ""}
                  required
                  onChange = {(event) => handleChange("Tranche_Name", event.target.value)}
                />
              </Grid>

              <Grid item xs = {4} className = "form-text-field"></Grid>

              <Grid item xs = {3} className = "form-text-field">
                <TextField
                  label = "AWB from Supplier"
                  id = "AWB_from_Supplier"
                  value = {formData?.AWB_from_Supplier || ""}
                  onChange = {(event) => handleChange("AWB_from_Supplier", event.target.value)}
                />
              </Grid>

              <Grid item xs = {3} className = "form-text-field">
                <LocalizationProvider dateAdapter = {AdapterDayjs}>
                  <DesktopDatePicker
                    inputFormat = "MM/DD/YYYY"
                    label = "Supplier Document Receiving Date"
                    disableMaskedInput
                    className = "date-picker w-full"
                    value = {convertToDayJsObject(formData?.Supplier_Document_Receiving_Date)}
                    onChange = {(newDate) => handleChange("Supplier_Document_Receiving_Date", newDate, true)}
                    slotProps = {{
                      textField: {
                        InputProps: {
                          size: "small",
                          disabled: true
                        }
                      }
                    }}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs = {3} className = "form-text-field">
                <TextField
                  label = "AWB to Customer"
                  id = "AWB_To_Customer"
                  value = {formData?.AWB_To_Customer || ""}
                  onChange = {(event) => handleChange("AWB_To_Customer", event.target.value)}
                />
              </Grid>

              <Grid item xs = {3} className = "form-text-field">
                <LocalizationProvider dateAdapter = {AdapterDayjs}>
                  <DesktopDatePicker
                    inputFormat = "MM/DD/YYYY"
                    label = "Dispatch Date"
                    disableMaskedInput
                    className = "date-picker w-full"
                    value = {convertToDayJsObject(formData?.Dispatch_Date)}
                    onChange = {(newDate) => handleChange("Dispatch_Date", newDate, true)}
                    slotProps = {{
                      textField: {
                        InputProps: {
                          size: "small",
                          disabled: true
                        }
                      }
                    }}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs = {3} className = "form-text-field">
                <TextField
                  label = "Disc. Acceptance"
                  id = "Disc_Acceptance"
                  value = {formData?.Disc_Acceptance || ""}
                  onChange = {(event) => handleChange("Disc_Acceptance", event.target.value)}
                />
              </Grid>
              
              <Grid item xs = {3}>
                <VirtualizedAutocomplete
                  isMultiple = {false}
                  isObject = {false}
                  filterOn = "Received Payment"
                  options = {receivedPayments}
                  selectedOptions = {formData?.Received_Payment || null}
                  handleSelectChange = {(filterOn, newValue) => handleChange("Received_Payment", newValue)}
                />
              </Grid>

              <Grid item xs = {3}>
                <VirtualizedAutocomplete
                  isMultiple = {false}
                  isObject = {false}
                  filterOn = "Discounting Required"
                  options = {discountingOptions}
                  selectedOptions = {formData?.Discounting_Required || null}
                  handleSelectChange = {(filterOn, newValue) => handleChange("Discounting_Required", newValue)}
                />
              </Grid>
              
              <Grid item xs = {4} className = "form-text-field">
                <TextField
                  label = "Special Requirement"
                  id = "Special_Requirement"
                  multiline
                  rows = {3}
                  value = {formData?.Special_Requirement || ""}
                  onChange = {(event) => handleChange("Special_Requirement", event.target.value)}
                />
              </Grid>

              <Grid item xs = {12}>
                <hr />

                <div className = "w-100 d-flex justify-content-between mb-4">
                  <h3 className = "mb-0">Target Dates</h3>
                </div>

                <Grid
                  container
                  spacing = {2}
                  className = "align-items-start autocomplete mb-4"
                >
                  <Grid item xs = {3} className = "form-text-field">
                    <LocalizationProvider dateAdapter = {AdapterDayjs}>
                      <DesktopDatePicker
                        inputFormat = "MM/DD/YYYY"
                        label = "Target Customer PFI Issuance Date"
                        disableMaskedInput
                        className = "date-picker w-full"
                        value = {convertToDayJsObject(formData?.Target_Customer_PFI_Issuance_Date)}
                        onChange = {(newDate) => handleChange("Target_Customer_PFI_Issuance_Date", newDate, true)}
                        slotProps = {{
                          textField: {
                            InputProps: {
                              size: "small",
                              disabled: true
                            }
                          }
                        }}
                      />
                    </LocalizationProvider>
                  </Grid>

                  <Grid item xs = {3} className = "form-text-field">
                    <LocalizationProvider dateAdapter = {AdapterDayjs}>
                      <DesktopDatePicker
                        inputFormat = "MM/DD/YYYY"
                        label = "Target Supplier LC Issuance Date"
                        disableMaskedInput
                        className = "date-picker w-full"
                        value = {convertToDayJsObject(formData?.Target_Supplier_LC_Issuance_Date)}
                        onChange = {(newDate) => handleChange("Target_Supplier_LC_Issuance_Date", newDate, true)}
                        slotProps = {{
                          textField: {
                            InputProps: {
                              size: "small",
                              disabled: true
                            }
                          }
                        }}
                      />
                    </LocalizationProvider>
                  </Grid>

                  <Grid item xs = {3} className = "form-text-field">
                    <LocalizationProvider dateAdapter = {AdapterDayjs}>
                      <DesktopDatePicker
                        inputFormat = "MM/DD/YYYY"
                        label = "Target Customer NN Documents"
                        disableMaskedInput
                        className = "date-picker w-full"
                        value = {convertToDayJsObject(formData?.Target_Customer_NN_Documents)}
                        onChange = {(newDate) => handleChange("Target_Customer_NN_Documents", newDate, true)}
                        slotProps = {{
                          textField: {
                            InputProps: {
                              size: "small",
                              disabled: true
                            }
                          }
                        }}
                      />
                    </LocalizationProvider>
                  </Grid>

                  <Grid item xs = {3} className = "form-text-field">
                    <LocalizationProvider dateAdapter = {AdapterDayjs}>
                      <DesktopDatePicker
                        inputFormat = "MM/DD/YYYY"
                        label = "Target Supplier NN Documents"
                        disableMaskedInput
                        className = "date-picker w-full"
                        value = {convertToDayJsObject(formData?.Target_Supplier_NN_Documents)}
                        onChange = {(newDate) => handleChange("Target_Supplier_NN_Documents", newDate, true)}
                        slotProps = {{
                          textField: {
                            InputProps: {
                              size: "small",
                              disabled: true
                            }
                          }
                        }}
                      />
                    </LocalizationProvider>
                  </Grid>

                  <Grid item xs = {3} className = "form-text-field">
                    <LocalizationProvider dateAdapter = {AdapterDayjs}>
                      <DesktopDatePicker
                        inputFormat = "MM/DD/YYYY"
                        label = "Target Supplier Original Documents"
                        disableMaskedInput
                        className = "date-picker w-full"
                        value = {convertToDayJsObject(formData?.Target_Supplier_Original_Documents)}
                        onChange = {(newDate) => handleChange("Target_Supplier_Original_Documents", newDate, true)}
                        slotProps = {{
                          textField: {
                            InputProps: {
                              size: "small",
                              disabled: true
                            }
                          }
                        }}
                      />
                    </LocalizationProvider>
                  </Grid>

                  <Grid item xs = {3} className = "form-text-field">
                    <LocalizationProvider dateAdapter = {AdapterDayjs}>
                      <DesktopDatePicker
                        inputFormat = "MM/DD/YYYY"
                        label = "Target Customer Original Documents"
                        disableMaskedInput
                        className = "date-picker w-full"
                        value = {convertToDayJsObject(formData?.Target_Customer_Original_Documents)}
                        onChange = {(newDate) => handleChange("Target_Customer_Original_Documents", newDate, true)}
                        slotProps = {{
                          textField: {
                            InputProps: {
                              size: "small",
                              disabled: true
                            }
                          }
                        }}
                      />
                    </LocalizationProvider>
                  </Grid>

                  <Grid item xs = {3} className = "form-text-field">
                    <LocalizationProvider dateAdapter = {AdapterDayjs}>
                      <DesktopDatePicker
                        inputFormat = "MM/DD/YYYY"
                        label = "Customer LC Deadline"
                        disableMaskedInput
                        className = "date-picker w-full"
                        value = {convertToDayJsObject(formData?.Customer_LC_Deadline)}
                        onChange = {(newDate) => handleChange("Customer_LC_Deadline", newDate, true)}
                        slotProps = {{
                          textField: {
                            InputProps: {
                              size: "small",
                              disabled: true
                            }
                          }
                        }}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs = {12}>
                <hr />

                <div className = "w-100 d-flex justify-content-between mb-4">
                  <h3 className = "mb-0">Document Status</h3>
                </div>

                <Grid
                  container
                  spacing = {2}
                  className = "align-items-start autocomplete mb-4"
                >
                  <Grid item xs = {3}>
                    <VirtualizedAutocomplete
                      isMultiple = {false}
                      isObject = {false}
                      filterOn = "Supplier Status"
                      options = {buyerDocumentStatus}
                      selectedOptions = {formData?.Supplier_Status || null}
                      handleSelectChange = {(filterOn, newValue) => handleChange("Supplier_Status", newValue)}
                    />
                  </Grid>

                  {formData?.Supplier_Status?.includes("Discrepant") && (
                    <>
                      <Grid item xs = {3}>
                        <VirtualizedAutocomplete
                          isMultiple = {false}
                          isObject = {false}
                          filterOn = "Supplier Discrepancy Acceptance Swift"
                          options = {discrepancyAcceptanceSwifts}
                          selectedOptions = {formData?.Supplier_Discrepancy_Acceptance_Swift || null}
                          handleSelectChange = {(filterOn, newValue) => handleChange("Supplier_Discrepancy_Acceptance_Swift", newValue)}
                        />
                      </Grid>

                      <Grid item xs = {4} className = "form-text-field">
                        <TextField
                          label = "Supplier Discrepancy Reason"
                          id = "Supplier_Discrepancy_Reason"
                          multiline
                          rows = {3}
                          value = {formData?.Supplier_Discrepancy_Reason || ""}
                          onChange = {(event) => handleChange("Supplier_Discrepancy_Reason", event.target.value)}
                        />
                      </Grid>
                    </>
                  )}
                </Grid>

                <Grid
                  container
                  spacing = {2}
                  className = "align-items-start autocomplete"
                >
                  <Grid item xs = {3}>
                    <VirtualizedAutocomplete
                      isMultiple = {false}
                      isObject = {false}
                      filterOn = "Customer Status"
                      options = {sellerDocumentStatus}
                      selectedOptions = {formData?.Customer_Status || null}
                      handleSelectChange = {(filterOn, newValue) => handleChange("Customer_Status", newValue)}
                    />
                  </Grid>

                  {formData?.Customer_Status?.includes("Discrepant") && (
                    <>
                      <Grid item xs = {3}>
                        <VirtualizedAutocomplete
                          isMultiple = {false}
                          isObject = {false}
                          filterOn = "Customer Discrepancy Acceptance Swift"
                          options = {discrepancyAcceptanceSwifts}
                          selectedOptions = {formData?.Customer_Discrepancy_Acceptance_Swift || null}
                          handleSelectChange = {(filterOn, newValue) => handleChange("Customer_Discrepancy_Acceptance_Swift", newValue)}
                        />
                      </Grid>

                      <Grid item xs = {4} className = "form-text-field">
                        <TextField
                          label = "Customer Discrepancy Reason"
                          id = "Customer_Discrepancy_Reason"
                          multiline
                          rows = {3}
                          value = {formData?.Customer_Discrepancy_Reason || ""}
                          onChange = {(event) => handleChange("Customer_Discrepancy_Reason", event.target.value)}
                        />
                      </Grid>
                    </>
                  )}
                </Grid>

                <hr />
              </Grid>

              <Grid item xs = {12}>
                <div className = "w-100 d-flex justify-content-between mb-3">
                  <h3>Upload Documents</h3>
                </div>

                <FileUploader
                  files = {files}
                  uploadedFiles = {uploadedFiles}
                  allowFileTypeSelection = {true}
                  buyingFileTypes = {buyingFileTypes}
                  sellingFileTypes = {sellingFileTypes}
                  isVerticalView = {true}
                  setIsLoading = {setIsLoading}
                  setShowAlert = {setShowAlert}
                  setAlertMessage = {setAlertMessage}
                  setAlertType = {setAlertType}
                  setFiles = {setFiles}
                  setUploadedFiles = {setUploadedFiles}
                />
              </Grid>
            </Grid>

            <button ref = {submitButtonRef} type = "submit" className = "hidden"></button>
          </div>
        </form>
      </div>

      <div className = "d-flex gap-2 justify-content-end">
        <button
          type = "button"
          variant = "contained"
          color = "primary"
          disabled = {isLoading}
          className = "btn btn_secondary custom_btn"
          onClick = {() => submitButtonRef?.current?.click()}
        >
          {isExistingDocumentation ? "Update" : "Save"}
        </button>

        {isExistingDocumentation && (
          <button
            type = "button"
            variant = "contained"
            color = "error"
            disabled = {isLoading}
            className = "btn custom_btn btn-danger"
            onClick = {() => setShowDeleteModal(true)}
          >
            Delete
          </button>
        )}
      </div>

      <Dialog open = {showWarningDialog} onClose = {handleCloseDialog}>
        <DialogContent>
          <DialogContentText className = "text-center">
            Please fill in at least one field in the form before adding a new entry.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <button
            type = "button"
            variant = "contained"
            className = "btn btn-primary custom_btn"
            onClick = {handleCloseDialog}
          >
            Ok
          </button>
        </DialogActions>
      </Dialog>

      <Modal show = {showDeleteModal} onHide = {handleCloseModal} centered className = "delete-modal">
        <Modal.Header closeButton>
          <Modal.Title>Are you sure?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span>
            Do you really want to delete this tranche? This action will remove
            it from the documentations which cannot be undone.
          </span>
        </Modal.Body>
        <Modal.Footer className = "gap-2">
          <Button variant = "outlined" size = "small" onClick = {handleCloseModal}>
            Cancel
          </Button>
          <Button variant = "contained" size = "small" onClick = {handleDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show = {showAddModal} onHide = {handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Are you sure?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span>
            Adding a new form will discard your unsaved changes. Proceed
            anyway?
          </span>
        </Modal.Body>
        <Modal.Footer className = "gap-2">
          <Button variant = "outlined" size = "small" onClick = {handleCloseModal}>
            Cancel
          </Button>
          <Button variant = "contained" size = "small" onClick = {handleAddTranche}>
            Add
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
});

export default Documentation;