import { forwardRef } from 'react';
import { IMaskInput } from 'react-imask';
import PropTypes from 'prop-types';

const CustomTextMask = forwardRef(function CustomTextMask(props, ref) 
{
  const { onChange, ...other } = props;

  return (
    <IMaskInput
      {...other}
      mask = "0000.[0000]"
      definitions = {{ '#': /[1-9]/ }}
      inputRef = {ref}
      onAccept = {(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

CustomTextMask.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

export default CustomTextMask;