import { BrowserRouter } from 'react-router-dom';
import { TabProvider } from './hooks/useTab';
import RouterLinks from './routes/RouterLinks';

const App = () => 
{
  return (
    <BrowserRouter>
      <TabProvider>
        <RouterLinks />
      </TabProvider>
    </BrowserRouter>
  );
}

export default App;