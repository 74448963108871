import { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { countries } from '../../static/CountryList';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import VirtualizedAutocomplete from '../../components/Autocomplete';
import Modal from 'react-bootstrap/Modal';
import dayjs from 'dayjs';
import axios from 'axios';

const SellingLeg = forwardRef(({ isLoading, selectedTab, selectedPFI, selectedDeal, setIsUpdated, setActiveTab, setIsLoading, setShowAlert, setAlertMessage, setAlertType, logOut = () => {} }, ref) => 
{
  /* LIST VARIABLES */
  const [banks, setBanks] = useState([]);
  const [packagingTypes, setPackagingTypes] = useState([]);
  const [incoterms, setIncoterms] = useState([]);
  const [traders, setTraders] = useState([]);
  const [seaPorts, setSeaPorts] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [pfis, setPFIs] = useState([]);
  const paymentInstrumentStatus = useMemo(() => ["Pending", "Received"], []);
  const validityDays = useMemo(() => [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15], []);

  /* COPY VARIABLES */
  const [selectedPFIToCopy, setSelectedPFIToCopy] = useState(null);

  /* FILE VARIABLES */
  const [files, setFiles] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);

  /* FORM VARIABLES */
  const dataSchema = {
    Trader_Id: null,
    Selling_Currency_Id: null,
    Incoterm_Id: null,
    Customer_Bank_Id: null,
    Other_Non_FZE_Bank: "",
    LC_Confirming_Bank_Id: null,
    Customer_Address: "",
    Port_of_Discharge_Id: null,
    PFI_Issuance_Date: null,
    Validity_Days: null,
    PFI_Expiry_Date: null,
    Last_Shipment_Date: null,
    Remaining_Balance_Payment_Days: null,
    Payment_Instrument_Status: "",
    LC_No: "",
    Issuance_Date: null,
    Document_Presentation_Days: null,
    Terms_and_Conditions: "",
    Country_Of_Origin: "",
    Packaging_Type_Id: null
  };
  const [initialFormData, setInitialFormData] = useState(dataSchema);
  const [formData, setFormData] = useState(dataSchema);
  const [isExistingSellingLeg, setIsExistingSellingLeg] = useState(false);
  const [isFormSavedOrDeleted, setIsFormSavedOrDeleted] = useState(false);
  const formRef = useRef(null);
  const submitButtonRef = useRef(null);

  /* MODAL VARIABLES */
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  useEffect(() => 
  {
    if (!isFormSavedOrDeleted)
    {
      // Check if form data is different from the initial values for non-date fields
      const isFormUpdated = Object.keys(initialFormData).some((key) => 
      {
        const formDataValue = formData[key];
        const initialFormDataValue = initialFormData[key];

        return (JSON.stringify(formDataValue) !== JSON.stringify(initialFormDataValue));
      });

      // Check date fields separately
      const isDateUpdated = ["PFI_Issuance_Date", "PFI_Expiry_Date", "Last_Shipment_Date", "Issuance_Date"].some((dateKey) => 
      {
        const formDataDate = formData[dateKey] ? dayjs(formData[dateKey]) : formData[dateKey];
        const initialFormDataDate = initialFormData[dateKey] ? dayjs(initialFormData[dateKey]) : initialFormData[dateKey];

        return (JSON.stringify(formDataDate) !== JSON.stringify(initialFormDataDate));
      });

      // Set the update state if any of the fields are updated
      setIsUpdated(isFormUpdated || isDateUpdated);
    }

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [formData]);

  useEffect(() => 
  {
    if (formData?.PFI_Issuance_Date) 
    {
      const orderDate = dayjs(formData?.PFI_Issuance_Date);
      const validityDays = formData?.Validity_Days || 0;
      const expiryDate = orderDate.add(validityDays, "days").format("MM/DD/YYYY");

      setFormData((previousFormData) => ({
        ...previousFormData,
        PFI_Expiry_Date: expiryDate
      }));
    }
  }, [formData?.PFI_Issuance_Date, formData?.Validity_Days]);

  useEffect(() => 
  {
    const fetchLists = async () => 
    {
      axios({
        method: "get",
        url: "/GetBankList"
      })
      .then((response) => 
      {
        const { status, data } = response;

        if (status === 200) 
        {
          const banks = data?.banks || [];
          setBanks(banks);
        }
      })
      .catch((error) => 
      {
        console.log("Get Banks Api: ", error);

        if (error?.response?.status === 403) 
        {
          logOut();
        }
        else
        {
          setAlertMessage(
            error?.response?.status === 429
              ? "Request limit exceeded. Please try again later."
              : "An error occurred while processing your request. Please try again later or contact the site administrator."
          );
          setAlertType("error");
          setShowAlert(true);
        }
      });

      axios({
        method: "get",
        url: "/GetPackagingTypeList"
      })
      .then((response) => 
      {
        const { status, data } = response;

        if (status === 200) 
        {
          const packagingTypes = data?.packaging_types || [];
          setPackagingTypes(packagingTypes);
        }
      })
      .catch((error) => 
      {
        console.log("Get Packaging Type Api: ", error);

        if (error?.response?.status === 403) 
        {
          logOut();
        }
        else
        {
          setAlertMessage(
            error?.response?.status === 429
              ? "Request limit exceeded. Please try again later."
              : "An error occurred while processing your request. Please try again later or contact the site administrator."
          );
          setAlertType("error");
          setShowAlert(true);
        }
      });

      axios({
        method: "get",
        url: "/GetTraderList"
      })
      .then((response) => 
      {
        const { status, data } = response;

        if (status === 200) 
        {
          const traders = data?.traders || [];
          setTraders(traders);
        }
      })
      .catch((error) => 
      {
        console.log("Get Buyers/traders Api: ", error);

        if (error?.response?.status === 403) 
        {
          logOut();
        }
        else
        {
          setAlertMessage(
            error?.response?.status === 429
              ? "Request limit exceeded. Please try again later."
              : "An error occurred while processing your request. Please try again later or contact the site administrator."
          );
          setAlertType("error");
          setShowAlert(true);
        }
      });

      axios({
        method: "get",
        url: "/GetIncotermList"
      })
      .then((response) => 
      {
        const { status, data } = response;

        if (status === 200) 
        {
          const incoterms = data?.incoterms || [];
          setIncoterms(incoterms);
        }
      })
      .catch((error) => 
      {
        console.log("Get Incoterms Api: ", error);

        if (error?.response?.status === 403) 
        {
          logOut();
        }
        else
        {
          setAlertMessage(
            error?.response?.status === 429
              ? "Request limit exceeded. Please try again later."
              : "An error occurred while processing your request. Please try again later or contact the site administrator."
          );
          setAlertType("error");
          setShowAlert(true);
        }
      });

      axios({
        method: "get",
        url: "/GetSeaPortList"
      })
      .then((response) => 
      {
        const { status, data } = response;

        if (status === 200) 
        {
          const seaports = data?.sea_ports || [];
          setSeaPorts(seaports);
        }
      })
      .catch((error) => 
      {
        console.log("Get Sea Ports Api: ", error);

        if (error?.response?.status === 403) 
        {
          logOut();
        }
        else
        {
          setAlertMessage(
            error?.response?.status === 429
              ? "Request limit exceeded. Please try again later."
              : "An error occurred while processing your request. Please try again later or contact the site administrator."
          );
          setAlertType("error");
          setShowAlert(true);
        }
      });

      axios({
        method: "get",
        url: "/GetCurrencyList"
      })
      .then((response) => 
      {
        const { status, data } = response;

        if (status === 200) 
        {
          const currencies = data?.currencies || [];
          setCurrencies(currencies);
        }
      })
      .catch((error) => 
      {
        console.log("Get Currencies Api: ", error);

        if (error?.response?.status === 403) 
        {
          logOut();
        }
        else
        {
          setAlertMessage(
            error?.response?.status === 429
              ? "Request limit exceeded. Please try again later."
              : "An error occurred while processing your request. Please try again later or contact the site administrator."
          );
          setAlertType("error");
          setShowAlert(true);
        }
      });
    }

    const fetchSellingLegData = async () => 
    {
      setIsLoading(true);
      setShowAlert(false);
      setAlertMessage("");
      setAlertType("");

      await axios({
        method: "post",
        url: "/GetSellingLeg",
        data: { Deal_PFI_Id: selectedPFIToCopy?.id || selectedPFI  }
      })
      .then((response) => 
      {
        setIsLoading(false);
        const { status, data } = response;

        if (status === 200) 
        {
          const isEmpty = typeof data === "object" ? data?.Deal_PFI_Id ? false : true : true;

          if (!isEmpty && !selectedPFIToCopy) 
          {
            setIsExistingSellingLeg(true);
          }
          
          data["PFI_Issuance_Date"] = data["PFI_Issuance_Date"] ? dayjs(data["PFI_Issuance_Date"]).format("MM/DD/YYYY") : data["PFI_Issuance_Date"];
          data["PFI_Expiry_Date"] = data["PFI_Expiry_Date"] ? dayjs(data["PFI_Expiry_Date"]).format("MM/DD/YYYY") : data["PFI_Expiry_Date"];
          data["Last_Shipment_Date"] = data["Last_Shipment_Date"] ? dayjs(data["Last_Shipment_Date"]).format("MM/DD/YYYY") : data["Last_Shipment_Date"];
          data["Issuance_Date"] = data["Issuance_Date"] ? dayjs(data["Issuance_Date"]).format("MM/DD/YYYY") : data["Issuance_Date"];

          setInitialFormData(JSON.parse(JSON.stringify(data)));
          setFormData(data);
        } 
        else if (status === 400)
        {
          setShowAlert(true);
          setAlertMessage(data?.error || "An error occurred while processing your request. Please try again later or contact the site administrator.");
          setAlertType("error");
  
          if (data?.is_deal_locked)
          {
            setIsUpdated(false);
            setActiveTab("Deal No.");
          }
        }
        else 
        {
          setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
          setAlertType("error");
          setShowAlert(true);
        }
      })
      .catch((error) => 
      {
        console.log("Get Selling Leg Data Api: ", error);
        setIsLoading(false);
        
        if (error?.response?.status === 403) 
        {
          logOut();
        }
        else
        {
          setAlertMessage(
            error?.response?.status === 429
              ? "Request limit exceeded. Please try again later."
              : "An error occurred while processing your request. Please try again later or contact the site administrator."
          );
          setAlertType("error");
          setShowAlert(true);
        }
      });
    }

    fetchLists();

    if (selectedPFI || selectedPFIToCopy) 
    {
      fetchSellingLegData();
    }

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [selectedPFI, selectedPFIToCopy]);

  useEffect(() => 
  {
    const fetchPFIList = async () => 
    {
      setIsLoading(true);

      await axios({
        method: "post",
        url: "/GetAutoPopulateDealPFIList",
        data: { 
          deal_pfi_id: selectedPFI,
          deal_no: selectedDeal?.id
        }
      })
      .then((response) => 
      {
        setIsLoading(false);
        const { status, data } = response;

        if (status === 200) 
        {
          setPFIs(data?.data || []);
        } 
        else if (status === 400)
        {
          setShowAlert(true);
          setAlertMessage(data?.error || "An error occurred while processing your request. Please try again later or contact the site administrator.");
          setAlertType("error");
        }
        else 
        {
          setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
          setAlertType("error");
          setShowAlert(true);
        }
      })
      .catch((error) => 
      {
        console.log("Get Shipment Detail Filters Api: ", error);
        setIsLoading(false);
        
        if (error?.response?.status === 403) 
        {
          logOut();
        }
        else
        {
          setAlertMessage(
            error?.response?.status === 429
              ? "Request limit exceeded. Please try again later."
              : "An error occurred while processing your request. Please try again later or contact the site administrator."
          );
          setAlertType("error");
          setShowAlert(true);
        }
      });
    }

    if (selectedPFI) 
    {
      fetchPFIList();
    }

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [selectedPFI]);
  
  useImperativeHandle(ref, () => ({
    handleSubmit
  }));

  const convertToDayJsObject = (date) => 
  {
    const convertedDate = date ? dayjs(date, "MM/DD/YYYY") : null;
    return convertedDate;
  }

  const handleUpload = async () => 
  {
    setIsLoading(true);
    setShowAlert(false);
    setAlertMessage("");
    setAlertType("");

    const fileFormData = new FormData();

    for (let i = 0; i < uploadedFiles.length; i++) 
    {
      const file = uploadedFiles[i];
      const fileObj = {
        DealPFIId: selectedPFIToCopy?.id || selectedPFI,
        fileName: file.name,
        docType: "Selling Leg",
        fileExtension: file.name.split(".").pop()
      };

      fileFormData.append(`file${i}`, JSON.stringify(fileObj));
      fileFormData.append(`file${i}`, file);
    }

    await axios({
      method: "post",
      url: "/UploadDocument",
      data: fileFormData
    })
    .then((response) => 
    {
      setIsLoading(false);
      const { status } = response;

      if (status === 200) 
      {
        setActiveTab("Shipment Details");
      } 
      else 
      {
        setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
        setAlertType("error");
        setShowAlert(true);
      }
    })
    .catch((error) => 
    {
      console.log("Upload Selling Leg Documents Api: ", error);
      setIsLoading(false);
      
      if (error?.response?.status === 403) 
      {
        logOut();
      }
      else
      {
        setAlertMessage(
          error?.response?.status === 429
            ? "Request limit exceeded. Please try again later."
            : "An error occurred while processing your request. Please try again later or contact the site administrator."
        );
        setAlertType("error");
        setShowAlert(true);
      }
    });
  }

  const handleSelectChange = (newValue) => 
  {
    setSelectedPFIToCopy(newValue);
    setFormData(dataSchema);
  }

  const handleChange = (field, value, isDate = false) => 
  {
    let newValue = value;
    let dataKey = null;
    let totalAmount = 0; 
    
    if (isDate && newValue)
    {
      newValue = dayjs(newValue).format("MM/DD/YYYY");
    }
    
    setFormData((prevData) => ({
      ...prevData,
      [field]: typeof newValue === "string" ? newValue.trim() === "" ? null : newValue : newValue ? newValue : null,
      [dataKey]: totalAmount
    }));
  }

  const handleSubmit = async (event) => 
  {
    event?.preventDefault();

    setIsLoading(true);
    setShowAlert(false);
    setAlertMessage("");
    setAlertType("");

    let updatedFormData = {
      ...formData,
      Deal_PFI_Id: selectedPFI 
    };

    updatedFormData = Object.fromEntries(Object.entries(updatedFormData).map(([key, value]) => [
      key,
      typeof value === "object" && value?.hasOwnProperty("value") ? value.id : value
    ]));

    const apiUrl = isExistingSellingLeg && !selectedPFIToCopy ? "/UpdateSellingLeg" : "/AddSellingLeg";

    await axios({
      method: "post",
      url: apiUrl,
      data: updatedFormData
    })
    .then((response) => 
    {
      const { status, data } = response;

      if (status === 200) 
      {
        if (uploadedFiles.length > 0) 
        {
          handleUpload();
        } 
        else 
        {
          setIsLoading(false);

          if (selectedTab) 
          {
            setActiveTab(selectedTab);
          } 
          else 
          {
            setActiveTab("Shipment Details");
          }
        }

        setIsFormSavedOrDeleted(true);
        setIsUpdated(false);
      } 
      else if (status === 400)
      {
        setShowAlert(true);
        setAlertMessage(data?.error || "An error occurred while processing your request. Please try again later or contact the site administrator.");
        setAlertType("error");

        if (data?.is_deal_locked)
        {
          setIsUpdated(false);
          setActiveTab("Deal No.");
        }
      }
      else 
      {
        setIsLoading(false);
        setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
        setAlertType("error");
        setShowAlert(true);
      }
    })
    .catch((error) => 
    {
      console.log("Add/Update Selling Leg Data Api: ", error);
      setIsLoading(false);
      
      if (error?.response?.status === 403) 
      {
        logOut();
      }
      else
      {
        setAlertMessage(
          error?.response?.status === 429
            ? "Request limit exceeded. Please try again later."
            : "An error occurred while processing your request. Please try again later or contact the site administrator."
        );
        setAlertType("error");
        setShowAlert(true);
      }
    });
  }

  const handleDelete = async () => 
  {
    setShowDeleteModal(false);
    setIsLoading(true);
    setShowAlert(false);
    setAlertMessage("");
    setAlertType("");

    const fileIds = files.map((file) => file.file.id);

    await axios({
      method: "post",
      url: "/DeleteSellingLeg",
      data: { Deal_PFI_Id: selectedPFIToCopy?.id || selectedPFI  },
    })
    .then(async (response) => 
    {
      const { status, data } = response;

      if (status === 200) 
      {
        await axios({
          method: "post",
          url: "/DeleteDocument",
          data: { fileIds },
        })
        .then((response) => 
        {
          setIsLoading(false);
          const { status } = response;

          if (status === 200) 
          {
            formRef?.current?.reset();

            setFormData((previousFormData) => ({
              ...previousFormData,
              Selling_Currency_Id: null,
              Incoterm_Id: null,
              Customer_Bank_Id: null,
              Other_Non_FZE_Bank: "",
              LC_Confirming_Bank_Id: null,
              Customer_Address: "",
              Port_of_Discharge_Id: null,
              PFI_Issuance_Date: null,
              Validity_Days: null,
              PFI_Expiry_Date: null,
              Last_Shipment_Date: null,
              Remaining_Balance_Payment_Days: null,
              Payment_Instrument_Status: "",
              LC_No: "",
              Issuance_Date: null,
              Document_Presentation_Days: null,
              Terms_and_Conditions: "",
              Country_Of_Origin: "",
              Packaging_Type_Id: null
            }));
            setFiles([]);
            setUploadedFiles([]);
            setIsExistingSellingLeg(false);
            setIsFormSavedOrDeleted(true);
            setAlertMessage("Selling leg data deleted successfully.");
            setAlertType("success");
            setShowAlert(true);
            setIsUpdated(false);
          } 
          else 
          {
            setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
            setAlertType("error");
            setShowAlert(true);
          }
        })
        .catch((error) => 
        {
          console.log("Delete Selling Leg Documents Api: ", error);
          setIsLoading(false);
          
          if (error?.response?.status === 403) 
          {
            logOut();
          }
          else
          {
            setAlertMessage(
              error?.response?.status === 429
                ? "Request limit exceeded. Please try again later."
                : "An error occurred while processing your request. Please try again later or contact the site administrator."
            );
            setAlertType("error");
            setShowAlert(true);
          }
        });
      } 
      else if (status === 400)
      {
        setShowAlert(true);
        setAlertMessage(data?.error || "An error occurred while processing your request. Please try again later or contact the site administrator.");
        setAlertType("error");

        if (data?.is_deal_locked)
        {
          setIsUpdated(false);
          setActiveTab("Deal No.");
        }
      }
      else 
      {
        setIsLoading(false);
        setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
        setAlertType("error");
        setShowAlert(true);
      }
    })
    .catch((error) => 
    {
      console.log("Delete Selling Leg Data Api: ", error);
      setIsLoading(false);
      
      if (error?.response?.status === 403) 
      {
        logOut();
      }
      else
      {
        setAlertMessage(
          error?.response?.status === 429
            ? "Request limit exceeded. Please try again later."
            : "An error occurred while processing your request. Please try again later or contact the site administrator."
        );
        setAlertType("error");
        setShowAlert(true);
      }
    });
  }

  const handleCloseModal = () => 
  {
    setShowDeleteModal(false);
  }

  return (
    <div>
      <div className = "d-flex justify-content-between align-items-center">
        <Typography variant = "h5" gutterBottom className = "page_heading m-0">
          Selling Leg Information
        </Typography>
        
        <div className = "copy-pfi autocomplete">
          <VirtualizedAutocomplete
            isMultiple = {false}
            isObject = {true}
            filterOn = "Select PFI to Auto Populate"
            options = {pfis}
            selectedOptions = {selectedPFIToCopy}
            handleSelectChange = {(filterOn, newValue) => handleSelectChange(newValue)}
          />
        </div>
    </div>

      <div className = "input-fields my-2 p-2">
        <form
          ref = {formRef}
          className = "progress_form_con mt-1"
          onSubmit = {handleSubmit}
        >
          <div className = "d-flex flex-column gap-3">
            <Grid
              container
              spacing = {2}
              className = "align-items-start autocomplete"
            >
              <Grid item xs = {3}>
                <VirtualizedAutocomplete
                  isMultiple = {false}
                  isObject = {true}
                  filterOn = "Selling Currency"
                  options = {currencies}
                  selectedOptions = {formData?.Selling_Currency_Id || null}
                  handleSelectChange = {(filterOn, newValue) => handleChange("Selling_Currency_Id", newValue)}
                />
              </Grid>

              <Grid item xs = {3}>
                <VirtualizedAutocomplete
                  isMultiple = {false}
                  isObject = {true}
                  filterOn = "Incoterms"
                  options = {incoterms}
                  selectedOptions = {formData?.Incoterm_Id || null}
                  handleSelectChange = {(filterOn, newValue) => handleChange("Incoterm_Id", newValue)}
                />
              </Grid>

              <Grid item xs = {3}>
                <VirtualizedAutocomplete
                  isMultiple = {false}
                  isObject = {true}
                  filterOn = "Customer Bank"
                  options = {banks}
                  selectedOptions = {formData?.Customer_Bank_Id || null}
                  handleSelectChange = {(filterOn, newValue) => handleChange("Customer_Bank_Id", newValue)}
                />
              </Grid>

              <Grid item xs = {3}>
                <VirtualizedAutocomplete
                  isMultiple = {false}
                  isObject = {true}
                  filterOn = "Confirming Bank"
                  options = {banks}
                  selectedOptions = {formData?.LC_Confirming_Bank_Id || null}
                  handleSelectChange = {(filterOn, newValue) => handleChange("LC_Confirming_Bank_Id", newValue)}
                />
              </Grid>
              
              <Grid item xs = {3} className = "form-text-field">
                <TextField
                  label = "Customer Address"
                  id = "Customer_Address"
                  value = {formData?.Customer_Address || ""}
                  onChange = {(event) => handleChange("Customer_Address", event.target.value)}                  
                />
              </Grid>

              <Grid item xs = {3}>
                <VirtualizedAutocomplete
                  isMultiple = {false}
                  isObject = {true}
                  filterOn = "Port of Discharge"
                  options = {seaPorts}
                  selectedOptions = {formData?.Port_of_Discharge_Id || null}
                  handleSelectChange = {(filterOn, newValue) => handleChange("Port_of_Discharge_Id", newValue)}
                />
              </Grid>

              <Grid item xs = {3} className = "form-text-field">
                <LocalizationProvider dateAdapter = {AdapterDayjs}>
                  <DesktopDatePicker
                    inputFormat = "MM/DD/YYYY"
                    label = "PFI Issuance Date"
                    disableMaskedInput
                    className = "date-picker w-full"
                    value = {convertToDayJsObject(formData?.PFI_Issuance_Date)}
                    onChange = {(newDate) => handleChange("PFI_Issuance_Date", newDate, true)}
                    onAccept = {(newDate) => handleChange("PFI_Issuance_Date", newDate, true)}
                    slotProps = {{
                      actionBar: {
                        actions: ["clear"]
                      },
                      textField: {
                        InputProps: {
                          size: "small",
                          disabled: true
                        }
                      }
                    }}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs = {3}>
                <VirtualizedAutocomplete
                  isMultiple = {false}
                  isObject = {false}
                  filterOn = "Validity Days"
                  options = {validityDays}
                  selectedOptions = {formData?.Validity_Days || null}
                  handleSelectChange = {(filterOn, newValue) => handleChange("Validity_Days", newValue)}
                />
              </Grid>

              <Grid item xs = {3} className = "form-text-field">
                <LocalizationProvider dateAdapter = {AdapterDayjs}>
                  <DesktopDatePicker
                    inputFormat = "MM/DD/YYYY"
                    label = "PFI Expiry Date"
                    disableMaskedInput
                    className = "date-picker w-full"
                    value = {convertToDayJsObject(formData?.PFI_Expiry_Date)}
                    onChange = {(newDate) => handleChange("PFI_Expiry_Date", newDate, true)}
                    onAccept = {(newDate) => handleChange("PFI_Expiry_Date", newDate, true)}
                    slotProps = {{
                      actionBar: {
                        actions: ["clear"]
                      },
                      textField: {
                        InputProps: {
                          size: "small",
                          disabled: true
                        }
                      }
                    }}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs = {3} className = "form-text-field">
                <LocalizationProvider dateAdapter = {AdapterDayjs}>
                  <DesktopDatePicker
                    inputFormat = "MM/DD/YYYY"
                    label = "Latest Date of Shipment"
                    disableMaskedInput
                    className = "date-picker w-full"
                    value = {convertToDayJsObject(formData?.Last_Shipment_Date)}
                    onChange = {(newDate) => handleChange("Last_Shipment_Date", newDate, true)}
                    onAccept = {(newDate) => handleChange("Last_Shipment_Date", newDate, true)}
                    slotProps = {{
                      actionBar: {
                        actions: ["clear"]
                      },
                      textField: {
                        InputProps: {
                          size: "small",
                          disabled: true
                        }
                      }
                    }}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs = {3} className = "form-text-field">
                <TextField
                  label = "Remaining Balance Payment Days"
                  type = "number"
                  id = "Remaining_Balance_Payment_Days"
                  value = {formData?.Remaining_Balance_Payment_Days || ""}
                  onChange = {(event) => handleChange("Remaining_Balance_Payment_Days", event.target.value)}
                  InputProps = {{ 
                    inputProps: { min: 0, max: 2147483647 } 
                  }}
                  onKeyDown = {(event) => 
                  {
                    if (event?.key === "e" || event?.key === "E" || event?.key === "-" || event?.key === "+") 
                    {
                      event.preventDefault();
                    }
                  }}
                  onInput = {(event) => 
                  {
                    const value = event.target.value;
                    event.target.value = value.replace(/[^0-9]/g, "");
                  }}
                />
              </Grid>

              <Grid item xs = {3} className = "form-text-field">
                <TextField
                  label = "LC No."
                  id = "LC_No"
                  value = {formData?.LC_No || ""}
                  onChange = {(event) => handleChange("LC_No", event.target.value)}
                />
              </Grid>

              <Grid item xs = {3}>
                <VirtualizedAutocomplete
                  isMultiple = {false}
                  isObject = {false}
                  filterOn = "LC Status"
                  options = {paymentInstrumentStatus}
                  selectedOptions = {formData?.Payment_Instrument_Status || null}
                  handleSelectChange = {(filterOn, newValue) => handleChange("Payment_Instrument_Status", newValue)}
                />
              </Grid>

              <Grid item xs = {3} className = "form-text-field">
                <LocalizationProvider dateAdapter = {AdapterDayjs}>
                  <DesktopDatePicker
                    inputFormat = "MM/DD/YYYY"
                    label = "LC Issuance Date"
                    disableMaskedInput
                    className = "date-picker w-full"
                    value = {convertToDayJsObject(formData?.Issuance_Date)}
                    onChange = {(newDate) => handleChange("Issuance_Date", newDate, true)}
                    onAccept = {(newDate) => handleChange("Issuance_Date", newDate, true)}
                    slotProps = {{
                      actionBar: {
                        actions: ["clear"]
                      },
                      textField: {
                        InputProps: {
                          size: "small",
                          disabled: true
                        }
                      }
                    }}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs = {3} className = "form-text-field">
                <TextField
                  label = "Document Presentation Days"
                  type = "number"
                  id = "Document_Presentation_Days"
                  value = {formData?.Document_Presentation_Days || ""}
                  onChange = {(event) =>
                  {
                    const value = event.target.value;

                    if (value === '' || (parseInt(value) >= 1 && parseInt(value) <= 100)) 
                    {
                      handleChange("Document_Presentation_Days", value);
                    }
                  }}
                  InputProps = {{ 
                    inputProps: { min: 0, max: 2147483647 } 
                  }}
                  onKeyDown = {(event) => 
                  {
                    if (event?.key === "e" || event?.key === "E" || event?.key === "-" || event?.key === "+") 
                    {
                      event.preventDefault();
                    }
                  }}
                  onInput = {(event) => 
                  {
                    const value = event.target.value;
                    event.target.value = value.replace(/[^0-9]/g, "");
                  }}
                />
              </Grid>

              <Grid item xs = {3}>
                <VirtualizedAutocomplete
                  isMultiple = {false}
                  isObject = {false}
                  filterOn = "Country of Origin"
                  options = {countries}
                  selectedOptions = {formData?.Country_Of_Origin || null}
                  handleSelectChange = {(filterOn, newValue) => handleChange("Country_Of_Origin", newValue)}
                />
              </Grid>

              <Grid item xs = {3}>
                <VirtualizedAutocomplete
                  isMultiple = {false}
                  isObject = {true}
                  filterOn = "Type of Packaging"
                  options = {packagingTypes}
                  selectedOptions = {formData?.Packaging_Type_Id || null}
                  handleSelectChange = {(filterOn, newValue) => handleChange("Packaging_Type_Id", newValue)}
                />
              </Grid>

              <Grid item xs = {3}>
                <VirtualizedAutocomplete
                  isMultiple = {false}
                  isObject = {true}
                  filterOn = "Trader Name"
                  options = {traders}
                  selectedOptions = {formData?.Trader_Id || null}
                  handleSelectChange = {(filterOn, newValue) => handleChange("Trader_Id", newValue)}
                />
              </Grid>
              
              <Grid item xs = {4} className = "form-text-field">
                <TextField
                  label = "Terms & Conditions"
                  id = "Terms_and_Conditions"
                  multiline
                  rows = {3}
                  value = {formData?.Terms_and_Conditions || ""}
                  onChange = {(event) => handleChange("Terms_and_Conditions", event.target.value)}
                />
              </Grid>
            </Grid>

            <button ref = {submitButtonRef} type = "submit" className = "hidden"></button>
          </div>
        </form>
      </div>

      <div className = "d-flex gap-2 justify-content-end">
        <button
          type = "button"
          variant = "contained"
          color = "primary"
          disabled = {isLoading}
          className = "btn btn_secondary custom_btn"
          onClick = {() => submitButtonRef?.current?.click()}
        >
          {isExistingSellingLeg  ? "Update" : "Save"}
        </button>

        {isExistingSellingLeg  && (
          <button
            type = "button"
            variant = "contained"
            color = "error"
            disabled = {isLoading}
            className = "btn custom_btn btn-danger"
            onClick = {() => setShowDeleteModal(true)}
          >
            Delete
          </button>
        )}
      </div>

      <Modal show = {showDeleteModal} onHide = {handleCloseModal} centered className = "delete-modal">
        <Modal.Header closeButton>
          <Modal.Title>Are you sure?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span>
            Do you really want to delete the form details? This process cannot
            be undone.
          </span>
        </Modal.Body>
        <Modal.Footer className = "gap-2">
          <Button variant = "outlined" size = "small" onClick = {handleCloseModal}>
            Cancel
          </Button>
          <Button variant = "contained" size = "small" onClick = {handleDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
});

export default SellingLeg;