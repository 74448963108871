import { useCallback, useEffect, useState } from 'react';
import Alert from '../../../components/Alert';
import EntityDataTable from '../../../components/EntityDataTable';
import Backdrop from '@mui/material/Backdrop';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import Modal from 'react-bootstrap/Modal';
import SBLCDetails from './SBLCDetails';
import SBLCForm from './SBLCForm';
import withAuth from '../../../lib/withAuth';
import roles from '../../../utility/roles';
import axios from 'axios';
import '../../../styles/components.scss';

const SBLC = ({ logOut = () => {} }) => 
{
  const [isLoading, setIsLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("");

  /* DATATABLE VARIABLES */
  const [columnData, setColumnData] = useState([]);
  const [columnDataTypes, setColumnDataTypes] = useState({});
  const [columnWidths, setColumnWidths] = useState({});
  const [rows, setRows] = useState([]);
  const [dropdowns, setDropdowns] = useState({});
  const [toggleDisplayData, setToggleDisplayData] = useState(false);

  /* SBLC VARIABLES */
  const [selectedSBLC, setSelectedSBLC] = useState(null);
  const [clickedSBLC, setClickedSBLC] = useState(null);
  const [showSBLCForm, setShowSBLCForm] = useState(false);

  /* STATUS VARIABLES */
  const [rowId, setRowId] = useState(null);
  const [rowStatus, setRowStatus] = useState(null);

  /* MODAL VARIABLES */
  const [showDisableModal, setShowDisableModal] = useState(false);

  useEffect(() => 
  {
    const fetchLists = async () => 
    {
      axios({
        method: "get",
        url: "/GetCurrencyList"
      })
      .then((response) => 
      {
        const { status, data } = response;

        if (status === 200) 
        {
          const currencies = data?.currencies || [];

          setDropdowns((previousDropdowns) => (
          {
            ...previousDropdowns,
            Currency: currencies,
          }));
        }
      })
      .catch((error) => 
      {
        console.log("Get Currencies Api: ", error);

        if (error?.response?.status === 403) 
        {
          logOut();
        }
        else
        {
          setAlertMessage(
            error?.response?.status === 429
              ? "Request limit exceeded. Please try again later."
              : "An error occurred while processing your request. Please try again later or contact the site administrator."
          );
          setAlertType("error");
          setShowAlert(true);
        }
      });
    }

    fetchLists();
  
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  useEffect(() => 
  {
    const fetchData = async () => 
    {
      setIsLoading(true);
      setShowAlert(false);
      setAlertMessage("");
      setAlertType("");
      setRowId(null);
      setRowStatus(null);

      await axios({
        method: "get",
        url: "/GetSBLC"
      })
      .then((response) => 
      {
        setIsLoading(false);
        const { status, data } = response;

        if (status === 200) 
        {
          const sblcData = data?.sblc_data || {};
          const { rows: rowData = [], columns = [], data_types: dataTypes = {}, max_column_lengths: widths = {} } = sblcData;
          const columnData = columns.filter((column) => !(column === "id" || column === "isPaid" || column === "User_Id" || column === "isDeleted")) || [];

          setColumnData(columnData);
          setColumnDataTypes(dataTypes);
          setColumnWidths(widths);
          setRows(rowData);
        } 
        else 
        {
          setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
          setAlertType("error");
          setShowAlert(true);
        }
      })
      .catch((error) => 
      { 
        console.log("Get SBLC Api: ", error);
        setIsLoading(false);
        
        if (error?.response?.status === 403) 
        {
          logOut();
        }
        else
        {
          setAlertMessage(
            error?.response?.status === 429
              ? "Request limit exceeded. Please try again later."
              : "An error occurred while processing your request. Please try again later or contact the site administrator."
          );
          setAlertType("error");
          setShowAlert(true);
        }
      });
    }

    fetchData();

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [toggleDisplayData]);

  const handleRecordStatusChangeWrapper = (id, status) => 
  {
    setRowId(id);
    setRowStatus(status);

    if (status === 1) 
    {
      setShowDisableModal(true);
    } 
    else 
    {
      handleRecordStatusChange(id, status);
    }
  }

  const handleRecordStatusChange = useCallback(async (id, status) => 
  {
    setShowDisableModal(false);
    setIsLoading(true);
    setShowAlert(false);
    setAlertMessage("");
    setAlertType("");

    await axios({
      method: "post",
      url: "/SetSBLCStatus",
      data: {
        id: rowId || id,
        status: rowStatus || status
      }
    })
    .then((response) => 
    {
      setIsLoading(false);
      const { status } = response;

      if (status === 200) 
      {
        setToggleDisplayData(!toggleDisplayData);
      } 
      else 
      {
        setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
        setAlertType("error");
        setShowAlert(true);
      }
    })
    .catch((error) => 
    { 
      console.log("SBLC Status Api: ", error);
      setIsLoading(false);
      
      if (error?.response?.status === 403) 
      {
        logOut();
      }
      else
      {
        setAlertMessage(
          error?.response?.status === 429
            ? "Request limit exceeded. Please try again later."
            : "An error occurred while processing your request. Please try again later or contact the site administrator."
        );
        setAlertType("error");
        setShowAlert(true);
      }
    });

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [rowId, rowStatus, toggleDisplayData]);

  const handleCloseModal = () => 
  {
    setShowDisableModal(false);
  }

  const handleAddSBLC = () => 
  {
    setSelectedSBLC(null);
    setShowSBLCForm(true);
  }

  const handleEditSBLC = (sblc) => 
  {
    setSelectedSBLC(sblc);
    setShowSBLCForm(true);
  }

  const handleCellClick = (sblc) => 
  {
    setClickedSBLC(sblc);
  }

  return (
    <div className = "px-4 content_container d-flex flex-column container entities">
      <Alert
        show = {showAlert}
        message = {alertMessage}
        type = {alertType}
        setShow = {setShowAlert}
      />
      
      <Slide direction = "right" appear = {false} in = {!clickedSBLC && !showSBLCForm} mountOnEnter unmountOnExit>
        <div>
          <Typography variant = "h5" gutterBottom className = "page_heading m-0">
            SBLC
          </Typography>

          <div className = "mt-3">
            <Backdrop
              sx = {{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open = {isLoading}
            />
            
            <EntityDataTable
              title = "SBLC"
              isLoading = {isLoading}
              columnData = {columnData}
              columnDataTypes = {columnDataTypes}
              columnWidths = {columnWidths}
              rows = {rows}
              dropdowns = {dropdowns}
              files = {[]}
              isEditable = {true}
              setIsLoading = {setIsLoading}
              setShowAlert = {setShowAlert}
              setAlertMessage = {setAlertMessage}
              setAlertType = {setAlertType}
              handleEditSBLC = {handleEditSBLC}
              handleAddSBLC = {handleAddSBLC}
              handleRecordStatusChange = {handleRecordStatusChangeWrapper}
              handleCellClick = {handleCellClick}
            />
          </div>

          <Modal show = {showDisableModal} onHide = {handleCloseModal} centered>
            <Modal.Header closeButton>
              <Modal.Title>Are you sure?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <span>
                Disabling the SBLC will remove it from the list of available SBLC.
              </span>
            </Modal.Body>
            <Modal.Footer className = "gap-2">
              <Button variant = "outlined" size = "small" onClick = {handleCloseModal}>
                Cancel
              </Button>
              <Button
                variant = "contained"
                size = "small"
                onClick = {handleRecordStatusChange}
              >
                Disable
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </Slide>

      <Slide direction = "left" in = {showSBLCForm ? true : false} mountOnEnter unmountOnExit>
        <div>
          <SBLCForm 
            selectedSBLC = {selectedSBLC}
            isLoading = {isLoading}
            toggleDisplayData = {toggleDisplayData}
            setIsLoading = {setIsLoading}
            setShowAlert = {setShowAlert}
            setAlertMessage = {setAlertMessage}
            setAlertType = {setAlertType}
            setShowSBLCForm = {setShowSBLCForm}
            setSelectedSBLC = {setSelectedSBLC}
            setToggleDisplayData = {setToggleDisplayData}
          />
        </div>
      </Slide>

      <Slide direction = "left" in = {clickedSBLC ? true : false} mountOnEnter unmountOnExit>
        <div>
          <SBLCDetails 
            selectedSBLC = {clickedSBLC} 
            setSelectedSBLC = {setClickedSBLC} 
            toggleDisplayData = {toggleDisplayData}
            setToggleDisplayData = {setToggleDisplayData}
          />
        </div>
      </Slide>
    </div>
  );
}

export default withAuth(SBLC)([roles.Administrator]);