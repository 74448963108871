import { configureStore } from '@reduxjs/toolkit';
import { createTransform, persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import rootReducer from './reducers/index';
import CryptoJS from 'crypto-js';

// Ensure you have the secret key defined in your environment variables
const secretKey = process.env.REACT_APP_REDUX_SECRET_KEY;

const encryptionTransformer = createTransform(
  (inboundState) => {
    // Encrypt the state before storing it
    const stringifiedState = JSON.stringify(inboundState);
    const encryptedState = CryptoJS.AES.encrypt(stringifiedState, secretKey).toString();
    return encryptedState;
  },
  (outboundState) => {
    // Decrypt the state when retrieving it
    const bytes = CryptoJS.AES.decrypt(outboundState, secretKey);
    const decryptedState = bytes.toString(CryptoJS.enc.Utf8);
    return JSON.parse(decryptedState);
  }
);

const persistConfig = { 
  key: 'root', 
  storage, 
  transforms: [encryptionTransformer]
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => 
    getDefaultMiddleware({ 
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    })
});

export const persistor = persistStore(store);