import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

export const WithModal = ({ title, showModal, children, isButtonDisabled, handleCSVDownload, handleCloseModal }) => 
{
  return (
    <Modal show = {showModal} onHide = {handleCloseModal} centered>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {children}
      </Modal.Body>
      
      <Modal.Footer className = "gap-1">
        <Button 
          className = "btn btn_secondary custom_btn" 
          size = "small" 
          disabled = {isButtonDisabled} 
          onClick = {handleCSVDownload}
        >
          Generate
        </Button>

        <Button 
          className = "btn custom_btn btn-danger" 
          size = "small" 
          onClick = {handleCloseModal}
        >
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}