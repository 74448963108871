import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

const ConfirmationDialog = ({ open, handleConfirm, handleDiscard, handleClose }) => 
{
    return (
        <Dialog open = {open} onClose = {handleClose}>
            <DialogContent>
                <DialogContentText className = "text-center">
                    Save changes before proceeding?
                </DialogContentText>
            </DialogContent>

            <DialogActions>
                <button
                    type = "button"
                    variant = "contained"
                    className = "btn btn_secondary custom_btn"
                    onClick = {handleConfirm}
                >
                    Save
                </button>

                <button
                    type = "button"
                    variant = "contained"
                    className = "btn btn-danger custom_btn"
                    onClick = {handleDiscard}
                >
                    Discard
                </button>
            </DialogActions>
        </Dialog>
    );
}

export default ConfirmationDialog;