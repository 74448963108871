import { useEffect, useRef, useState } from 'react';
import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { BiReset } from 'react-icons/bi';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import VirtualizedAutocomplete from '../../components/Autocomplete';
import ReportDataTable from '../../components/ReportDataTable';
import ReportDownloader from '../../components/ReportDownloader';
import dayjs from 'dayjs';
import axios from 'axios';

const MorningReport = ({ isLoading = false, businessUnits = [], setIsLoading = () => {}, setShowAlert = () => {}, setAlertMessage = () => {}, setAlertType = () => {}, logOut = () => {} }) => 
{
    /* SELECTED LIST VARIABLES */
    const [selectedBusinessUnit, setSelectedBusinessUnit] = useState(null);

    /* DATE VARIABLES */
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    /* API VARIABLES */
    const [fetchApiUrl, setFetchApiURL] = useState(null);
    const [exportApiUrl, setExportApiURL] = useState(null);

    /* DATA VARIABLES */
    const [morningReportData, setMorningReportData] = useState({});
    const [morningReportRows, setMorningReportRows] = useState([]);
    const [toggleDisplayData, setToggleDisplayData] = useState(false);

    /* CONDITIONAL FILTER VARIABLES */
    const [isFilterable, setIsFilterable] = useState(false);
    const [filter, setFilter] = useState(false);
    const [isFiltered, setIsFiltered] = useState(false);

    /* BUTTON VARIABLES */
    const downloadButtonRef = useRef(null);

    useEffect(() =>
    {
        const fetchMorningReportData = async () =>
        {
            setIsLoading(true);
            setShowAlert(false);
            setAlertMessage("");
            setAlertType("");

            await axios({
                method: "post",
                url: fetchApiUrl,
                data: {
                    businessUnit: selectedBusinessUnit?.id,
                    startDate: startDate,
                    endDate: endDate
                }
            })
            .then((response) => 
            {
                setIsLoading(false);
                const { status, data } = response;
        
                if (status === 200) 
                {
                    const morningReportData = data?.morning_report_data || {};
                    const { rows = [] } = morningReportData;

                    if (filter && rows.length === 0)
                    {
                        setAlertMessage("No data found that matches the selected filter options.");
                        setAlertType("info");
                        setShowAlert(true);
                    }

                    setMorningReportData(morningReportData);
                }
                else
                {
                    setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
                    setAlertType("error");
                    setShowAlert(true);
                }
            })
            .catch((error) => 
            {
                console.log("Get Morning Report Data Api: ", error);
                setIsLoading(false);

                if (error?.response?.status === 403) 
                {
                    logOut();
                }
                else
                {
                    setAlertMessage(
                    error?.response?.status === 429
                        ? "Request limit exceeded. Please try again later."
                        : "An error occurred while processing your request. Please try again later or contact the site administrator."
                    );
                    setAlertType("error");
                    setShowAlert(true);
                }
            });
        }

        if (selectedBusinessUnit)
        {
            fetchMorningReportData();
        }

        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [filter, toggleDisplayData]);
    
    useEffect(() => 
    {
        if (selectedBusinessUnit || startDate || endDate)
        {
            setIsFilterable(true);
        }
        else
        {
            setIsFilterable(false);
        }

    }, [selectedBusinessUnit, startDate, endDate]);

    useEffect(() =>
    {
        const selectedBusinessUnitId = selectedBusinessUnit?.id || null;

        if ([2, 3, 5].includes(selectedBusinessUnitId))
        {
            setFetchApiURL("/GetMorningReportFertilizersAndPetroChemicals");
            setExportApiURL("/GenerateMorningReportFertilizersAndPetroChemicals");
        }
        else if ([1, 4].includes(selectedBusinessUnitId))
        {
            setFetchApiURL("/GetMorningReportPolymersAndFoods");
            setExportApiURL("/GenerateMorningReportPolymersAndFoods");
        }
        else if (selectedBusinessUnitId === 6)
        {
            setFetchApiURL("/GetMorningReportSBLC");
            setExportApiURL("/GenerateMorningReportSBLC");
        }
    }, [selectedBusinessUnit]);
    
    useEffect(() => 
    {
        if (morningReportRows.length > 0) 
        {
            downloadButtonRef?.current?.click();
        }
    }, [morningReportRows]);
    
    const convertToDayJsObject = (date) => 
    {
        const convertedDate = date ? dayjs(date, "MM/DD/YYYY") : null;
        return convertedDate;
    }
    
    const handleDateChange = (newDate, type) => 
    {
        const date = newDate ? dayjs(newDate).format("MM/DD/YYYY") : newDate;
        type === "Start Date" ? setStartDate(date) : setEndDate(date);
    }  

    const handleFilter = () => 
    {
        setIsFiltered(true);
        setFilter(true);
        setToggleDisplayData(!toggleDisplayData);
    }

    const handleReset = () =>
    {
        setSelectedBusinessUnit(null);
        setStartDate(null);
        setEndDate(null);
        setMorningReportData({});
        setMorningReportRows([]);
        setFetchApiURL(null);
        setExportApiURL(null);
        setIsFilterable(false);
        setFilter(false);
        setIsFiltered(false);
        setToggleDisplayData(!toggleDisplayData);
    }

    const handleExportReport = (rows) =>
    {
        const tableRows = rows.map(({ original: { 'Deal No': dealNo, ...rest } }) => ({ 'Deal No.': dealNo, ...rest })) || [];
        setMorningReportRows(tableRows);
    }

    return (
        <div>
            <form className = "d-flex flex-column gap-5 mb-3" onSubmit = {(event) => event.preventDefault()}>
                <Grid container spacing = {2} justifyContent = "center" alignItems = "center">
                    <Grid item xs = {2} className = "autocomplete" sx = {{ paddingTop: '11px !important' }}>
                        <VirtualizedAutocomplete
                            isMultiple = {false}
                            isObject = {true}
                            isRequired = {true}
                            filterOn = "Business Unit"
                            options = {businessUnits?.filter(businessUnit => businessUnit.value !== "SBLC")}
                            selectedOptions = {selectedBusinessUnit}
                            handleSelectChange = {(filterOn, newValue) => setSelectedBusinessUnit(newValue)}
                        />
                    </Grid>

                    <Grid item xs = {2}>
                        <LocalizationProvider dateAdapter = {AdapterDayjs}>
                            <DesktopDatePicker
                                inputFormat = "DD-MMM-YYYY"
                                label = "Start Date"
                                className = "date-picker w-full"
                                maxDate = {convertToDayJsObject(endDate)}
                                value = {convertToDayJsObject(startDate)}
                                onChange = {(newDate) => handleDateChange(newDate, "Start Date")}
                                onAccept = {(newDate) => handleDateChange(newDate, "Start Date")}
                                slotProps = {{
                                    actionBar: {
                                        actions: ["clear"]
                                    },
                                    textField: {
                                        InputProps: {
                                            size: "small",
                                            disabled: true
                                        }
                                    }
                                }}
                            />
                        </LocalizationProvider>
                    </Grid>

                    <Grid item xs = {2}>
                        <LocalizationProvider dateAdapter = {AdapterDayjs}>
                            <DesktopDatePicker
                                inputFormat = "DD-MMM-YYYY"
                                label = "End Date"
                                className = "date-picker w-full"
                                minDate = {convertToDayJsObject(startDate)}
                                value = {convertToDayJsObject(endDate)}
                                onChange = {(newDate) => handleDateChange(newDate, "End Date")}
                                onAccept = {(newDate) => handleDateChange(newDate, "End Date")}
                                slotProps = {{
                                    actionBar: {
                                        actions: ["clear"]
                                    },
                                    textField: {
                                        InputProps: {
                                            size: "small",
                                            disabled: true
                                        }
                                    }
                                }}
                            />
                        </LocalizationProvider>
                    </Grid>

                    <Grid item xs = {2}>
                        <ButtonGroup variant = "contained" disableElevation = {true}>
                            <Button type = "submit" className = "filter-button" color = "primary" disabled = {!isFilterable} onClick = {handleFilter}>Filter</Button>
                            <Button className = "reset-button" color = "info" disabled = {!isFiltered} startIcon = {<BiReset />} onClick = {handleReset}></Button>
                        </ButtonGroup>
                    </Grid>
                </Grid>
            </form>
            
            <LocalizationProvider dateAdapter = {AdapterDayjs}>
                <ReportDataTable
                    title = "Morning Report"
                    isLoading = {isLoading} 
                    isBusinessUnitSelected = {selectedBusinessUnit && isFiltered}
                    isReportExportable = {[1, 2, 3, 4, 5].includes(selectedBusinessUnit?.id)}
                    reportData = {morningReportData}
                    handleExportRows = {handleExportReport} 
                />
            </LocalizationProvider>

            <ReportDownloader
                ref = {downloadButtonRef}
                apiMethod = "post"
                api = {exportApiUrl}
                apiData = {{ rowData: morningReportRows }}
                fileName = {`${selectedBusinessUnit?.value} Morning Report`}
                buttonType = "submit"
                buttonText = "Morning Report"
                setIsLoading = {setIsLoading}
                setShowAlert = {setShowAlert}
                setAlertMessage = {setAlertMessage}
                setAlertType = {setAlertType}
                logOut = {logOut}
            />
        </div>
    );
}

export default MorningReport;