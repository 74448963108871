import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import VirtualizedAutocomplete from '../../components/Autocomplete';
import axios from 'axios';
import '../../styles/general.scss';

const PFI = ({ isLoading, selectedTab, selectedDeal, selectedPFI, setActiveTab, setSelectedPFI, setIsLoading, setShowAlert, setAlertMessage, setAlertType, logOut = () => {} }) => 
{
  const location = useLocation();
  const { selectedBusinessUnit } = location.state || {};

  /* LIST VARIABLES */
  const [pfis, setPFIs] = useState([]);

  useEffect(() => 
  {
    const fetchData = async () => 
    {
      setIsLoading(true);
      setShowAlert(false);
      setAlertMessage("");
      setAlertType("");

      await axios({
        method: "post",
        url: "/GetDealPFIList",
        data: {
          deal_no: selectedDeal.id
        }
      })
      .then((response) => 
      {
        setIsLoading(false);
        const { status, data } = response;

        if (status === 200) 
        {
          const pfiData = data?.data || [];
          setPFIs(pfiData);
        } 
        else if (status === 400)
        {
          setShowAlert(true);
          setAlertMessage(data?.error || "An error occurred while processing your request. Please try again later or contact the site administrator.");
          setAlertType("error");

          if (data?.is_deal_locked)
          {
            setActiveTab("Deal No.");
          }
        }
        else 
        {
          setShowAlert(true);
          setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
          setAlertType("error");
        }
      })
      .catch((error) => 
      {
        console.error("Get PFI Numbers Api: ", error);
        setIsLoading(false);
        
        if (error?.response?.status === 403) 
        {
          logOut();
        }
        else
        {
          setAlertMessage(
            error?.response?.status === 429
              ? "Request limit exceeded. Please try again later."
              : "An error occurred while processing your request. Please try again later or contact the site administrator."
          );
          setAlertType("error");
          setShowAlert(true);
        }
      });
    }

    if (selectedDeal?.id) 
    {
      fetchData();
    }

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [selectedDeal]);

  const handleGeneratePFINo = async () => 
  {
    setIsLoading(true);
    setShowAlert(false);
    setAlertMessage("");
    setAlertType("");

    await axios({
      method: "POST",
      url: "/AddDealPFI",
      data: {
        deal_no: selectedDeal?.id
      }
    })
    .then((response) => 
    {
      setIsLoading(false);
      const { status, data } = response;

      if (status === 200) 
      {
        setSelectedPFI(data);

        if (selectedTab) 
        {
          setActiveTab(selectedTab);
        } 
        else 
        {
          setActiveTab("Deal Header");
        }
      } 
      else 
      {
        setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
        setAlertType("error");
        setShowAlert(true);
      }
    })
    .catch((error) => 
    { 
      console.error("Generate PFI Api: ", error);
      setIsLoading(false);
      
      if (error?.response?.status === 403) 
      {
        logOut();
      }
      else
      {
        setAlertMessage(
          error?.response?.status === 429
            ? "Request limit exceeded. Please try again later."
            : "An error occurred while processing your request. Please try again later or contact the site administrator."
        );
        setAlertType("error");
        setShowAlert(true);
      }
    });
  }

  return (
    <div className = "d-flex flex-column gap-2 pfi-no justify-content-center align-items-center m-auto">
      <button
        className = "btn btn-primary generate-button"
        onClick = {handleGeneratePFINo}
        disabled = {isLoading || !selectedBusinessUnit}
      >
        Generate PFI
      </button>

      <strong className = "d-flex flex-column justify-content-center align-items-center">
        OR
      </strong>

      <div className = "autocomplete dropdown">
        <VirtualizedAutocomplete
          isMultiple = {false}
          isObject = {true}
          filterOn = "Select Existing PFI"
          options = {pfis}
          selectedOptions = {selectedPFI || null}
          selectedDeal = {selectedDeal?.value}
          handleSelectChange = {(filterOn, newValue) => 
          {
            setSelectedPFI(newValue);

            if (newValue) 
            {
              setActiveTab("Deal Header");
            }
          }}
        />
      </div>
    </div>
  );
}

export default PFI;