import { useEffect, useRef, useState } from 'react';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import CircularProgress from '@mui/material/CircularProgress';
import Button from 'react-bootstrap/Button';
import Alert from '../../components/Alert';
import withAuth from '../../lib/withAuth';
import roles from '../../utility/roles';
import axios from 'axios';

const CreateUser = ({ logOut = () => {} }) => 
{
  const [isLoading, setIsLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("");

  /* FORM VARIABLES */
  const [formData, setFormData] = useState({});
  const formRef = useRef(null);

  /* ROLE VARIABLES */
  const [areRolesLoading, setAreRolesLoading] = useState(false);
  const [roles, setRoles] = useState([]);

  /* PASSWORD VARIABLES */
  const [showPassword, setShowPassword] = useState(false);
  const confirmPasswordInputRef = useRef(null);

  useEffect(() => 
  {
    const fetchData = async () => 
    {
      setAreRolesLoading(true);
      setShowAlert(false);
      setAlertMessage("");
      setAlertType("");

      await axios({
        method: "get",
        url: "/LoadUserRoles"
      })
      .then((response) => 
      {
        setAreRolesLoading(false);
        const { status, data } = response;

        if (status === 200) 
        {
          const responseData = data?.data || [];
          setRoles(responseData);
        }
        else
        {
          setAlertMessage("An error occurred while retrieving the user roles. Please try again later or contact the site administrator.");
          setAlertType("error");
          setShowAlert(true);
        }
      })
      .catch((error) => 
      {
        console.log("Load Roles Api: ", error);
        setAreRolesLoading(false);

        if (error?.response?.status === 403) 
        {
          logOut();
        }
        else
        {
          setAlertMessage(
            error?.response?.status === 429
              ? "Request limit exceeded. Please try again later."
              : "An error occurred while processing your request. Please try again later or contact the site administrator."
          );
          setAlertType("error");
          setShowAlert(true);
        }
      });
    }

    fetchData();
  
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  const handleToggleShowPassword = () => 
  {
    setShowPassword(previousShowPassword => !previousShowPassword); 
  }

  const handleChange = (event) => 
  {
    const { name, value } = event.target;
    const userData = { ...formData };

    if (typeof value !== "undefined" && value !== "") 
    {
      if (name === "firstName" || name === "lastName") 
      {
        const namePattern = /^[a-zÀ-ÿ][-,a-z. ']+$/gim;

        if (!namePattern.test(value)) 
        {
          event.target.setCustomValidity("Please enter a valid name.");
        } 
        else 
        {
          event.target.setCustomValidity("");
          userData[name] = value;
        }
      } 
      else if (name === "email") 
      {
        const emailPattern = new RegExp(
          /^(('[\w\s-]+')|([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
        );

        if (!emailPattern.test(value)) 
        {
          event.target.setCustomValidity("Please enter a valid email address.");
        } 
        else 
        {
          event.target.setCustomValidity("");
          userData[name] = value;
        }
      } 
      else if (name === "password") 
      {
        if (userData?.confirmPassword !== value) 
        {
          confirmPasswordInputRef?.current?.setCustomValidity("Password and confirm password values do not match!");
        } 
        else 
        {
          confirmPasswordInputRef?.current?.setCustomValidity("");
        }

        userData[name] = value;
      } 
      else if (name === "confirmPassword") 
      {
        if (userData?.password !== value) 
        {
          event.target.setCustomValidity("Password and confirm password values do not match!");
        } 
        else 
        {
          event.target.setCustomValidity("");
        }

        userData[name] = value;
      } 
      else 
      {
        userData[name] = value;
      }
    }

    setFormData(userData);
  }

  const handleSubmit = async (event) => 
  {
    event.preventDefault();

    setIsLoading(true);
    setShowAlert(false);
    setAlertMessage("");
    setAlertType("");

    await axios({
      method: "post",
      url: "/CreateUser",
      data: {
        FirstName: formData.firstName,
        LastName: formData.lastName,
        Email: formData.email.toLowerCase(),
        Role: formData.role,
        Password: formData.password
      }
    })
    .then((response) => 
    {
      setIsLoading(false);
      const { status, data } = response;

      if (status === 200) 
      {
        formRef?.current?.reset();

        setFormData({});
        setAlertMessage("New User Created Successfully.");
        setAlertType("success");
        setShowAlert(true);
      } 
      else if (status === 202) 
      {
        setAlertMessage(data?.data || "");
        setAlertType("error");
        setShowAlert(true);
      }
    })
    .catch((error) => 
    {
      console.log("Create User Api: ", error);
      setIsLoading(false);

      if (error?.response?.status === 403) 
      {
        logOut();
      }
      else
      {
        setAlertMessage(
          error?.response?.status === 429
            ? "Request limit exceeded. Please try again later."
            : "An error occurred while processing your request. Please try again later or contact the site administrator."
        );
        setAlertType("error");
        setShowAlert(true);
      }
    });
  }

  return (
    <div className = "content_container d-flex flex-column mt-0 user">
      <Alert
        show = {showAlert}
        message = {alertMessage}
        type = {alertType}
        setShow = {setShowAlert}
      />

      <div className = "py-4">
        <form   autocomplete="off" ref = {formRef} className = "add-user-form" onSubmit = {handleSubmit} >
          <div className = "form-group">
            <label htmlFor = "firstName">First Name</label>

            <input
              type = "text"
              className = "form-control"
              name = "firstName"
              placeholder = "Enter First Name"
              onChange = {handleChange}
              required
            />
          </div>

          <div className = "form-group">
            <label htmlFor = "lastName">Last Name</label>

            <input
              type = "text"
              className = "form-control"
              name = "lastName"
              placeholder = "Enter Last Name"
              onChange = {handleChange}
              required
            />
          </div>

          <div className = "form-group">
            <label htmlFor = "email">Email Address</label>

            <input
              type = "email"
              className = "form-control"
              name = "email"
              placeholder = "Enter Email Address"
              onChange = {handleChange}
              required
            />
          </div>

          <div className = "form-group">
            <label htmlFor = "role">Role</label>

            <div className = "d-flex w-full align-items-center position-relative">
              <select
                className = "form-control"
                name = "role"
                onChange = {handleChange}
                required
              >
                <option value = "" hidden>
                  Select Role
                </option>

                {roles?.map((role, index) => (
                  <option key = {index} value = {role} onClick = {handleChange}>
                    {role}
                  </option>
                ))}
              </select>

              {areRolesLoading && (
                <CircularProgress color = "inherit" size = {20} className = "loader" />
              )}
            </div>
          </div>

          <div className = "form-group">
            <label htmlFor = "password">Password</label>

            <div className = "password-container">
              <input
                type = {showPassword ? "text" : "password"}
                id = "password"
                name = "password"
                className = "form-control"
                placeholder = "Enter Password"
                onChange = {handleChange}
                required
              />

              {showPassword ? (
                <AiOutlineEyeInvisible
                  className = "show-hide-password"
                  title = "Hide Password"
                  onClick = {handleToggleShowPassword}
                />
              ) : (
                <AiOutlineEye
                  className = "show-hide-password"
                  title = "Show Password"
                  onClick = {handleToggleShowPassword}
                />
              )}
            </div>
          </div>

          <div className = "form-group">
            <label htmlFor = "confirmPassword">Confirm Password</label>

            <div className = "password-container">
              <input
                ref = {confirmPasswordInputRef}
                type = {showPassword ? "text" : "password"}
                id = "confirmPassword"
                name = "confirmPassword"
                className = "form-control"
                placeholder = "Enter Confirm Password"
                onChange = {handleChange}
                required
              />

              {showPassword ? (
                <AiOutlineEyeInvisible
                  className = "show-hide-password"
                  title = "Hide Password"
                  onClick = {handleToggleShowPassword}
                />
              ) : (
                <AiOutlineEye
                  className = "show-hide-password"
                  title = "Show Password"
                  onClick = {handleToggleShowPassword}
                />
              )}
            </div>
          </div>

          <div className = "text-center pt-2">
            <Button
              className = "btn custom_btn btn-primary m-auto"
              variant = "primary"
              type = "submit"
              disabled = {isLoading}
            >
              {isLoading ? (
                <div className = "spinner-border text-info" role = "status" />
              ) : (
                "Add User"
              )}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default withAuth(CreateUser)([roles.Administrator]);