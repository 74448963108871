import { useState } from 'react';
import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { BiReset } from 'react-icons/bi';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import VirtualizedAutocomplete from '../../components/Autocomplete';
import ReportDownloader from '../../components/ReportDownloader';
import dayjs from 'dayjs';

const DealReport = ({ businessUnits = [], setIsLoading = () => {}, setShowAlert = () => {}, setAlertMessage = () => {}, setAlertType = () => {}, logOut = () => {} }) => 
{
    /* LIST VARIABLES */
    businessUnits = [...businessUnits, { id: "All", value: "All" }];

    /* SELECTED LIST VARIABLES */
    const [selectedBusinessUnit, setSelectedBusinessUnit] = useState(null);

    /* DATE VARIABLES */
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const convertToDayJsObject = (date) => 
    {
        const convertedDate = date ? dayjs(date, "MM/DD/YYYY") : null;
        return convertedDate;
    }

    const handleDateChange = (newDate, type) => 
    {
        const date = newDate ? dayjs(newDate).format("MM/DD/YYYY") : newDate;
        type === "Start Date" ? setStartDate(date) : setEndDate(date);
    }

    const handleReset = () =>
    {
        setSelectedBusinessUnit(null);
        setStartDate(null);
        setEndDate(null);
    }

    return (
        <form className = "d-flex flex-column gap-5 mb-3">
            <Grid container spacing = {2} justifyContent = "center" alignItems = "center">
                <Grid item xs = {2} className = "autocomplete" sx = {{ paddingTop: '11px !important' }}>
                    <VirtualizedAutocomplete
                        isMultiple = {false}
                        isObject = {true}
                        isRequired = {true}
                        filterOn = "Business Unit"
                        options = {businessUnits}
                        selectedOptions = {selectedBusinessUnit}
                        handleSelectChange = {(filterOn, newValue) => setSelectedBusinessUnit(newValue)}
                    />
                </Grid>

                <Grid item xs = {2}>
                    <LocalizationProvider dateAdapter = {AdapterDayjs}>
                        <DesktopDatePicker
                            inputFormat = "DD-MMM-YYYY"
                            label = "Start Date"
                            className = "date-picker w-full"
                            maxDate = {convertToDayJsObject(endDate)}
                            value = {convertToDayJsObject(startDate)}
                            onChange = {(newDate) => handleDateChange(newDate, "Start Date")}
                            onAccept = {(newDate) => handleDateChange(newDate, "Start Date")}
                            slotProps = {{
                                actionBar: {
                                    actions: ["clear"]
                                },
                                textField: {
                                    InputProps: {
                                        size: "small",
                                        disabled: true
                                    }
                                }
                            }}
                        />
                    </LocalizationProvider>
                </Grid>

                <Grid item xs = {2}>
                    <LocalizationProvider dateAdapter = {AdapterDayjs}>
                        <DesktopDatePicker
                            inputFormat = "DD-MMM-YYYY"
                            label = "End Date"
                            className = "date-picker w-full"
                            minDate = {convertToDayJsObject(startDate)}
                            value = {convertToDayJsObject(endDate)}
                            onChange = {(newDate) => handleDateChange(newDate, "End Date")}
                            onAccept = {(newDate) => handleDateChange(newDate, "End Date")}
                            slotProps = {{
                                actionBar: {
                                    actions: ["clear"]
                                },
                                textField: {
                                    InputProps: {
                                        size: "small",
                                        disabled: true
                                    }
                                }
                            }}
                        />
                    </LocalizationProvider>
                </Grid>

                <Grid item xs = {2}>
                    <ButtonGroup variant = "contained" disableElevation = {true}>
                        <Button className = "reset-button" color = "info" disabled = {!(selectedBusinessUnit || startDate || endDate)} startIcon = {<BiReset />} onClick = {handleReset}></Button>
                    </ButtonGroup>
                </Grid>
            </Grid>

            <Grid 
                container 
                spacing = {2} 
                flexDirection = "column" 
                justifyContent = "center" 
                paddingX = {2} 
                gap = {1}
            >
                <Grid 
                    item 
                    xs = {12} 
                    className = "d-flex align-items-center pt-0" 
                    justifyContent ="space-between"
                >
                    <span>
                        Download the data for all deals and their relevant details in the system.
                    </span>

                    <div className = "report-button">
                        <ReportDownloader
                            isBusinessUnitRequired = {true}
                            apiMethod = "post"
                            api = "/GenerateDealReport"
                            apiData = {{ 
                                businessUnit: selectedBusinessUnit?.id || null, 
                                startDate, 
                                endDate 
                            }}
                            fileName = "Deal Report"
                            buttonType = "submit"
                            buttonText = "Deal Report"
                            setIsLoading = {setIsLoading}
                            setShowAlert = {setShowAlert}
                            setAlertMessage = {setAlertMessage}
                            setAlertType = {setAlertType}
                            logOut = {logOut}
                        />
                    </div>
                </Grid>
            </Grid>
        </form>
    );
}

export default DealReport;