export const routes = {
    login: "/login",
    resetPassword: "/login/reset-password",
    changePassword: "/login/change-password/user/:id",
    dealsFertilizers: "/deals/fertilizers",
    dealsFoods: "/deals/foods",
    dealsMiscellaneous: "/deals/miscellaneous",
    dealsFeedstocks: "/deals/petrochemicals/feedstocks",
    dealsPolymers: "/deals/polymers",
    dealsSblc: "/deals/sblc",
    entitiesBanks: "/entities/banks",
    entitiesTrader: "/entities/trader",
    entitiesTenor: "/entities/tenor",
    entitiesDeals: "/entities/deals",
    entitiesDealPfis: "/entities/deal-pfis",
    entitiesDropdowns: "/entities/dropdowns",
    entitiesProducts: "/entities/products",
    entitiesSblc: "/entities/SBLC",
    entitiesVendors: "/entities/vendors",
    reportsDealReport: "/reports/deal-report",
    reportsFinanceReport: "/reports/finance-report",
    reportsMorningReport: "/reports/morning-report",
    createUser: "/create-user",
    pageNotFound: "*"
};

export default routes;