import { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { DesktopDatePicker, DesktopDateTimePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useLocation } from 'react-router-dom';
import DealSBLC from './DealSBLC';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';
import Grid from '@mui/material/Grid';
import Modal from 'react-bootstrap/Modal';
import Button from '@mui/material/Button';
import VirtualizedAutocomplete from '../../components/Autocomplete';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import IconButton from '@mui/material/IconButton';
import CustomTextMask from '../../components/CustomTextMask';
import dayjs from 'dayjs';
import axios from 'axios';

const DealHeader = forwardRef(({ isLoading, selectedTab, selectedPFI, selectedDeal, setIsUpdated, setActiveTab, setIsLoading, setShowAlert, setAlertMessage, setAlertType, logOut }, ref) => 
{
  const location = useLocation();
  const { selectedBusinessUnit } = location.state || {};
  const { isFertilizersAndPetroChemicalsDeal, isFoodsOrPolymersDeal, isFoodsPolymersOrSBLCDeal } = useMemo(() => 
  {
    const id = selectedBusinessUnit?.id;
    
    return {
      isFertilizersAndPetroChemicalsDeal: [2, 3, 5].includes(id),
      isFoodsOrPolymersDeal: [1, 4].includes(id),
      isFoodsPolymersOrSBLCDeal: [1, 4, 6].includes(id),
    };

  }, [selectedBusinessUnit]);
  
  /* AUTHENTICATION VARIABLES */
  const currentUser = useSelector((state) => state.user);

  /* LIST VARIABLES */
  const [productData, setProductData] = useState([]);
  const [products, setProducts] = useState([]);
  const [tenors, setTenors] = useState([]);
  const [productGrades, setProductGrades] = useState([]);
  const [paymentTerms, setPaymentTerms] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [pfis, setPFIs] = useState([]);
  const orderStatus = useMemo(() => ["In-Progress", "Cancelled", "Closed"], []);
  const transactionTyoes = useMemo(() => ["Addition", "Subtraction"], []);

  /* COPY VARIABLES */
  const [selectedPFIToCopy, setSelectedPFIToCopy] = useState(null);
  
  /* FILE VARIABLES */
  const [files, setFiles] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);

  /* FORM VARIABLES */
  const dataSchema = {
    Order_Status: "",
    Buyer_Id: null,
    Buying_Payment_Terms_Id: null,
    Buying_Tenor_Id: null,
    Seller_Id: null,
    Selling_Payment_Terms_Id: null,
    Selling_Tenor_Id: null,
    Booked_for_Month: null,
    Order_Date: null,
    Deal_Close_Date: null,
    Deal_Sheet_Approval_Date: null,
    Shipment_Month_And_Date: null,
    Final_Quantity: null,
    Total_Amount: null,
    Freight: null,
    FOB_Value: null,
    Label: "",
    Value: null,
    Transaction_Type: null,
    products: []
  };
  const [initialFormData, setInitialFormData] = useState(dataSchema);
  const [formData, setFormData] = useState(dataSchema);
  const [isExistingDealHeader, setIsExistingDealHeader] = useState(false);
  const [isFormSavedOrDeleted, setIsFormSavedOrDeleted] = useState(false);
  const formRef = useRef(null);
  const submitButtonRef = useRef(null);

  /* SBLC VARIABLES */
  const sblcDataSchema = {
    SBLC_Id: null,
    PFIs: [],
    PFI_Id: []
  };
  const [initialSBLCFormData, setInitialSBLCFormData] = useState(sblcDataSchema);
  const [sblcFormData, setSBLCFormData] = useState(sblcDataSchema);
  const [isSBLCFormUpdated, setIsSBLCFormUpdated] = useState(false);
  const [isPFIAccordionExpanded, setIsPFIAccordionExpanded] = useState(null);
  const [isProductGradeAccordionExpanded, setIsProductGradeAccordionExpanded] = useState(null);
  const [isExistingSBLC, setIsExistingSBLC] = useState(false);

  /* INPUT REFERENCE VARIABLES */
  const freightInputRef = useRef(null);
  const fobValueInputRef = useRef(null);
  
  /* DIALOG VARIABLES */
  const [showWarningDialog, setShowWarningDialog] = useState(false);
  const [warningText, setWarningText] = useState(null);

  /* MODAL VARIABLES */
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  /* ACCORDIAN VARIABLES */
  const [isProductAccordionExpanded, setIsProductAccordionExpanded] = useState(0);
  const productAccordionRefs = useRef([]);

  useEffect(() => 
  {
    if (!isFormSavedOrDeleted)
    {
      // Check if form data is different from the initial values for non-date fields
      const isFormUpdated = Object.keys(initialFormData).some((key) => 
      {
        const formDataValue = formData[key];
        const initialFormDataValue = initialFormData[key];

        return (JSON.stringify(formDataValue) !== JSON.stringify(initialFormDataValue));
      });

      // Check date fields separately
      const isDateUpdated = ["Booked_for_Month", "Order_Date", "Deal_Close_Date", "Deal_Sheet_Approval_Date", "Shipment_Month_And_Date"].some((dateKey) => 
      {
        const formDataDate = formData[dateKey] ? dayjs(formData[dateKey]) : formData[dateKey];
        const initialFormDataDate = initialFormData[dateKey] ? dayjs(initialFormData[dateKey]) : initialFormData[dateKey];

        return (JSON.stringify(formDataDate) !== JSON.stringify(initialFormDataDate));
      });

      productAccordionRefs.current = productAccordionRefs.current.slice(0, formData?.products?.length);

      // console.log(isFormUpdated || isDateUpdated)
      // Set the update state if any of the fields are updated
      setIsUpdated(isFormUpdated || isDateUpdated);
    }

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [formData]);

  useEffect(() => 
  {
    if (!isFormSavedOrDeleted)
    {
      // Check if SBLC form data is different from the initial values
      const isSBLCFormUpdated = Object.keys(initialSBLCFormData).some((key) => 
      {
        const sblcFormDataValue = sblcFormData[key];
        const initialSBLCFormDataValue = initialSBLCFormData[key];

        return (JSON.stringify(sblcFormDataValue) !== JSON.stringify(initialSBLCFormDataValue));
      });

      setIsSBLCFormUpdated(isSBLCFormUpdated);
      setIsUpdated(isSBLCFormUpdated);
    }

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [sblcFormData]);

  useEffect(() => 
  {
    const fetchLists = async () => 
    {
      axios({
        method: "get",
        url: "/GetProducts"
      })
      .then((response) => 
      {
        const { status, data } = response;

        if (status === 200) 
        {
          const productData = data?.product_data?.rows || [];
          setProductData(productData);
        }
      })
      .catch((error) => 
      {
        console.log("Get Products Api: ", error);

        if (error?.response?.status === 403) 
        {
          logOut();
        }
        else
        {
          setAlertMessage(
            error?.response?.status === 429
              ? "Request limit exceeded. Please try again later."
              : "An error occurred while processing your request. Please try again later or contact the site administrator."
          );
          setAlertType("error");
          setShowAlert(true);
        }
      });

      axios({
        method: "get",
        url: "/GetTenorList"
      })
      .then((response) => 
      {
        const { status, data } = response;

        if (status === 200) 
        {
          const tenors = data?.tenors || [];
          setTenors(tenors);
        }
      })
      .catch((error) => 
      {
        console.log("Get Buyers/Tenors Api: ", error);

        if (error?.response?.status === 403) 
        {
          logOut();
        }
        else
        {
          setAlertMessage(
            error?.response?.status === 429
              ? "Request limit exceeded. Please try again later."
              : "An error occurred while processing your request. Please try again later or contact the site administrator."
          );
          setAlertType("error");
          setShowAlert(true);
        }
      });

      axios({
        method: "get",
        url: "/GetProductGradeList"
      })
      .then((response) => 
      {
        const { status, data } = response;

        if (status === 200) 
        {
          const productGrades = data?.product_grades || [];
          setProductGrades(productGrades);
        }
      })
      .catch((error) => 
      {
        console.log("Get Product Grades Api: ", error);

        if (error?.response?.status === 403) 
        {
          logOut();
        }
        else
        {
          setAlertMessage(
            error?.response?.status === 429
              ? "Request limit exceeded. Please try again later."
              : "An error occurred while processing your request. Please try again later or contact the site administrator."
          );
          setAlertType("error");
          setShowAlert(true);
        }
      });

      axios({
        method: "get",
        url: "/GetPaymentTermList"
      })
      .then((response) => 
      {
        const { status, data } = response;

        if (status === 200) 
        {
          const paymentTerms = data?.payment_terms || [];
          setPaymentTerms(paymentTerms);
        }
      })
      .catch((error) => 
      {
        console.log("Get Payment Terms Api: ", error);

        if (error?.response?.status === 403) 
        {
          logOut();
        }
        else
        {
          setAlertMessage(
            error?.response?.status === 429
              ? "Request limit exceeded. Please try again later."
              : "An error occurred while processing your request. Please try again later or contact the site administrator."
          );
          setAlertType("error");
          setShowAlert(true);
        }
      });

      axios({
        method: "post",
        url: "/GetProductsList",
        data: {
          business_unit_id: selectedBusinessUnit?.id
        }
      })
      .then((response) => 
      {
        const { status, data } = response;

        if (status === 200) 
        {
          const products = data?.product || [];
          setProducts(products);
        }
      })
      .catch((error) => 
      {
        console.log("Get Filtered Products Api: ", error);

        if (error?.response?.status === 403) 
        {
          logOut();
        }
        else
        {
          setAlertMessage(
            error?.response?.status === 429
              ? "Request limit exceeded. Please try again later."
              : "An error occurred while processing your request. Please try again later or contact the site administrator."
          );
          setAlertType("error");
          setShowAlert(true);
        }
      });

      axios({
        method: "post",
        url: "/GetVendorList",
        data: {
          business_unit_id: selectedBusinessUnit?.id
        }
      })
      .then((response) => 
      {
        const { status, data } = response;

        if (status === 200) 
        {
          const vendors = data?.vendors || [];
          setVendors(vendors);
        }
      })
      .catch((error) => 
      {
        console.log("Get Filtered Sellers/Vendors Api: ", error);

        if (error?.response?.status === 403) 
        {
          logOut();
        }
        else
        {
          setAlertMessage(
            error?.response?.status === 429
              ? "Request limit exceeded. Please try again later."
              : "An error occurred while processing your request. Please try again later or contact the site administrator."
          );
          setAlertType("error");
          setShowAlert(true);
        }
      });
    }

    fetchLists();

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  useEffect(() => 
  {
    const fetchPFIList = () => 
    {
      axios({
        method: "post",
        url: "/GetAutoPopulateDealPFIList",
        data: { 
          deal_pfi_id: selectedPFI,
          deal_no: selectedDeal?.id
        }
      })
      .then((response) => 
      {
        const { status, data } = response;

        if (status === 200) 
        {
          setPFIs(data?.data || []);
        }
      })
      .catch((error) => 
      {
        console.log("Get Shipment Detail Filters Api: ", error);
        
        if (error?.response?.status === 403) 
        {
          logOut();
        }
        else
        {
          setAlertMessage(
            error?.response?.status === 429
              ? "Request limit exceeded. Please try again later."
              : "An error occurred while processing your request. Please try again later or contact the site administrator."
          );
          setAlertType("error");
          setShowAlert(true);
        }
      });
    }

    if (selectedPFI) 
    {
      fetchPFIList();
    }

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [selectedPFI]);

  useEffect(() => 
  {
    const fetchDealData = async () => 
    {
      setIsLoading(true);
      setShowAlert(false);
      setAlertMessage("");
      setAlertType("");
      
      const apiUrl = isFertilizersAndPetroChemicalsDeal ? "/GetOrderDetailsFertilizersAndPetroChemicals" : isFoodsPolymersOrSBLCDeal && "/GetOrderDetailsPolymersAndFoods";
      
      await axios({
        method: "post",
        url: apiUrl,
        data: { Deal_PFI_Id: selectedPFIToCopy?.id || selectedPFI }
      })
      .then((response) => 
      {
        if (selectedBusinessUnit?.id !== 6)
        {
          setIsLoading(false);
        }

        const { status, data } = response;
        
        if (status === 200) 
        {
          const isEmpty = typeof data === "object" ? data?.Deal_PFI_Id ? false : true : true;

          if (!isEmpty && !selectedPFIToCopy) 
          {
            setIsExistingDealHeader(true);
          }

          data["Booked_for_Month"] = data["Booked_for_Month"] ? dayjs(data["Booked_for_Month"]) : data["Booked_for_Month"];
          data["Order_Date"] = data["Order_Date"] ? dayjs(data["Order_Date"]).format("MM/DD/YYYY") : data["Order_Date"];
          data["Deal_Close_Date"] = data["Deal_Close_Date"] ? dayjs(data["Deal_Close_Date"]).format("MM/DD/YYYY") : data["Deal_Close_Date"];
          data["Shipment_Month_And_Date"] = data["Shipment_Month_And_Date"] ? dayjs(data["Shipment_Month_And_Date"]).format("MM/DD/YYYY") : data["Shipment_Month_And_Date"];
          
          setInitialFormData(JSON.parse(JSON.stringify(data)));
          setFormData(data);
          setIsProductAccordionExpanded(data?.products?.length - 1);

          if (selectedBusinessUnit?.id === 6)
          {
            fetchSBLCData();
          }
        } 
        else if (status === 400)
        {
          setShowAlert(true);
          setAlertMessage(data?.error || "An error occurred while processing your request. Please try again later or contact the site administrator.");
          setAlertType("error");
  
          if (data?.is_deal_locked)
          {
            setIsUpdated(false);
            setActiveTab("Deal No.");
          }
        }
        else 
        {
          setIsLoading(false);
          setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
          setAlertType("error");
          setShowAlert(true);
        }
      })
      .catch((error) => 
      {
        console.log("Get Deal Data Api: ", error);
        setIsLoading(false);
        
        if (error?.response?.status === 403) 
        {
          logOut();
        }
        else
        {
          setAlertMessage(
            error?.response?.status === 429
              ? "Request limit exceeded. Please try again later."
              : "An error occurred while processing your request. Please try again later or contact the site administrator."
          );
          setAlertType("error");
          setShowAlert(true);
        }
      });
    }

    const fetchSBLCData = async () =>
    {
      await axios({
        method: "post",
        url: "/GetDealSBLCProductGrades",
        data: { Deal_PFI_Id: selectedPFIToCopy?.id || selectedPFI }
      })
      .then((response) => 
      {
        setIsLoading(false);
        const { status, data } = response;

        if (status === 200) 
        {
          const isEmpty = typeof data === "object" ? data?.Deal_PFI_Id ? false : true : true;

          if (!isEmpty) 
          {
            setIsExistingDealHeader(true);
            setIsExistingSBLC(true);
          }

          const updatedData = isEmpty ? sblcDataSchema : data;
          const pfiIndex = (updatedData?.PFI_Id?.length ?? 0) - 1;
          const productGradeIndex = (updatedData?.PFI_Id?.[pfiIndex]?.Product_Grade_Id?.length ?? 0) - 1;

          setFormData((previousFormData) => ({
            ...previousFormData,
            Total_Amount: data?.Total_Amount || 0
          }));
          setInitialSBLCFormData(JSON.parse(JSON.stringify(updatedData)));
          setSBLCFormData(updatedData);
          setIsPFIAccordionExpanded(pfiIndex);
          setIsProductGradeAccordionExpanded(productGradeIndex);
        }
        else if (status === 400)
        {
          setShowAlert(true);
          setAlertMessage(data?.error || "An error occurred while processing your request. Please try again later or contact the site administrator.");
          setAlertType("error");
  
          if (data?.is_deal_locked)
          {
            setIsUpdated(false);
            setActiveTab("Deal No.");
          }
        }
        else 
        {
          setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
          setAlertType("error");
          setShowAlert(true);
        }
      })
      .catch((error) => 
      {
        console.log("Get Buying Leg SBLC Data Api: ", error);
        setIsLoading(false);
        
        if (error?.response?.status === 403) 
        {
          logOut();
        }
        else
        {
          setAlertMessage(
            error?.response?.status === 429
              ? "Request limit exceeded. Please try again later."
              : "An error occurred while processing your request. Please try again later or contact the site administrator."
          );
          setAlertType("error");
          setShowAlert(true);
        }
      });
    }

    if (selectedPFI || selectedPFIToCopy) 
    {
      fetchDealData();
    }

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [selectedPFI, selectedPFIToCopy]);
  
  useImperativeHandle(ref, () => ({
    handleSubmit: handleSubmitWrapper
  }));

  const convertToDayJsObject = (date, datetime) => 
  {
    const convertedDate = date ? dayjs(date, "MM/DD/YYYY") : datetime ? dayjs(datetime, "MM/DD/YYYY hh:mm A") : null;
    return convertedDate;
  }

  const shouldDisableDate = (date, type) => 
  {
    if (!formData?.Booked_for_Month) 
    {
      return false;
    }

    const selectedDate = new Date(date);
    const bookedDate = new Date(formData?.Booked_for_Month);

    return (
      selectedDate.getFullYear() !== bookedDate.getFullYear() ||
      (type === "Month" && selectedDate.getMonth() !== bookedDate.getMonth())
    );
  }

  const handleUpload = async () => 
  {
    setIsLoading(true);
    setShowAlert(false);
    setAlertMessage("");
    setAlertType("");

    const fileFormData = new FormData();

    for (let i = 0; i < uploadedFiles.length; i++) 
    {
      const file = uploadedFiles[i];
      const fileObj = {
        DealPFIId: selectedPFIToCopy?.id || selectedPFI,
        fileName: file.name,
        docType: "Order Details",
        fileExtension: file.name.split(".").pop()
      };

      fileFormData.append(`file${i}`, JSON.stringify(fileObj));
      fileFormData.append(`file${i}`, file);
    }

    await axios({
      method: "post",
      url: "/UploadDocument",
      headers: { "Content-Type": "multipart/form-data" },
      data: fileFormData
    })
    .then((response) => 
    {
      setIsLoading(false);
      const { status } = response;

      if (status === 200) 
      {
        setActiveTab("Buying Leg");
      } 
      else 
      {
        setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
        setAlertType("error");
        setShowAlert(true);
      }
    })
    .catch((error) => 
    { 
      console.log("Upload Deal Header Documents Api: ", error);
      setIsLoading(false);
      
      if (error?.response?.status === 403) 
      {
        logOut();
      }
      else
      {
        setAlertMessage(
          error?.response?.status === 429
            ? "Request limit exceeded. Please try again later."
            : "An error occurred while processing your request. Please try again later or contact the site administrator."
        );
        setAlertType("error");
        setShowAlert(true);
      }
    });
  }

  const handleSelectChange = (newValue) => 
  {
    setSelectedPFIToCopy(newValue);
    setFormData(dataSchema);
  }

  const handleChange = (event, field, value, isDate = false) => 
  {
    let newValue = value;

    if (isDate && newValue) 
    {
      newValue = dayjs(newValue).format("MM/DD/YYYY");
    }

    if (["Freight", "FOB_Value", "Value"].includes(field))
    {
      // Remove any non-numeric characters except the decimal point
      newValue = value.replace(/[^0-9.]/g, "");

      // Ensure the value has at most 2 decimal places
      if (newValue.includes(".")) 
      {
        const [integerPart, decimalPart] = newValue.split(".");
        newValue = `${integerPart}.${decimalPart.substring(0, 2)}`;
      }
    }

    if (field === "Booked_for_Month")
    {
      setFormData((previousFormData) => ({
        ...previousFormData,
        [field]: typeof newValue === "string" ? newValue.trim() === "" ? null : newValue : newValue ? newValue : null,
        Order_Date: null
      }));
    }
    else if (field === "Deal_Close_Date") 
    {
      setFormData((previousFormData) => ({
        ...previousFormData,
        [field]: typeof newValue === "string" ? newValue.trim() === "" ? null : newValue : newValue ? newValue : null,
        Order_Status: orderStatus.find((status) => status === "Closed") || null
      }));
    } 
    else if (field === "Freight" || field === "FOB_Value")
    {
      freightInputRef?.current?.setCustomValidity("");
      fobValueInputRef?.current?.setCustomValidity("");

      let freight = parseInt(formData["Freight"]) || 0;
      let fobValue = parseInt(formData["FOB_Value"]) || 0;

      if (field === "Freight")
      {
        freight = parseInt(newValue);
      }
      else if (field === "FOB_Value")
      {
        fobValue = parseInt(newValue);
      }

      const calculatedTotalAmount = freight + fobValue;
      const actualTotalAmount = formData["Total_Amount"] || 0;

      if (calculatedTotalAmount > actualTotalAmount)
      {
        event?.target?.setCustomValidity("The calculated total is more than the actual total amount.");
      }
      else if (calculatedTotalAmount < actualTotalAmount)
      {
        event?.target?.setCustomValidity("The calculated total is less than the actual total amount.");
      }

      setFormData((previousFormData) => ({
        ...previousFormData,
        [field]: typeof newValue === "string" ? newValue.trim() === "" ? null : newValue : newValue ? newValue : null
      }));
    }
    else 
    {
      setFormData((previousFormData) => ({
        ...previousFormData,
        [field]: typeof newValue === "string" ? newValue.trim() === "" ? null : newValue : newValue ? newValue : null
      }));
    }
  }

  const handleProductChange = (index, field, value) => 
  {
    let formattedValue = value;

    if (["Quantity", "Buying_Price", "Selling_Price"].includes(field)) 
    {
      // Remove any non-numeric characters except the decimal point
      formattedValue = value.replace(/[^0-9.]/g, "");

      if (formattedValue.includes(".")) 
      {
        const [integerPart, decimalPart] = formattedValue.split(".");

        if (field === "Quantity") 
        {
          // Ensure the value has at most 3 decimal places
          formattedValue = `${integerPart}.${decimalPart.substring(0, 3)}`;
        } 
        else 
        {
          // Ensure the value has at most 2 decimal places
          formattedValue = `${integerPart}.${decimalPart.substring(0, 2)}`;
        }
      }
    }

    const newProducts = [...formData.products];
    newProducts[index][field] = formattedValue;

    setFormData((previousFormData) => ({
      ...previousFormData,
      products: newProducts
    }));
  }

  const handleAddProduct = () => 
  {
    const products = [...formData.products];
    const isAnyProductFieldFilled =
      products.length > 0
        ? products.every((product) =>
            Object.values(product).some((value) =>
              Array.isArray(value) ? value.length > 0 : Boolean(value)
            )
          )
        : true;

    if (isAnyProductFieldFilled) 
    {
      const newProductData = {
        Product_Id: null,
        Unit_of_Measurement: "",
        Product_Grade_Id: null,
        Product_Specification: "",
        Product_Description: "",
        Quantity: "",
        Buying_Price: "",
        Selling_Price: "",
        HS_Code: ""
      };
      const updatedProducts = [...products, newProductData];

      setIsProductAccordionExpanded(updatedProducts?.length - 1);
      setFormData((previousFormData) => ({
        ...previousFormData,
        products: updatedProducts
      }));
    } 
    else 
    {
      setWarningText("Please fill in at least one field in the current product details before adding a new one.");
      setShowWarningDialog(true);
    }
  }

  const handleDeleteProduct = (index) => 
  {
    setFormData((previousFormData) => ({
      ...previousFormData,
      products: previousFormData.products.filter((_, i) => i !== index)
    }));
  }

  const handleSubmitWrapper = (event) =>
  {
    event?.preventDefault();

    if (isExistingSBLC && isSBLCFormUpdated && selectedBusinessUnit?.id === 6)
    {
      setWarningText("Updating the SBLC will remove it from Shipment Details. Do you want to continue?");
      setShowWarningDialog(true);
    }
    else
    {
      handleSubmit();
    }
  }

  const handleSubmit = async () => 
  {  
    setShowWarningDialog(false);
    setWarningText(null);
    setIsLoading(true);
    setShowAlert(false);
    setAlertMessage("");
    setAlertType("");
    
    let updatedFormData = {
      ...formData,
      Deal_PFI_Id: selectedPFI
    };

    updatedFormData = Object.fromEntries(Object.entries(updatedFormData).map(([key, value]) => [
      key,
      typeof value === "object" && value?.hasOwnProperty("value") ? value.id : value
    ]));

    const apiUrl = isExistingDealHeader && !selectedPFIToCopy
      ? isFertilizersAndPetroChemicalsDeal
        ? "/UpdateOrderDetailsFertilizersAndPetroChemicals"
        : isFoodsPolymersOrSBLCDeal 
          ? "/UpdateOrderDetailsPolymersAndFoods" 
          : ""
      : isFertilizersAndPetroChemicalsDeal
        ? "/AddOrderDetailsFertilizersAndPetroChemicals"
        : isFoodsPolymersOrSBLCDeal 
          ? "/AddOrderDetailsPolymersAndFoods" 
          : "";
    let isSBLCSaved = selectedBusinessUnit?.id === 6 ? false : true;

    if (selectedBusinessUnit?.id === 6)
    {
      let updatedSBLCFormData = {
        ...sblcFormData,
        Deal_PFI_Id: selectedPFI,
        User_Id: currentUser?.user_id
      };
      const sblcApiUrl = isExistingSBLC ? "/UpdateDealSBLCProductGrades" : "/AddDealSBLCProductGrades";

      const transformObject = (obj, seen = new Set()) => 
      {
        if (seen.has(obj)) return obj; // Return the object if it has already been processed
        if (typeof obj !== "object" || obj === null) return obj; // Base case for non-objects
        
        seen.add(obj); // Add the current object to the seen set
        
        if (Array.isArray(obj)) 
        {
          return obj.map(item =>
            typeof item === "object" && item !== null
              ? item.hasOwnProperty("value")
                ? item.id
                : transformObject(item, seen) // Recursively transform nested arrays
              : item
          );
        }
      
        return Object.fromEntries(
          Object.entries(obj).map(([key, value]) => [
            key,
            typeof value === "object" && value !== null
              ? Array.isArray(value)
                ? transformObject(value, seen)  // Handle arrays
                : value.hasOwnProperty("value")
                  ? value.id
                  : transformObject(value, seen)  // Recursively transform nested objects
              : value
          ])
        );
      }
  
      updatedSBLCFormData = transformObject(updatedSBLCFormData);

      await axios({
        method: "post",
        url: sblcApiUrl,
        data: updatedSBLCFormData
      })
      .then((response) => 
      {
        const { status, data } = response;

        if (status === 200) 
        {
          isSBLCSaved = true;
        } 
        else if (status === 400)
        {
          setShowAlert(true);
          setAlertMessage(data?.error || "An error occurred while processing your request. Please try again later or contact the site administrator.");
          setAlertType("error");
  
          if (data?.is_deal_locked)
          {
            setIsUpdated(false);
            setActiveTab("Deal No.");
          }
        }
        else 
        {
          setIsLoading(false);
          setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
          setAlertType("error");
          setShowAlert(true);
        }
      })
      .catch((error) => 
      {
        console.log("Add/Update Buying Leg SBLC Data Api: ", error);
        setIsLoading(false);
        
        if (error?.response?.status === 403) 
        {
          logOut();
        }
        else
        {
          setAlertMessage(
            error?.response?.status === 429
              ? "Request limit exceeded. Please try again later."
              : "An error occurred while processing your request. Please try again later or contact the site administrator."
          );
          setAlertType("error");
          setShowAlert(true);
        }
      });    
    }
        
    if (isSBLCSaved)
    {
      await axios({
        method: "post",
        url: apiUrl,
        data: updatedFormData
      })
      .then((response) => 
      {
        const { status, data } = response;

        if (status === 200) 
        {
          if (uploadedFiles.length > 0) 
          {
            handleUpload();
          } 
          else 
          {
            setIsLoading(false);

            if (selectedTab) 
            {
              setActiveTab(selectedTab);
            } 
            else 
            {
              setActiveTab("Buying Leg");
            }
          }

          setIsFormSavedOrDeleted(true);
          setIsUpdated(false);
        } 
        else if (status === 400)
        {
          setShowAlert(true);
          setAlertMessage(data?.error || "An error occurred while processing your request. Please try again later or contact the site administrator.");
          setAlertType("error");
  
          if (data?.is_deal_locked)
          {
            setIsUpdated(false);
            setActiveTab("Deal No.");
          }
        }
        else 
        {
          setIsLoading(false);
          setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
          setAlertType("error");
          setShowAlert(true);
        }
      })
      .catch((error) => 
      {
        console.log("Add/Update Deal Details Api: ", error);
        setIsLoading(false);
        
        if (error?.response?.status === 403) 
        {
          logOut();
        }
        else
        {
          setAlertMessage(
            error?.response?.status === 429
              ? "Request limit exceeded. Please try again later."
              : "An error occurred while processing your request. Please try again later or contact the site administrator."
          );
          setAlertType("error");
          setShowAlert(true);
        }
      });
    }
  }

  const handleDelete = async () => 
  {
    setShowDeleteModal(false);
    setIsLoading(true);
    setShowAlert(false);
    setAlertMessage("");
    setAlertType("");

    const fileIds = files.map((file) => file.file.id);
    let isSBLCDeleted = selectedBusinessUnit?.id === 6 ? false : true;

    if (selectedBusinessUnit?.id === 6)
    {
      await axios({
        method: "post",
        url: "/DeleteDealSBLCProductGrades",
        data: { 
          Deal_PFI_Id: selectedPFI,
          SBLC_Id: sblcFormData?.SBLC_Id?.id
        }
      })
      .then((response) => 
      {
        const { status, data } = response;

        if (status === 200) 
        {
          isSBLCDeleted = true;
          
          const resetSBLCFormData = {
            SBLC_Id: null,
            PFIs: [],
            PFI_Id: []
          }
          
          setSBLCFormData(resetSBLCFormData);
          setIsPFIAccordionExpanded(null);
          setIsProductGradeAccordionExpanded(null);
          setIsExistingSBLC(false);
        } 
        else if (status === 400)
        {
          setShowAlert(true);
          setAlertMessage(data?.error || "An error occurred while processing your request. Please try again later or contact the site administrator.");
          setAlertType("error");
  
          if (data?.is_deal_locked)
          {
            setIsUpdated(false);
            setActiveTab("Deal No.");
          }
        }
        else 
        {
          setIsLoading(false);
          setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
          setAlertType("error");
          setShowAlert(true);
        }
      })
      .catch((error) => 
      {
        console.log("Delete Buying Leg SBLC Api: ", error);
        setIsLoading(false);
        
        if (error?.response?.status === 403) 
        {
          logOut();
        }
        else
        {
          setAlertMessage(
            error?.response?.status === 429
              ? "Request limit exceeded. Please try again later."
              : "An error occurred while processing your request. Please try again later or contact the site administrator."
          );
          setAlertType("error");
          setShowAlert(true);
        }
      });
    }

    if (isSBLCDeleted)
    {
      await axios({
        method: "post",
        url: "/DeleteOrderDetails",
        data: { Deal_PFI_Id: selectedPFI }
      })
      .then(async (response) => 
      {
        const { status, data } = response;

        if (status === 200) 
        {
          await axios({
            method: "post",
            url: "/DeleteDocument",
            data: { fileIds }
          })
          .then((response) => 
          {
            setIsLoading(false);
            const { status } = response;

            if (status === 200) 
            {
              formRef?.current?.reset();

              setFormData((previousFormData) => ({
                ...previousFormData,
                Order_Status: "",
                Buyer_Id: null,
                Buying_Payment_Terms_Id: null,
                Buying_Tenor_Id: "",
                Seller_Id: null,
                Selling_Payment_Terms_Id: null,
                Selling_Tenor_Id: "",
                Booked_for_Month: null,
                Order_Date: null,
                Deal_Close_Date: null,
                Deal_Sheet_Approval_Date: null,
                Shipment_Month_And_Date: null,
                Freight: null,
                FOB_Value: null,
                Label: "",
                Value: null,
                Transaction_Type: null,
                products: []
              }));
              setFiles([]);
              setUploadedFiles([]);
              setIsExistingDealHeader(false);
              setIsFormSavedOrDeleted(true);
              setIsUpdated(false);
              setAlertMessage("Deal header data deleted successfully.");
              setAlertType("success");
              setShowAlert(true);
            } 
            else 
            {
              setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
              setAlertType("error");
              setShowAlert(true);
            }
          })
          .catch((error) => 
          {
            console.log("Delete Deal Header Documents Api: ", error);
            setIsLoading(false);
            
            if (error?.response?.status === 403) 
            {
              logOut();
            }
            else
            {
              setAlertMessage(
                error?.response?.status === 429
                  ? "Request limit exceeded. Please try again later."
                  : "An error occurred while processing your request. Please try again later or contact the site administrator."
              );
              setAlertType("error");
              setShowAlert(true);
            }
          });
        } 
        else if (status === 400)
        {
          setShowAlert(true);
          setAlertMessage(data?.error || "An error occurred while processing your request. Please try again later or contact the site administrator.");
          setAlertType("error");
  
          if (data?.is_deal_locked)
          {
            setIsUpdated(false);
            setActiveTab("Deal No.");
          }
        }
        else 
        {
          setIsLoading(false);
          setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
          setAlertType("error");
          setShowAlert(true);
        }
      })
      .catch((error) => 
      {
        console.log("Delete Deal Header Data Api: ", error);
        setIsLoading(false);
        
        if (error?.response?.status === 403) 
        {
          logOut();
        }
        else
        {
          setAlertMessage(
            error?.response?.status === 429
              ? "Request limit exceeded. Please try again later."
              : "An error occurred while processing your request. Please try again later or contact the site administrator."
          );
          setAlertType("error");
          setShowAlert(true);
        }
      });
    }
  }

  const handleAccordionChange = (index) => (event, isExpanded) => 
  {
    setIsProductAccordionExpanded(isExpanded ? index : isProductAccordionExpanded);

    if (isExpanded && productAccordionRefs.current[index]) 
    {
      // Adjust timeout as needed for smooth scrolling
      setTimeout(() => 
      {
        if (productAccordionRefs.current[index]?.scrollIntoView) 
        {
          productAccordionRefs.current[index].scrollIntoView({ behavior: "smooth" });
        }
      }, 300);
    }
  }

  const handleCloseDialog = () => 
  {
    setShowWarningDialog(false);
    setWarningText(null);
  }

  const handleCloseModal = () => 
  {
    setShowDeleteModal(false);
  }

  return (
    <div>
      <div className = "d-flex justify-content-between align-items-center">
        <Typography variant = "h5" gutterBottom className = "page_heading m-0">
          Deal Information
        </Typography>
        
        <div className = "copy-pfi autocomplete">
          <VirtualizedAutocomplete
            isMultiple = {false}
            isObject = {true}
            filterOn = "Select PFI to Auto Populate"
            options = {pfis}
            selectedOptions = {selectedPFIToCopy}
            handleSelectChange = {(filterOn, newValue) => handleSelectChange(newValue)}
          />
        </div>
      </div>

      <div className = "input-fields my-2 p-2">
        <form
          ref = {formRef}
          className = "progress_form_con mt-1"
          onSubmit = {handleSubmitWrapper}
        >
          <div className = "d-flex flex-column gap-3">
            <Grid
              container
              spacing = {2}
              className = "align-items-start autocomplete"
            >
              <Grid item xs = {3}>
                <VirtualizedAutocomplete
                  isMultiple = {false}
                  isObject = {false}
                  filterOn = "Deal Status"
                  options = {orderStatus}
                  selectedOptions = {formData?.Order_Status || null}
                  handleSelectChange = {(filterOn, newValue) => handleChange(null, "Order_Status", newValue)}
                />
              </Grid>

              <Grid item xs = {3}>
                <VirtualizedAutocomplete
                  isMultiple = {false}
                  isObject = {true}
                  filterOn = "Customer Name"
                  options = {vendors}
                  selectedOptions = {formData?.Buyer_Id || null}
                  handleSelectChange = {(filterOn, newValue) => handleChange(null, "Buyer_Id", newValue)}
                />
              </Grid>

              <Grid item xs = {3}>
                <VirtualizedAutocomplete
                  isMultiple = {false}
                  isObject = {true}
                  filterOn = "Selling Payment Terms"
                  options = {paymentTerms}
                  selectedOptions = {formData?.Selling_Payment_Terms_Id || null}
                  handleSelectChange = {(filterOn, newValue) => handleChange(null, "Selling_Payment_Terms_Id", newValue)}
                />
              </Grid>

              <Grid item xs = {3}>
                <VirtualizedAutocomplete
                  isMultiple = {false}
                  isObject = {true}
                  filterOn = "Selling Tenor"
                  options = {tenors}
                  selectedOptions = {formData?.Selling_Tenor_Id || null}
                  handleSelectChange = {(filterOn, newValue) => handleChange(null, "Selling_Tenor_Id", newValue)}
                />
              </Grid>

              <Grid item xs = {3}>
                <VirtualizedAutocomplete
                  isMultiple = {false}
                  isObject = {true}
                  filterOn = "Supplier Name"
                  options = {vendors}
                  selectedOptions = {formData?.Seller_Id || null}
                  handleSelectChange = {(filterOn, newValue) => handleChange(null, "Seller_Id", newValue)}
                />
              </Grid>

              <Grid item xs = {3}>
                <VirtualizedAutocomplete
                  isMultiple = {false}
                  isObject = {true}
                  filterOn = "Buying Payment Terms"
                  options = {paymentTerms}
                  selectedOptions = {formData?.Buying_Payment_Terms_Id || null}
                  handleSelectChange = {(filterOn, newValue) => handleChange(null, "Buying_Payment_Terms_Id", newValue)}
                />
              </Grid>

              <Grid item xs = {3}>
                <VirtualizedAutocomplete
                  isMultiple = {false}
                  isObject = {true}
                  filterOn = "Buying Tenor"
                  options = {tenors}
                  selectedOptions = {formData?.Buying_Tenor_Id || null}
                  handleSelectChange = {(filterOn, newValue) => handleChange(null, "Buying_Tenor_Id", newValue)}
                />
              </Grid>

              <Grid item xs = {3} className = "form-text-field">
                <LocalizationProvider dateAdapter = {AdapterDayjs}>
                  <DesktopDatePicker
                    className = "date-picker date-filter"
                    label = "Deal Open Month"
                    openTo = "month"
                    views = {["month", "year"]}
                    inputFormat = "MMM-YY"
                    disableMaskedInput
                    value = {formData?.Booked_for_Month || null}
                    onChange = {(newDate) => handleChange(null, "Booked_for_Month", newDate)}
                    onAccept = {(newDate) => handleChange(null, "Booked_for_Month", newDate)}
                    slotProps = {{
                      actionBar: {
                        actions: ["clear"]
                      },
                      textField: {
                        InputProps: {
                          size: "small",
                          disabled: true
                        }
                      }
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              
              <Grid item xs = {3} className = "form-text-field">
                <LocalizationProvider dateAdapter = {AdapterDayjs}>
                  <DesktopDatePicker
                    inputFormat = "MM/DD/YYYY"
                    label = "Deal Open Date"
                    disableMaskedInput
                    className = "date-picker w-full"
                    value = {convertToDayJsObject(formData?.Order_Date)}
                    onChange = {(newDate) => handleChange(null, "Order_Date", newDate, true)}
                    onAccept = {(newDate) => handleChange(null, "Order_Date", newDate, true)}
                    shouldDisableMonth = {(month) => shouldDisableDate(month, "Month")}
                    shouldDisableYear = {(year) => shouldDisableDate(year, "Year")}
                    InputProps = {{
                      required: formData?.Order_Status?.value === "Closed"
                    }}
                    slotProps = {{
                      actionBar: {
                        actions: ["clear"]
                      },
                      textField: {
                        InputProps: {
                          size: "small",
                          disabled: true
                        }
                      }
                    }}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs = {3} className = "form-text-field">
                <LocalizationProvider dateAdapter = {AdapterDayjs}>
                  <DesktopDatePicker
                    inputFormat = "MM/DD/YYYY"
                    label = "Deal Close Date"
                    className = "date-picker w-full"
                    disableMaskedInput
                    minDate = {convertToDayJsObject(formData?.Order_Date)}
                    value = {convertToDayJsObject(formData?.Deal_Close_Date)}
                    onChange = {(newDate) => handleChange(null, "Deal_Close_Date", newDate, true)}
                    onAccept = {(newDate) => handleChange(null, "Deal_Close_Date", newDate, true)}
                    slotProps = {{
                      actionBar: {
                        actions: ["clear"]
                      },
                      textField: {
                        InputProps: {
                          size: "small",
                          disabled: true
                        }
                      }
                    }}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs = {3} className = "form-text-field">
                <LocalizationProvider dateAdapter = {AdapterDayjs}>
                  <DesktopDateTimePicker
                    inputFormat = "MM/DD/YYYY hh:mm A"
                    label = "Deal Sheet Approval Date & Time"
                    className = "datetime-picker w-full"
                    disableMaskedInput
                    value = {formData?.Deal_Sheet_Approval_Date ? dayjs(formData?.Deal_Sheet_Approval_Date) : null}
                    onChange = {(newDateTime) => handleChange(null, "Deal_Sheet_Approval_Date", newDateTime)}
                    slotProps = {{
                      textField: {
                        InputProps: {
                          size: "small",
                          disabled: true
                        }
                      }
                    }}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs = {3} className = "form-text-field">
                <LocalizationProvider dateAdapter = {AdapterDayjs}>
                  <DesktopDatePicker
                    inputFormat = "MM/DD/YYYY"
                    label = "Shipment Month & Date"
                    className = "date-picker w-full"
                    disableMaskedInput
                    value = {convertToDayJsObject(formData?.Shipment_Month_And_Date)}
                    onChange = {(newDate) => handleChange(null, "Shipment_Month_And_Date", newDate, true)}
                    onAccept = {(newDate) => handleChange(null, "Shipment_Month_And_Date", newDate, true)}
                    slotProps = {{
                      actionBar: {
                        actions: ["clear"]
                      },
                      textField: {
                        InputProps: {
                          size: "small",
                          disabled: true
                        }
                      }
                    }}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs = {3} className = "form-text-field">
                <TextField
                  label = "Final Quantity"
                  id = "Final_Quantity"
                  type = "number"
                  value = {formData?.Final_Quantity || ""}
                  disabled
                  InputLabelProps = {{
                    shrink: formData?.Final_Quantity ? true : false
                  }}
                />
              </Grid>

              <Grid item xs = {12}>
                <hr />

                <div className = "w-100 d-flex justify-content-between mb-4">
                  <h3 className = "mb-0">PFI Document Values</h3>
                </div>

                <Grid
                  container
                  spacing = {2}
                  className = "align-items-start"
                >
                  <Grid item xs = {3} className = "form-text-field">
                    <TextField
                      label = "Total Amount"
                      id = "Total_Amount"
                      type = "number"
                      value = {formData?.Total_Amount || ""}
                      disabled
                      InputLabelProps = {{ shrink: formData?.Total_Amount }}
                    />
                  </Grid>

                  <Grid item xs = {3} className = "form-text-field">
                    <TextField
                      inputRef = {freightInputRef}
                      label = "Freight"
                      id = "Freight"
                      type = "number"
                      value = {formData?.Freight || ""}
                      onChange = {(event) => handleChange(event, "Freight", event.target.value)}
                    />
                  </Grid>

                  <Grid item xs = {3} className = "form-text-field">
                    <TextField
                      inputRef = {fobValueInputRef}
                      label = "FOB Value"
                      id = "FOB_Value"
                      value = {formData?.FOB_Value || ""}
                      onChange = {(event) => handleChange(event, "FOB_Value", event.target.value)}
                    />
                  </Grid>
                  
                  <Grid item xs = {3} className = "form-text-field">
                    <TextField
                      label = "Label"
                      id = "Label"
                      value = {formData?.Label || ""}
                      onChange = {(event) => handleChange(event, "Label", event.target.value)}
                    />
                  </Grid>

                  <Grid item xs = {3} className = "form-text-field">
                    <TextField
                      label = "Value"
                      id = "Value"
                      type = "number"
                      value = {formData?.Value || ""}
                      onChange = {(event) => handleChange(event, "Value", event.target.value)}
                    />
                  </Grid>

                  <Grid item xs = {3}>
                    <VirtualizedAutocomplete
                      isMultiple = {false}
                      isObject = {false}
                      filterOn = "Transaction Type"
                      options = {transactionTyoes}
                      selectedOptions = {formData?.Transaction_Type || null}
                      handleSelectChange = {(filterOn, newValue) => handleChange(null, "Transaction_Type", newValue)}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs = {12}>
                <hr />

                {selectedBusinessUnit?.id === 6 ? (
                  <DealSBLC 
                    selectedTab = "Deal Header"
                    sblcFormData = {sblcFormData}
                    isPFIAccordionExpanded = {isPFIAccordionExpanded}
                    isProductGradeAccordionExpanded = {isProductGradeAccordionExpanded}
                    setShowAlert = {setShowAlert}
                    setAlertMessage = {setAlertMessage}
                    setAlertType = {setAlertType}
                    setSBLCFormData = {setSBLCFormData}
                    setIsPFIAccordionExpanded = {setIsPFIAccordionExpanded}
                    setIsProductGradeAccordionExpanded = {setIsProductGradeAccordionExpanded}
                  />
                ) : (
                  <>
                    <div className = "w-100 d-flex justify-content-between align-items-center mb-2">
                      <h3 className = "mb-0">Products</h3>
                      <IconButton
                        className = "add_button"
                        color = "primary"
                        onClick = {handleAddProduct}
                      >
                        <AddIcon />
                      </IconButton>
                    </div>

                    {formData?.products?.map((product, index) => (
                      <Accordion
                        key = {index}
                        expanded = {isProductAccordionExpanded === index}
                        ref = {(element) => (productAccordionRefs.current[index] = element)}
                        onChange = {handleAccordionChange(index)}
                      >
                        <AccordionSummary
                          expandIcon = {<ExpandMoreIcon />}
                          aria-controls = {`product-content-${index}`}
                          id = {`product-header-${index}`}
                        >
                          <Typography>Product {index + 1}</Typography>
                          <IconButton
                            color = "error"
                            size = "medium"
                            onClick = {(event) => 
                            {
                              event.stopPropagation();
                              handleDeleteProduct(index);

                              if (isProductAccordionExpanded === index) 
                              {
                                setIsProductAccordionExpanded(0);
                              }
                            }}
                          >
                            <DeleteIcon fontSize = "medium" />
                          </IconButton>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid
                            container
                            spacing = {2}
                            className = "align-items-start autocomplete"
                          >
                            <Grid item xs = {3}>
                              <VirtualizedAutocomplete
                                isMultiple = {false}
                                isObject = {true}
                                isRequired = {true}
                                filterOn = "Product"
                                options = {products}
                                selectedOptions = {product.Product_Id || null}
                                handleSelectChange = {(filterOn, newValue) => handleProductChange(index, "Product_Id", newValue)}
                              />
                            </Grid>

                            <Grid item xs = {3} className = "form-text-field">
                              <TextField
                                label = "Unit of Measurement"
                                id = "Unit_of_Measurement"
                                value = {product?.Product_Id ? productData?.find((_product) => _product.id === product.Product_Id?.id)?.Unit_of_Measurement : ""}
                                disabled
                                InputLabelProps = {{
                                  shrink:
                                    product?.Product_Id &&
                                    productData?.some((_product) => _product.id === product.Product_Id.id && _product.Unit_of_Measurement)
                                }}
                              />
                            </Grid>

                            {isFoodsOrPolymersDeal && (
                              <Grid item xs = {3}>
                                <VirtualizedAutocomplete
                                  isMultiple = {false}
                                  isObject = {true}
                                  filterOn = "Product Grade"
                                  options = {productGrades}
                                  selectedOptions = {product.Product_Grade_Id || null}
                                  handleSelectChange = {(filterOn, newValue) => handleProductChange(index, "Product_Grade_Id", newValue)}
                                />
                              </Grid>
                            )}

                            <Grid item xs = {3} className = "form-text-field">
                              <TextField
                                label = {`${!isFoodsOrPolymersDeal ? "Product" : ""} Quantity`}
                                id = "Quantity"
                                type = "number"
                                value = {product.Quantity || ""}
                                onChange = {(event) => handleProductChange(index, "Quantity", event.target.value)}
                                InputProps = {{
                                  inputProps: { min: 0, max: 2147483647, step: "0.001" } 
                                }}
                                onKeyDown = {(event) => 
                                {
                                  if (event?.key === "e" || event?.key === "E" || event?.key === "-" || event?.key === "+") 
                                  {
                                    event.preventDefault();
                                  }
                                }}
                              />
                            </Grid>

                            <Grid item xs = {3} className = "form-text-field">
                              <TextField
                                label = {`${!isFoodsOrPolymersDeal ? "Product" : ""} Selling Price`}
                                id = "Selling_Price"
                                type = "number"
                                value = {product.Selling_Price || ""}
                                onChange = {(event) => handleProductChange(index, "Selling_Price", event.target.value)}
                                InputProps = {{ 
                                  inputProps: { min: 0, max: 2147483647, step: "0.01" } 
                                }}
                                onKeyDown = {(event) => 
                                {
                                  if (event?.key === "e" || event?.key === "E" || event?.key === "-" || event?.key === "+") 
                                  {
                                    event.preventDefault();
                                  }
                                }}
                              />
                            </Grid>

                            <Grid item xs = {3} className = "form-text-field">
                              <TextField
                                label = {`${!isFoodsOrPolymersDeal ? "Product" : ""} Buying Price`}
                                id = "Buying_Price"
                                type = "number"
                                value = {product.Buying_Price || ""}
                                onChange = {(event) => handleProductChange(index, "Buying_Price", event.target.value)}
                                InputProps = {{ 
                                  inputProps: { min: 0, max: 2147483647, step: "0.01" } 
                                }}
                                onKeyDown = {(event) => 
                                {
                                  if (event?.key === "e" || event?.key === "E" || event?.key === "-" || event?.key === "+") 
                                  {
                                    event.preventDefault();
                                  }
                                }}
                              />
                            </Grid>

                            <Grid item xs = {3} className = "form-text-field">
                              <TextField
                                label = {`${!isFoodsOrPolymersDeal ? "Product" : ""} HS Code`}
                                id = "HS_Code"
                                name = "HS_Code"
                                value = {product?.HS_Code ? product?.HS_Code?.toString() : ""}
                                onChange = {(event) => handleProductChange(index, "HS_Code", event.target.value)}
                                InputProps = {{ inputComponent: CustomTextMask }}
                              />
                            </Grid>

                            {isFoodsOrPolymersDeal && (
                              <Grid item xs = {4} className = "form-text-field">
                                <TextField
                                  label = "Specification"
                                  id = "Product_Specification"
                                  multiline
                                  rows = {3}
                                  value = {product.Product_Specification || ""}
                                  onChange = {(event) => handleProductChange(index, "Product_Specification", event.target.value)}
                                />
                              </Grid>
                            )}

                            <Grid item xs = {4} className = "form-text-field">
                              <TextField
                                label = {`${!isFoodsOrPolymersDeal ? "Product" : ""} Description`}
                                id = "Product_Description"
                                multiline
                                rows = {3}
                                value = {product.Product_Description || ""}
                                onChange = {(event) => handleProductChange(index, "Product_Description", event.target.value)}
                              />
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  </>
                )}
              </Grid>
            </Grid>

            <button ref = {submitButtonRef} type = "submit" className = "hidden"></button>
          </div>
        </form>
      </div>

      <div className = "d-flex gap-2 justify-content-end">
        <button
          type = "button"
          variant = "contained"
          color = "primary"
          disabled = {isLoading}
          className = "btn btn_secondary custom_btn"
          onClick = {() => submitButtonRef?.current?.click()}
        >
          {isExistingDealHeader ? "Update" : "Save"}
        </button>

        {isExistingDealHeader  && (
          <button
            type = "button"
            variant = "contained"
            color = "error"
            disabled = {isLoading}
            className = "btn custom_btn btn-danger"
            onClick = {() => setShowDeleteModal(true)}
          >
            Delete
          </button>
        )}
      </div>

      <Dialog open = {showWarningDialog} onClose = {handleCloseDialog}>
        <DialogContent>
          <DialogContentText className = "text-center">
            {warningText}
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          {selectedBusinessUnit?.id === 6 ? (
            <button
              type = "button"
              variant = "contained"
              className = "btn btn-primary custom_btn"
              onClick = {handleSubmit}
            >
              Yes
            </button>
          ) : (
            <button
              type = "button"
              variant = "contained"
              className = "btn btn-primary custom_btn"
              onClick = {handleCloseDialog}
            >
              Ok
            </button>
          )}
        </DialogActions>
      </Dialog>

      <Modal show = {showDeleteModal} onHide = {handleCloseModal} centered className = "delete-modal">
        <Modal.Header closeButton>
          <Modal.Title>Are you sure?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span>
            Do you really want to delete the form details? 
            {selectedBusinessUnit?.id === 6 && (
              <> This will cause the selected product grades to be deleted as well. </>
            )}
            This process cannot be undone.
          </span>
        </Modal.Body>
        <Modal.Footer className = "gap-2">
          <Button variant = "outlined" size = "small" onClick = {handleCloseModal}>
            Cancel
          </Button>
          <Button variant = "contained" size = "small" onClick = {handleDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
});

export default DealHeader;