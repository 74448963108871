import { Fragment, useEffect, useRef, useState } from 'react';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import VirtualizedAutocomplete from '../../../components/Autocomplete';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import dayjs from 'dayjs';
import axios from 'axios';

dayjs.extend(isSameOrBefore);

const SBLCForm = ({ selectedSBLC = null, isLoading = false, toggleDisplayData = false, setIsLoading = () => {}, setShowAlert = () => {}, setAlertMessage = () => {}, setAlertType = () => {}, setShowSBLCForm = () => {}, setSelectedSBLC = () => {}, setToggleDisplayData = () => {} }) => 
{
  /* AUTHENTICATION VARIABLES */
  const currentUser = useSelector((state) => state.user);

  /* LIST VARIABLES */
  const [banks, setBanks] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [tenors, setTenors] = useState([]);
  const [packagingTypes, setPackagingTypes] = useState([]);
  const [productData, setProductData] = useState([]);
  const [products, setProducts] = useState([]);
  const [productGrades, setProductGrades] = useState([]);

  /* FORM VARIABLES */
  const dataSchema = {
    SBLC_Number: null,
    Bank_Id: null,
    Supplier_Id: null,
    Amount: null,
    Available_Amount : null,
    Currency_Id: null,
    Tenor_Id: null,
    Validity_Date: null,
    PFIs: []
  };
  const [formData, setFormData] = useState(dataSchema);
  const [isExistingRecord, setIsExistingRecord] = useState(false);
  const formRef = useRef(null);
  const amountInputRef = useRef(null);
  const submitButtonRef = useRef(null);

  /* ACCORDION VARIABLES */
  const [isPFIAccordionExpanded, setIsPFIAccordionExpanded] = useState(null);
  const [isPFIProductGradeAccordionExpanded, setIsPFIProductGradeAccordionExpanded] = useState(null);
  const [isSIProductGradeAccordionExpanded, setIsSIProductGradeAccordionExpanded] = useState(null);
  const [isCIAccordionExpanded, setIsCIAccordionExpanded] = useState(null);
  const [isCIProductGradeAccordionExpanded, setIsCIProductGradeAccordionExpanded] = useState(null);
  const pfiAccordionRef = useRef({});
  const pfiProductGradeAccordionRef = useRef({});
  const siProductGradeAccordionRef = useRef({});
  const ciAccordionRef = useRef({});
  const ciProductGradeAccordianRef = useRef({});

  /* DIALOG VARIABLES */
  const [showWarningDialog, setShowWarningDialog] = useState(false);

  /* TOOLTIP VARIABLES */
  const [isOpenToolTip, setIsOpenToolTip] = useState(false);
  const HTMLToolTip = styled(({ className, ...props }) => (
    <Tooltip 
      {...props} 
      arrow 
      open = {isOpenToolTip}
      classes = {{ popper: className }} 
      className = "mr-2 cursor-pointer" 
      onOpen = {() => setIsOpenToolTip(true)}
      onClose = {() => setIsOpenToolTip(false)}
      sx = {{
        color: '#d32f2f'
      }}
    />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9'
    }
  }));

  /* WINDOW SCROLLING VARIABLES */
  const [scrollToTop, setScrollToTop] = useState(false);

  useEffect(() => 
  {
    const fetchLists = async () => 
    {
      axios({
        method: "get",
        url: "/GetBankList"
      })
      .then((response) => 
      {
        const { status, data } = response;

        if (status === 200) 
        {
          const banks = data?.banks || [];
          setBanks(banks);
        }
      })
      .catch((error) => 
      {
        console.log("Get Banks Api: ", error);
      });

      axios({
        method: "get",
        url: "/GetCurrencyList"
      })
      .then((response) => 
      {
        const { status, data } = response;

        if (status === 200) 
        {
          const currencies = data?.currencies || [];
          setCurrencies(currencies);
        }
      })
      .catch((error) => 
      {
        console.log("Get Currencies Api: ", error);
      });

      axios({
        method: "get",
        url: "/GetTenorList",
      })
      .then((response) => 
      {
        const { status, data } = response;

        if (status === 200) 
        {
          const tenors = data?.tenors || [];
          setTenors(tenors);
        }
      })
      .catch((error) => 
      {
        console.log("Get Buyers/Tenors Api: ", error);
      });

      axios({
        method: "post",
        url: "/GetVendorList",
        data: {
          business_unit_id: 6
        }
      })
      .then((response) => 
      {
        const { status, data } = response;

        if (status === 200) 
        {
          const vendors = data?.vendors || [];
          setVendors(vendors);
        }
      })
      .catch((error) => 
      {
        console.log("Get Filtered Sellers/Vendors Api: ", error);
      });

      axios({
        method: "get",
        url: "/GetPackagingTypeList"
      })
      .then((response) => 
      {
        const { status, data } = response;

        if (status === 200) 
        {
          const packagingTypes = data?.packaging_types || [];
          setPackagingTypes(packagingTypes);
        }
      })
      .catch((error) => 
      {
        console.log("Get Packaging Type Api: ", error);
      });

      axios({
        method: "get",
        url: "/GetProducts"
      })
      .then((response) => 
      {
        const { status, data } = response;

        if (status === 200) 
        {
          const productData = data?.product_data?.rows || [];
          setProductData(productData);
        }
      })
      .catch((error) => 
      {
        console.log("Get Products Api: ", error);
      });

      axios({
        method: "post",
        url: "/GetProductsList",
        data: {
          business_unit_id: 6
        }
      })
      .then((response) => 
      {
        const { status, data } = response;

        if (status === 200) 
        {
          const products = data?.product || [];
          setProducts(products);
        }
      })
      .catch((error) => 
      {
        console.log("Get Filtered Products Api: ", error);
      });

      axios({
        method: "get",
        url: "/GetProductGradeList"
      })
      .then((response) => 
      {
        const { status, data } = response;

        if (status === 200) 
        {
          const productGrades = data?.product_grades || [];
          setProductGrades(productGrades);
        }
      })
      .catch((error) => 
        {
        console.log("Get Product Grades Api: ", error);
      });
    }

    fetchLists();

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);
  
  useEffect(() => 
  {
    const fetchSBLCData = async () => 
    {
      setIsLoading(true);
      setShowAlert(false);
      setAlertMessage("");
      setAlertType("");

      await axios({
        method: "post",
        url: "/GetSingleSBLC",
        data: {
          sblc_id: selectedSBLC?.id || null
        }
      })
      .then((response) => 
      {
        setIsLoading(false);
        const { status, data = {} } = response;

        if (status === 200) 
        {
          const isEmpty = typeof data === "object" ? data?.SBLC_Id ? false : true : true;

          if (!isEmpty) 
          {
            const pfiIndex = (data?.PFIs?.length ?? 0) - 1;
            const pfiProductGradeIndex = (data?.PFIs?.[pfiIndex]?.Product_Grades?.length ?? 0) - 1;
            const siProductGradeIndex = (data?.PFIs?.[pfiIndex]?.SI?.Product_Grades?.length ?? 0) - 1;
            const ciIndex = (data?.PFIs?.[pfiIndex]?.SI?.CIs?.length ?? 0) - 1;
            const ciProductGradeIndex = (data?.PFIs?.[pfiIndex]?.SI?.CIs[ciIndex]?.Product_Grades?.length ?? 0) - 1;

            setIsPFIAccordionExpanded(pfiIndex);
            setIsPFIProductGradeAccordionExpanded(pfiProductGradeIndex);
            setIsSIProductGradeAccordionExpanded(siProductGradeIndex);
            setIsCIAccordionExpanded(ciIndex);
            setIsCIProductGradeAccordionExpanded(ciProductGradeIndex);
            setIsExistingRecord(true);
          }

          const setValidityDateToDayJsObject = (obj) => 
          {
            if (typeof obj !== "object" || obj === null) return;
          
            Object.keys(obj).forEach(key => 
            {
              if (key?.includes("Date")) 
              {
                obj[key] = obj[key] ? dayjs(obj[key]).format("MM/DD/YYYY") : obj[key];
              } 
              else if (typeof obj[key] === "object") 
              {
                setValidityDateToDayJsObject(obj[key]);
              }
            });
          }

          data["Available_Amount"] = data["Available_Amount"] || data["Amount"];

          setValidityDateToDayJsObject(data);
          setFormData(data);
        } 
        else 
        {
          setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
          setAlertType("error");
          setShowAlert(true);
        }
      })
      .catch((error) => 
      {
        console.log("Get SBLC Data Api: ", error);
        setIsLoading(false);
        setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
        setAlertType("error");
        setShowAlert(true);
      });
    }

    if (selectedSBLC) 
    {
      fetchSBLCData();
    }

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [selectedSBLC]);

  useEffect(() => 
  {
    let isScrolling = false; // Flag to prevent multiple event listeners

    const waitForScrollEnd = () => 
    {
      return new Promise((resolve) => 
      {
        const onScroll = () => 
        {
          // Check if the window has scrolled to the top
          if (window.scrollY === 0) 
          {
            window.removeEventListener('scroll', onScroll);
            resolve();
          }
        }

        if (!isScrolling) 
        {
          isScrolling = true;
          window.addEventListener('scroll', onScroll);
        } 
        else 
        {
          resolve(); // Immediately resolve if already scrolling
        }
      });
    }

    const handleScrollAndFocus = async () => 
    {
      if (formData?.Available_Amount < 0 || scrollToTop) 
      {
        // Scroll to the top
        window.scrollTo({ top: 0, behavior: 'smooth' });

        // Wait for the scroll to finish and then focus the input
        await waitForScrollEnd();
        amountInputRef.current?.focus();

        // Manage tooltip visibility
        setIsOpenToolTip(true);
        setTimeout(() => 
        {
          setIsOpenToolTip(false);
        }, 3000);

        // Reset 'scrollToTop' state
        setScrollToTop(false);
      }
    }

    handleScrollAndFocus();

    return () => 
    {
      window.removeEventListener('scroll', () => {});
    };
  }, [formData?.Available_Amount, scrollToTop]);

  const convertToDayJsObject = (date) => 
  {
    const convertedDate = date ? dayjs(date, "MM/DD/YYYY") : null;
    return convertedDate;
  }

  const calculateAvailableAmount = (pfis = [], amount = formData?.Amount || 0) => 
  {
    let updatedAvailableAmount = parseFloat(amount);

    pfis?.forEach(pfi => 
    {
      const productGradeMap = new Map();

      // Create a map for Buying_Price by Grade_Id.id for quick access
      pfi.Product_Grades?.forEach(productGrade => 
      {
        productGradeMap.set(productGrade?.Grade_Id?.id, parseFloat(productGrade?.Buying_Price));
      });      

      // Subtract SI_Quantity multiplied by Buying_Price
      pfi?.SI?.Product_Grades.forEach(product => 
      {
        const pfiProductGradeBuyingPrice = productGradeMap.get(product?.Grade_Id?.id);

        if (product?.SI_Quantity && pfiProductGradeBuyingPrice) 
        {
          updatedAvailableAmount -= parseFloat(product?.SI_Quantity) * pfiProductGradeBuyingPrice;
        }
      });

      // Add CI_Quantity multiplied by Buying_Price if Is_Paid is true
      pfi?.SI?.CIs?.forEach(ci => 
      {
        if (ci?.Is_Paid) 
        {
          ci?.Product_Grades?.forEach(product => 
          {
            const pfiProductGradeBuyingPrice = productGradeMap.get(product?.Grade_Id?.id);

            if (product?.CI_Quantity && pfiProductGradeBuyingPrice) 
            {
              updatedAvailableAmount += parseFloat(product?.CI_Quantity) * pfiProductGradeBuyingPrice;
            }
          });
        }
      });
    });

    updatedAvailableAmount = updatedAvailableAmount.toFixed(2);

    return updatedAvailableAmount;
  }

  const handleAccordionChange = (index, flag) => (_, newExpanded) => 
  {
    const isValid = formRef?.current?.checkValidity();

    if (isValid)
    {
      if (flag === "PFI") 
      {
        setIsPFIAccordionExpanded(newExpanded ? index : null);
      } 
      else if (flag === "PFI Product Grade") 
      {
        setIsPFIProductGradeAccordionExpanded(newExpanded ? index : null);
      }
      else if (flag === "SI Product Grade") 
      {
        setIsSIProductGradeAccordionExpanded(newExpanded ? index : null);
      }
      else if (flag === "CI") 
      {
        setIsCIAccordionExpanded(newExpanded ? index : null);
      } 
      else if (flag === "CI Product Grade") 
      {
        setIsCIProductGradeAccordionExpanded(newExpanded ? index : null);
      }
    }
    else 
    {
      handleFormValidation();
    }
  }

  const handleFormValidation = ({ message = null } = {}) => 
  {
    const formElements = formRef?.current?.elements;
  
    Array.from(formElements).forEach((element) => 
    {
      if (element.nodeName === "INPUT" && element.type !== "submit") 
      {
        const { value } = element;
        const isRequired = element.hasAttribute("required");
        const isAutocomplete = element?.className?.includes("MuiAutocomplete");
  
        if (!isAutocomplete && isRequired && !value?.trim()) 
        {
          element.setCustomValidity("Please fill in this field.");
        }
        else 
        {
          element.setCustomValidity("");
        }
      } 
      else if (message) 
      {
        // Ensure the message is set only when provided
        element.setCustomValidity(message);
      }
    });
  
    formRef.current.reportValidity();
  }
  
  const handleSBLCChange = (field, value, isDate = false) => 
  {
    let newValue = value;
  
    if (isDate) 
    {
      newValue = dayjs(newValue).format("MM/DD/YYYY");
    }
  
    if (field === "Amount") 
    {
      // Remove any non-numeric characters except the decimal point
      newValue = value.replace(/[^0-9.]/g, "");
  
      // Ensure the value has at most 2 decimal places
      if (newValue.includes(".")) 
      {
        const [integerPart, decimalPart] = newValue.split(".");
        newValue = `${integerPart}.${decimalPart.substring(0, 2)}`;
      }

      const updatedAvailableAmount = calculateAvailableAmount(formData?.PFIs, newValue);
  
      setFormData((previousFormData) => ({
        ...previousFormData,
        [field]: typeof newValue === "string" ? newValue.trim() === "" ? null : newValue : newValue ? newValue : null,
        Available_Amount: updatedAvailableAmount,
        isUpdated: true
      }));
    }
    else
    {
      setFormData((previousFormData) => ({
        ...previousFormData,
        [field]: typeof newValue === "string" ? newValue.trim() === "" ? null : newValue : newValue ? newValue : null,
        isUpdated: true
      }));
    }
  }
  
  const handleAddWrapper = (type, pfiIndex = null, ciIndex = null) => 
  {
    const isValid = formRef?.current?.checkValidity();

    if (isValid) 
    {
      if (type === "PFI") 
      {
        handleAddPFI();
      }
      else if (type === "PFI Product Grade") 
      {
        handleAddPFIProductGrade(pfiIndex);
      }
      else if (type === "SI Product Grade") 
      {
        handleAddSIProductGrade(pfiIndex);
      }
      else if (type === "CI") 
      {
        handleAddCI(pfiIndex);
      }
      else if (type === "CI Product Grade") 
      {
        handleAddCIProductGrade(pfiIndex, ciIndex);
      }
    } 
    else 
    {
      handleFormValidation();
    }
  }

  const handlePFIChange = (pfiIndex, value, field, type, pfiProductGradeIndex, isDate = false) => 
  {
    let newValue = value;

    if (isDate) 
    {
      newValue = dayjs(newValue).format("MM/DD/YYYY");
    }

    setFormData((previousFormData) => 
    {
      const updatedPFIs = [...(previousFormData.PFIs || [])];
      let updatedAvailableAmount = formData?.Available_Amount;

      // Ensure 'pfiIndex' exists and initialize 'isNew' and 'isUpdated'
      if (!updatedPFIs[pfiIndex]) 
      {
        updatedPFIs[pfiIndex] = { isNew: true, isUpdated: false };
      }

      // Handle nested field updates
      if (type === "Product Grade") 
      {
        const updatedPFIProductGrades = [...(updatedPFIs[pfiIndex]?.Product_Grades || [])];
        const updatedSI = {...(updatedPFIs[pfiIndex]?.SI || {})};
        const updatedCIs = [...(updatedSI?.CIs || [])];

        // Mark 'isUpdated' as true if it's not new or does not have 'isNew' property
        if (!updatedPFIProductGrades[pfiProductGradeIndex]?.isNew) 
        {
          updatedPFIProductGrades[pfiProductGradeIndex].isUpdated = true;
        }

        if (field === "PFI_Quantity" || field === "Buying_Price") 
        {
          // Remove any non-numeric characters except the decimal point
          newValue = newValue.replace(/[^0-9.]/g, "");
    
          // Ensure the value has at most 3 decimal places
          if (newValue.includes(".")) 
          {
            const [integerPart, decimalPart] = newValue.split(".");
    
            if (field === "PFI_Quantity") 
            {
              newValue = `${integerPart}.${decimalPart.substring(0, 3)}`;
            } 
            else 
            {
              newValue = `${integerPart}.${decimalPart.substring(0, 2)}`;

              updatedSI.CIs = updatedCIs.map(ci => ({
                ...ci,
                Is_Paid: false
              }));
            }
          }
        }
        else
        {
          updatedSI.CIs = updatedCIs.map(ci => ({
            ...ci,
            Product_Grades: ci?.Product_Grades?.filter(productGrade => productGrade?.Grade_Id?.id !== updatedPFIProductGrades[pfiProductGradeIndex]?.Grade_Id?.id)
          }));
          updatedSI.Product_Grades = updatedSI?.Product_Grades?.filter(productGrade => productGrade?.Grade_Id?.id !== updatedPFIProductGrades[pfiProductGradeIndex]?.Grade_Id?.id) || [];
        }
        
        updatedPFIProductGrades[pfiProductGradeIndex] = { ...updatedPFIProductGrades[pfiProductGradeIndex], [field]: newValue };
        updatedPFIs[pfiIndex].SI = updatedSI;
        updatedPFIs[pfiIndex].Product_Grades = updatedPFIProductGrades;

        if (field === "Buying_Price")
        {
          updatedAvailableAmount = calculateAvailableAmount(updatedPFIs);

          return { ...previousFormData, Available_Amount: updatedAvailableAmount, PFIs: updatedPFIs, isUpdated: true };
        }
      } 
      else 
      {
        // Mark 'isUpdated' as true if it is not new or does not have 'isNew' property
        if (!updatedPFIs[pfiIndex]?.isNew) 
        {
          updatedPFIs[pfiIndex].isUpdated = true;
        }

        updatedPFIs[pfiIndex][field] = newValue;
      }

      return { ...previousFormData, PFIs: updatedPFIs };
    });
  }

  const handleAddPFI = () => 
  {
    const pfis = [...(formData?.PFIs || [])];
    const isAnyPFIFieldFilled = pfis?.length > 0 
      ? pfis.every((pfi) => 
        Object.entries(pfi)
          .filter(([key]) => key !== 'isNew' && key !== 'isUpdated')
          .some(([_, value]) => 
          {
            if (typeof value === 'object' && value !== null) 
            {
              return Array.isArray(value)
                ? value.length > 0
                : Object.values(value).some(subValue => 
                    subValue !== undefined && subValue !== "" && subValue !== null &&
                    (typeof subValue !== 'object' || (Array.isArray(subValue) ? subValue.length > 0 : true))
                  );
            }

            return value !== undefined && value !== "" && value !== null &&
              (typeof value !== 'object' || (Array.isArray(value) ? value.length > 0 : true));
          })
      )
    : true;

    if (isAnyPFIFieldFilled) 
    {
      const newPFIData = {
        PFI_Number: null,
        Validity_Date: null,
        Product_Id: null,
        Packaging_Type_Id: null,
        Product_Grades: [],
        SI: {
          Additional_Conditions: "",
          Remarks: "",
          Product_Grades: [],
          isUpdated: false
        },
        isNew: true,
        isUpdated: false
      };

      setIsPFIAccordionExpanded(pfis?.length);
      setFormData((previousFormData) => ({
        ...previousFormData,
        PFIs: [...(previousFormData?.PFIs || []), newPFIData]
      }));
    } 
    else 
    {
      setShowWarningDialog(true);
    }
  }

  const handleDeletePFI = (event, pfiIndex) => 
  {
    event.stopPropagation();

    const pfis = [...(formData?.PFIs || [])];

    pfis?.splice(pfiIndex, 1);
    const updatedAvailableAmount = calculateAvailableAmount(pfis);

    setIsPFIAccordionExpanded(pfis?.length - 1);
    setFormData((previousFormData) => ({
      ...previousFormData,
      Available_Amount: updatedAvailableAmount,
      PFIs: pfis,
      isUpdated: true
    }));
  }

  const handleAddPFIProductGrade = (pfiIndex) => 
  {
    const pfis = [...(formData?.PFIs || [])];
    const productGrades = [...pfis[pfiIndex]?.Product_Grades || []];
    const newProductGrade = {
      Grade_Id: null,
      PFI_Quantity: null,
      Buying_Price: null,
      isNew: true,
      isUpdated: false
    };

    setIsPFIProductGradeAccordionExpanded(productGrades.length);
    setFormData((previousFormData) => 
    {
      const updatedPFIs = [...(previousFormData?.PFIs || [])];
    
      updatedPFIs[pfiIndex] = {
        ...updatedPFIs[pfiIndex],
        Product_Grades: [...productGrades, newProductGrade]
      };

      return { ...previousFormData, PFIs: updatedPFIs };
    });
  }

  const handleDeletePFIProductGrade = (event, pfiIndex, pfiProductGradeIndex) => 
  {
    event.stopPropagation();

    setFormData((previousFormData) => 
    {
      const updatedPFIs = [...(previousFormData.PFIs || [])];
      const updatedPFIProductGrades = [...(updatedPFIs[pfiIndex]?.Product_Grades || [])];
      const updatedSI = {...(updatedPFIs[pfiIndex]?.SI || {})};
      const updatedCIs = [...(updatedSI?.CIs || [])];

      updatedSI.CIs = updatedCIs.map(ci => ({
        ...ci,
        Product_Grades: ci?.Product_Grades?.filter(productGrade => productGrade?.Grade_Id?.id !== updatedPFIProductGrades[pfiProductGradeIndex]?.Grade_Id?.id)
      }));
      updatedSI.Product_Grades = updatedSI?.Product_Grades?.filter(productGrade => productGrade?.Grade_Id?.id !== updatedPFIProductGrades[pfiProductGradeIndex]?.Grade_Id?.id) || [];
      updatedPFIProductGrades?.splice(pfiProductGradeIndex, 1);
      updatedPFIs[pfiIndex].SI = updatedSI;
      updatedPFIs[pfiIndex].Product_Grades = updatedPFIProductGrades;
      
      const updatedAvailableAmount = calculateAvailableAmount(updatedPFIs);

      setIsPFIProductGradeAccordionExpanded(updatedPFIProductGrades?.length - 1);

      return { ...previousFormData, Available_Amount: updatedAvailableAmount, PFIs: updatedPFIs, isUpdated: true };
    });
  }

  const handleSIChange = (pfiIndex, value, field, type, siProductGradeIndex) => 
  {
    let newValue = value;
  
    setFormData((previousFormData) => 
    {
      const updatedPFIs = [...(previousFormData.PFIs || [])];
      const updatedSI = { ...(updatedPFIs[pfiIndex]?.SI || {}) };
  
      if (type === "Product Grade") 
      {
        const updatedCIs = [...(updatedSI?.CIs || [])];
        const updatedSIProductGrades = [...(updatedSI?.Product_Grades || [])];
        let updatedAvailableAmount = previousFormData?.Available_Amount;
  
        if (!updatedSIProductGrades[siProductGradeIndex]?.isNew) 
        {
          updatedSIProductGrades[siProductGradeIndex].isUpdated = true;
        }
  
        if (field === "SI_Quantity") 
        {
          newValue = newValue.replace(/[^0-9.]/g, "");
  
          if (newValue.includes(".")) 
          {
            const [integerPart, decimalPart] = newValue.split(".");
            newValue = `${integerPart}.${decimalPart.substring(0, 3)}`;
          }

          updatedSI.CIs = updatedCIs.map(ci => ({
            ...ci,
            Is_Paid: false
          }));
        }
        else
        {
          updatedSI.CIs = updatedCIs.map(ci => ({
            ...ci,
            Product_Grades: ci?.Product_Grades?.filter(productGrade => productGrade?.Grade_Id?.id !== updatedSIProductGrades[siProductGradeIndex]?.Grade_Id?.id)
          }));
        }
  
        updatedSIProductGrades[siProductGradeIndex] = { ...updatedSIProductGrades[siProductGradeIndex], [field]: newValue };
        updatedSI.Product_Grades = updatedSIProductGrades;
        updatedPFIs[pfiIndex].SI = updatedSI;

        if (field === "SI_Quantity")
        {
          updatedAvailableAmount = calculateAvailableAmount(updatedPFIs);
          return { ...previousFormData, Available_Amount: updatedAvailableAmount, PFIs: updatedPFIs, isUpdated: true };
        }
      } 
      else 
      {
        updatedSI[field] = newValue;

        // Mark 'isUpdated' as true if PFI is not new or does not have 'isNew' property
        if (!updatedPFIs[pfiIndex]?.isNew) 
        {
          updatedSI.isUpdated = true;
        }
  
        updatedPFIs[pfiIndex].SI = updatedSI;
      }

      return { ...previousFormData, PFIs: updatedPFIs };
    });
  }
  
  const handleAddSIProductGrade = (pfiIndex) => 
  {
    const pfis = [...(formData?.PFIs || [])];
    const si = {...(pfis[pfiIndex]?.SI || {})};
    const productGrades = [...(si?.Product_Grades || [])];
    const newProductGrade = {
      Grade_Id: null,
      SI_Quantity: null,
      isNew: true,
      isUpdated: false
    };

    setIsSIProductGradeAccordionExpanded(productGrades.length);
    setFormData((previousFormData) => 
    {
      const updatedPFIs = [...(previousFormData?.PFIs || [])];
      const updatedSI = {...(updatedPFIs[pfiIndex]?.SI || {})};
    
      updatedSI.Product_Grades = [...productGrades, newProductGrade];
      updatedPFIs[pfiIndex].SI = updatedSI;

      return { ...previousFormData, PFIs: updatedPFIs };
    });
  }

  const handleDeleteSIProductGrade = (event, pfiIndex, siProductGradeIndex) => 
  {
    event.stopPropagation();
  
    setFormData((previousFormData) => 
    {
      const updatedPFIs = [...(previousFormData.PFIs || [])];
      const updatedSI = { ...(updatedPFIs[pfiIndex]?.SI || {}) };
      const updatedSIProductGrades = [...(updatedSI?.Product_Grades || [])];
      const updatedCIs = [...(updatedSI?.CIs || [])];
  
      // Remove the product grade from both CI (if it exists) and SI
      updatedSI.CIs = updatedCIs.map(ci => ({
        ...ci,
        Product_Grades: ci?.Product_Grades?.filter(productGrade => productGrade?.Grade_Id?.id !== updatedSIProductGrades[siProductGradeIndex]?.Grade_Id?.id)
      }));
      updatedSIProductGrades.splice(siProductGradeIndex, 1);
      updatedSI.Product_Grades = updatedSIProductGrades;  
      updatedPFIs[pfiIndex].SI = updatedSI;

      const updatedAvailableAmount = calculateAvailableAmount(updatedPFIs);
      
      setIsSIProductGradeAccordionExpanded(updatedSIProductGrades.length - 1);
  
      return { ...previousFormData, Available_Amount: updatedAvailableAmount, PFIs: updatedPFIs, isUpdated: true };
    });
  }

  const handleCIChange = (pfiIndex, value, field, type, ciIndex, ciProductGradeIndex, isDate = false) => 
  {
    let newValue = value;

    if (isDate) 
    {
      newValue = dayjs(newValue).format("MM/DD/YYYY");
    }
  
    setFormData((previousFormData) => 
    {
      const updatedPFIs = [...(previousFormData.PFIs || [])];
      const updatedSI = { ...(updatedPFIs[pfiIndex]?.SI || {}) };
      const updatedCIs = [...(updatedSI?.CIs || [])];
      const updatedCIProductGrades = [...(updatedCIs[ciIndex]?.Product_Grades || [])];
  
      if (!updatedCIs[ciIndex]) 
      {
        updatedPFIs[pfiIndex] = { isNew: true, isUpdated: false };
      }
  
      if (type === "Product Grade") 
      {  
        if (!updatedCIProductGrades[ciProductGradeIndex]?.isNew) 
        {
          updatedCIProductGrades[ciProductGradeIndex].isUpdated = true;
        }
  
        if (field === "CI_Quantity") 
        {
          newValue = newValue.replace(/[^0-9.]/g, "");
  
          if (newValue.includes(".")) 
          {
            const [integerPart, decimalPart] = newValue.split(".");
            newValue = `${integerPart}.${decimalPart.substring(0, 3)}`;
          }
        }
  
        updatedCIProductGrades[ciProductGradeIndex] = { ...updatedCIProductGrades[ciProductGradeIndex], [field]: newValue };
        updatedCIs[ciIndex].Product_Grades = updatedCIProductGrades;
        updatedPFIs[pfiIndex].SI = updatedSI;
      } 
      else 
      {
        let updatedAvailableAmount = previousFormData?.Available_Amount;
        
        if (!updatedCIs[ciIndex]?.isNew) 
        {
          updatedCIs[ciIndex].isUpdated = true;
        }
        
        updatedCIs[ciIndex][field] = newValue;
        updatedSI.CIs = updatedCIs;
        updatedPFIs[pfiIndex].SI = updatedSI;

        if (field === "Is_Paid")
        {
          updatedAvailableAmount = calculateAvailableAmount(updatedPFIs);

          if (parseFloat(updatedAvailableAmount) > parseFloat(previousFormData?.Amount))
          {
            updatedAvailableAmount = previousFormData?.Available_Amount;
            updatedCIs[ciIndex][field] = false;
            updatedCIs[ciIndex].isAmountExceeded = true;

            if (!isOpenToolTip) 
            {
              setIsOpenToolTip(true);
          
              setTimeout(() =>
              {
                updatedCIs[ciIndex].isAmountExceeded = false;
                setIsOpenToolTip(false);
              }, 3000);
            }
          }

          return { ...previousFormData, Available_Amount: updatedAvailableAmount, PFIs: updatedPFIs, isUpdated: true };
        }
      }

      return { ...previousFormData, PFIs: updatedPFIs };
    });
  }
  
  const handleAddCI = (pfiIndex) => 
  {
    const pfis = [...(formData?.PFIs || [])];
    const si = {...(pfis[pfiIndex]?.SI || {})};
    const cis = [...(si?.CIs || [])];
    const isAnyCIFieldFilled = cis?.length > 0 
      ? cis.every((ci) => 
        Object.entries(ci)
          .filter(([key]) => key !== 'isNew' && key !== 'isUpdated')
          .some(([_, value]) => 
          {
            if (typeof value === 'object' && value !== null) 
            {
              return Array.isArray(value)
                ? value.length > 0
                : Object.values(value).some(subValue => 
                    subValue !== undefined && subValue !== "" && subValue !== null &&
                    (typeof subValue !== 'object' || (Array.isArray(subValue) ? subValue.length > 0 : true))
                  );
            }

            return value !== undefined && value !== "" && value !== null &&
              (typeof value !== 'object' || (Array.isArray(value) ? value.length > 0 : true));
          })
      )
    : true;

    if (isAnyCIFieldFilled)
    {
      const newCIData = {
        CI_Number: null,
        Shipment_Date: null,
        Master_BL_Number: null,
        Is_Paid: false,
        Product_Grades: [],
        isNew: true,
        isUpdated: false
      };
  
      setIsCIAccordionExpanded(cis?.length);
      setFormData((previousFormData) => 
      {
        const updatedPFIs = [...(previousFormData?.PFIs || [])];
        const updatedSI = {...(updatedPFIs[pfiIndex]?.SI || {})};
    
        updatedSI.CIs = [...cis, newCIData];
        updatedPFIs[pfiIndex].SI = updatedSI;
  
        return { ...previousFormData, PFIs: updatedPFIs };
      });
    }
    else
    {
      setShowWarningDialog(true);
    }
  }

  const handleDeleteCI = (event, pfiIndex, ciIndex) => 
  {
    event.stopPropagation();
  
    setFormData((previousFormData) => 
    {
      const updatedPFIs = [...(previousFormData.PFIs || [])];
      const updatedSI = { ...(updatedPFIs[pfiIndex]?.SI || {}) };
      const updatedCIs = [...(updatedSI?.CIs || [])];
  
      updatedCIs.splice(ciIndex, 1);
      updatedSI.CIs = updatedCIs;
      updatedPFIs[pfiIndex].SI = updatedSI;

      const updatedAvailableAmount = calculateAvailableAmount(updatedPFIs);

      setIsCIAccordionExpanded(updatedCIs?.length);

      return { ...previousFormData, Available_Amount: updatedAvailableAmount, PFIs: updatedPFIs, isUpdated: true };
    });
  }
  
  const handleAddCIProductGrade = (pfiIndex, ciIndex) => 
  {
    const pfis = [...(formData?.PFIs || [])];
    const si = {...(pfis[pfiIndex]?.SI || {})};
    const cis = [...(si?.CIs ||  [])];
    const productGrades = [...(cis[ciIndex]?.Product_Grades || [])];
    const newProductGrade = {
      Grade_Id: null,
      CI_Quantity: null,
      isNew: true,
      isUpdated: false
    };

    setIsCIProductGradeAccordionExpanded(productGrades.length);
    setFormData((previousFormData) => 
    {
      const updatedPFIs = [...(previousFormData?.PFIs || [])];
      const updatedSI = {...(updatedPFIs[pfiIndex]?.SI || {})};
      const updatedCIs = [...(updatedSI?.CIs || [])];
    
      updatedCIs[ciIndex].Product_Grades = [...productGrades, newProductGrade];
      updatedSI.CIs = updatedCIs;
      updatedPFIs[pfiIndex].SI = updatedSI;

      return { ...previousFormData, PFIs: updatedPFIs };
    });
  }

  const handleDeleteCIProductGrade = (event, pfiIndex, ciIndex, ciProductGradeIndex) => 
  {
    event.stopPropagation();

    setFormData((previousFormData) => 
    {
      const updatedPFIs = [...(previousFormData.PFIs || [])];
      const updatedSI = {...(updatedPFIs[pfiIndex]?.SI || {})};
      const updatedCIs = [...(updatedSI?.CIs || [])];
      const updatedCIProductGrades = [...(updatedCIs[ciIndex]?.Product_Grades || [])];

      updatedCIProductGrades?.splice(ciProductGradeIndex, 1);
      updatedCIs[ciIndex].Product_Grades = updatedCIProductGrades;
      updatedSI.CIs = updatedCIs;
      updatedPFIs[pfiIndex].SI = updatedSI;

      const updatedAvailableAmount = calculateAvailableAmount(updatedPFIs);
      
      setIsCIProductGradeAccordionExpanded(updatedCIProductGrades?.length - 1);

      return { ...previousFormData, Available_Amount: updatedAvailableAmount, PFIs: updatedPFIs, isUpdated: true };
    });
  }

  const handleSubmitWrapper = () =>
  {
    if (formData?.Available_Amount < 0)
    {
      setScrollToTop(true);
    }
    else
    {
      submitButtonRef?.current?.click();
    }
  }

  const handleSubmit = async (event) => 
  {
    event?.preventDefault();

    setIsLoading(true);
    setShowAlert(false);
    setAlertMessage("");
    setAlertType("");

    let updatedFormData = {
      ...formData,
      User_Id: currentUser?.user_id
    };

    const transformObject = (obj, seen = new Set()) => 
    {
      if (seen.has(obj)) return obj; // Return the object if it has already been processed
      if (typeof obj !== "object" || obj === null) return obj; // Base case for non-objects
      
      seen.add(obj); // Add the current object to the seen set
      
      if (Array.isArray(obj)) 
      {
        return obj.map(item =>
          typeof item === "object" && item !== null
            ? item.hasOwnProperty("value")
              ? item.id
              : transformObject(item, seen) // Recursively transform nested arrays
            : item
        );
      }
    
      return Object.fromEntries(
        Object.entries(obj).map(([key, value]) => [
          key,
          typeof value === "object" && value !== null
            ? Array.isArray(value)
              ? transformObject(value, seen)  // Handle arrays
              : value.hasOwnProperty("value")
                ? value.id
                : transformObject(value, seen)  // Recursively transform nested objects
            : value
        ])
      );
    }

    updatedFormData = transformObject(updatedFormData);

    const apiUrl = isExistingRecord ? "/UpdateSingleSBLC" : "/AddSingleSBLC";

    await axios({
      method: "post",
      url: apiUrl,
      headers: { "Content-Type": "application/json" },
      data: updatedFormData
    })
    .then((response) => 
    {
      setIsLoading(false);
      const { status } = response;

      if (status === 200) 
      {
        setAlertMessage("Data saved successfully.");
        setAlertType("success");
        setShowAlert(true);

        setTimeout(() =>
        {
          handleReset();
          handleBackPress();
        }, 1000);
      } 
      else 
      {
        setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
        setAlertType("error");
        setShowAlert(true);
      }
    })
    .catch((error) => 
    {
      console.log("Add/Update SBLC Data Api: ", error);
      setIsLoading(false);
      setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
      setAlertType("error");
      setShowAlert(true);
    });
  }

  const handleReset = () =>
  {
    setFormData({});
    setIsExistingRecord(false);
    setIsPFIAccordionExpanded(null);
    setIsPFIProductGradeAccordionExpanded(null);
    setIsSIProductGradeAccordionExpanded(null);
    setIsCIAccordionExpanded(null);
    setIsCIProductGradeAccordionExpanded(null);
  }

  const handleCloseDialog = () => 
  {
    setShowWarningDialog(false);
  }

  const handleBackPress = () =>
  {
    setShowSBLCForm(false);
    setSelectedSBLC(null);
    setToggleDisplayData(!toggleDisplayData);
  }

  return (
    <div className = "position-relative">
      <div className = "d-flex justify-content-start align-items-center gap-2 mb-2">
        <IconButton color = "primary" sx = {{ boxShadow: 3 }} onClick = {handleBackPress}>
          <ArrowBackIcon fontSize = "medium" />
        </IconButton>

        <h3 className = "m-0">{selectedSBLC ? "Edit SBLC" : "Add SBLC"}</h3>
      </div>

      <Backdrop
        sx = {{ color: "#fff", position: 'absolute', height: '100%', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open = {isLoading}
      >
        <CircularProgress color = "inherit" />
      </Backdrop>

      <form
        ref = {formRef}
        className = "progress_form_con multi-form-container mt-3 p-2"
        onSubmit = {handleSubmit}
      >
        <div className = "d-flex flex-column gap-3">
          <Grid
            container
            spacing = {2}
            className = "align-items-start autocomplete"
          >
            <Grid item xs = {12}>
              <div className = "w-100 mt-1 mb-2">
                <h3 className = "mb-0">Basic Information</h3>
              </div>
            </Grid>
            
            <Grid item xs = {3} className = "form-text-field">
              <TextField
                label = "SBLC Number"
                id = "SBLC_Number"
                value = {formData?.SBLC_Number || ""}
                required
                onChange = {(event) => handleSBLCChange("SBLC_Number", event.target.value)}
              />
            </Grid>

            <Grid item xs = {3}>
              <VirtualizedAutocomplete
                isMultiple = {false}
                isObject = {true}
                filterOn = "Bank"
                options = {banks}
                selectedOptions = {formData?.Bank_Id || null}
                handleSelectChange = {(filterOn, newValue) => handleSBLCChange("Bank_Id", newValue)}
              />
            </Grid>

            <Grid item xs = {3}>
              <VirtualizedAutocomplete
                isMultiple = {false}
                isObject = {true}
                filterOn = "Supplier"
                options = {vendors}
                selectedOptions = {formData?.Supplier_Id || null}
                handleSelectChange = {(filterOn, newValue) => handleSBLCChange("Supplier_Id", newValue)}
              />
            </Grid>

            <Grid item xs = {3} className = "form-text-field">
              <TextField
                inputRef = {amountInputRef}
                label = "Amount"
                id = "Amount"
                name = "Amount"
                type = "number"
                value = {formData?.Amount || ""}
                error = {formData?.Available_Amount < 0}
                onChange = {(event) => handleSBLCChange("Amount", event.target.value)}
                InputProps = {{ 
                  inputProps: { 
                    min: 0, 
                    max: 2147483647,
                    step: "0.01" 
                  },
                  endAdornment: (
                    <Fragment>
                      {formData?.Available_Amount < 0 && (
                        <HTMLToolTip
                          title = {
                            <Fragment>
                              <Typography color = "error">Insufficient Amount!</Typography>
                              <span className = "amount-error-message">
                                Please include an appropriate SBLC amount to cover the costs of the Shipment Instruction purchases.
                              </span>
                            </Fragment>
                          }
                        >
                          <ErrorOutlineIcon />
                        </HTMLToolTip>
                      )}
                    </Fragment>
                  ),
                  sx: {
                    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                      display: 'none'
                    },
                    '& input[type=number]': {
                      MozAppearance: 'textfield'
                    },
                    padding: '0.6em 0.15em'
                  }
                }}
                onKeyDown = {(event) => 
                {
                  if (event?.key === "e" || event?.key === "E" || event?.key === "-" || event?.key === "+") 
                  {
                    event.preventDefault();
                  }
                }}
              />
            </Grid>

            <Grid item xs = {3} className = "form-text-field">
              <TextField
                label = "Available Amount"
                id = "Available_Amount"
                type = "number"
                disabled
                value = {(formData?.Available_Amount || formData?.Available_Amount === 0) || ""}
                InputProps = {{ 
                  inputProps: { min: 0, max: 2147483647, step: "0.01" } 
                }}
                onKeyDown = {(event) => 
                {
                  if (event?.key === "e" || event?.key === "E" || event?.key === "-" || event?.key === "+") 
                  {
                    event.preventDefault();
                  }
                }}
                InputLabelProps = {{
                  shrink: formData?.Available_Amount ? true : false
                }}
              />
            </Grid>

            <Grid item xs = {3}>
              <VirtualizedAutocomplete
                isMultiple = {false}
                isObject = {true}
                filterOn = "Currency"
                options = {currencies}
                selectedOptions = {formData?.Currency_Id || null}
                handleSelectChange = {(filterOn, newValue) => handleSBLCChange("Currency_Id", newValue)}
              />
            </Grid>

            <Grid item xs = {3}>
              <VirtualizedAutocomplete
                isMultiple = {false}
                isObject = {true}
                filterOn = "Tenor"
                options = {tenors}
                selectedOptions = {formData?.Tenor_Id || null}
                handleSelectChange = {(filterOn, newValue) => handleSBLCChange("Tenor_Id", newValue)}
              />
            </Grid>

            <Grid item xs = {3} className = "form-text-field">
              <LocalizationProvider dateAdapter = {AdapterDayjs}>
                <DesktopDatePicker
                  inputFormat = "MM/DD/YYYY"
                  label = "Validity Date"
                  disableMaskedInput
                  className = "date-picker w-full"
                  value = {convertToDayJsObject(formData?.Validity_Date)}
                  onChange = {(newDate) => handleSBLCChange("Validity_Date", newDate, true)}
                  slotProps = {{
                    textField: {
                      InputProps: {
                        size: "small",
                        disabled: true
                      }
                    }
                  }}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs = {12}>
              <hr />

              <div className = "w-100 d-flex justify-content-between align-items-center mb-2">
                <h3 className = "mb-0">Proforma Invoices</h3>

                <IconButton
                  className = "add_button"
                  color = "primary"
                  onClick = {() => handleAddWrapper("PFI")}
                >
                  <AddIcon />
                </IconButton>
              </div>

              {formData?.PFIs?.map((pfi, pfiIndex) => 
              {
                // Create a Set of ids for both pfi and si product grades for fast lookup
                const pfiProductGradeIdSet = new Set(pfi?.Product_Grades?.map(pfiProductGrade => pfiProductGrade?.Grade_Id?.id) || []);
                const siProductGradeIdSet = new Set(pfi?.SI?.Product_Grades?.map(siProductGrade => siProductGrade?.Grade_Id?.id) || []);

                // Calculate total quantity and amount for PFI product grades
                const pfiTotalQuantity = pfi?.Product_Grades?.reduce((total, pfiProductGrade) => 
                {
                  const quantity = parseFloat(pfiProductGrade?.PFI_Quantity) || 0;
                  const sum = total + quantity;

                  return sum % 1 !== 0 ? parseFloat(sum?.toFixed(2)) : sum;
                }, 0) || 0;
                const pfiTotalAmount = pfi?.Product_Grades?.reduce((total, pfiProductGrade) => 
                {
                  const quantity = parseFloat(pfiProductGrade?.PFI_Quantity) || 0;
                  const price = parseFloat(pfiProductGrade?.Buying_Price) || 0;
                  const sum = total + (quantity * price);

                  return sum % 1 !== 0 ? parseFloat(sum?.toFixed(2)) : sum;
                }, 0) || 0;

                // Calculate total quantity and amount for SI product grades
                const siTotalQuantity = pfi?.SI?.Product_Grades?.reduce((total, siProductGrade) => 
                {
                  const quantity = parseFloat(siProductGrade?.SI_Quantity) || 0;
                  const sum = total + quantity;

                  return sum % 1 !== 0 ? parseFloat(sum?.toFixed(2)) : sum;
                }, 0) || 0;
                const siTotalAmount = pfi?.SI?.Product_Grades?.reduce((total, siProductGrade) => 
                {
                  const quantity = parseFloat(siProductGrade?.SI_Quantity) || 0;
                  const price = parseFloat(pfi?.Product_Grades?.find(pfiProductGrade => pfiProductGrade?.Grade_Id?.id === siProductGrade?.Grade_Id?.id)?.Buying_Price) || 0;
                  const sum = total + (quantity * price);

                  return sum % 1 !== 0 ? parseFloat(sum?.toFixed(2)) : sum;
                }, 0) || 0;

                // Get list of product grades selected in PFI
                const pfiFilteredProductGrades = pfi?.Product_Grades?.reduce((accumulator, currentProductGrade) => 
                {
                  const productGrade = currentProductGrade?.Grade_Id;
                  return (productGrade !== undefined && productGrade !== null && productGrade !== "") ? [...accumulator, productGrade] : accumulator;
                }, []) || [];

                // Create SI Number if SI ID exists
                const siNumber = pfi?.SI?.SI_Id ? `${pfi?.PFI_Number}/SI${pfi?.SI?.SI_Id?.toString()?.padStart(3, '0')?.slice(-3)}` : null;

                return (
                  <Grid item key = {pfiIndex}>
                    <Accordion
                      ref = {(element) => (pfiAccordionRef.current[pfiIndex] = element)}
                      expanded = {isPFIAccordionExpanded === pfiIndex}
                      onChange = {handleAccordionChange(pfiIndex, "PFI")}
                    >
                      <AccordionSummary
                        expandIcon = {<ExpandMoreIcon />}
                        aria-controls = {`PFI-content-${pfiIndex}`}
                        id = {`PFI-header-${pfiIndex}`}
                      >
                        <h4 className = "mb-0">PFI {pfiIndex + 1}</h4>

                        <IconButton
                          color = "error"
                          size = "medium"
                          onClick = {(event) => handleDeletePFI(event, pfiIndex)}
                        >
                          <DeleteIcon fontSize = "medium" />
                        </IconButton>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container spacing = {2} className = "w-100">
                          <Grid item xs = {3} className = "form-text-field">
                            <TextField
                              label = "PFI Number"
                              id = "PFI_Number"
                              value = {pfi?.PFI_Number || ""}
                              required
                              onChange = {(event) => handlePFIChange(pfiIndex, event.target.value, "PFI_Number")}
                            />
                          </Grid>

                          <Grid item xs = {3} className = "form-text-field">
                            <TextField
                              label = "Total Quantity"
                              id = "Total_Quantity"
                              type = "number"
                              value = {pfiTotalQuantity || ""}
                              disabled
                              InputLabelProps = {{
                                shrink: pfiTotalQuantity ? true : false
                              }}
                            />
                          </Grid>

                          <Grid item xs = {3} className = "form-text-field">
                            <TextField
                              label = "Total Amount"
                              id = "Total_Amount"
                              type = "number"
                              value = {pfiTotalAmount || ""}
                              disabled
                              InputLabelProps = {{
                                shrink: pfiTotalAmount ? true : false
                              }}
                            />
                          </Grid>
                          
                          <Grid item xs = {3} className = "form-text-field">
                            <LocalizationProvider dateAdapter = {AdapterDayjs}>
                              <DesktopDatePicker
                                inputFormat = "MM/DD/YYYY"
                                label = "Validity Date"
                                disableMaskedInput
                                className = "date-picker w-full"
                                value = {convertToDayJsObject(pfi?.Validity_Date)}
                                onChange = {(newDate) => handlePFIChange(pfiIndex, newDate, "Validity_Date", null, null, true)}
                                slotProps = {{
                                  textField: {
                                    InputProps: {
                                      size: "small",
                                      disabled: true
                                    }
                                  }
                                }}
                              />
                            </LocalizationProvider>
                          </Grid>
                          
                          <Grid item xs = {3}>
                            <VirtualizedAutocomplete
                              isMultiple = {false}
                              isObject = {true}
                              filterOn = "Product"
                              options = {products}
                              selectedOptions = {pfi?.Product_Id || null}
                              handleSelectChange = {(filterOn, newValue) => handlePFIChange(pfiIndex, newValue, "Product_Id")}
                            />
                          </Grid>

                          <Grid item xs = {3} className = "form-text-field">
                            <TextField
                              label = "Unit of Measurement"
                              id = "Unit_of_Measurement"
                              value = {pfi?.Product_Id ? productData?.find((product) => product.id === pfi?.Product_Id?.id)?.Unit_of_Measurement : ""}
                              disabled
                              InputLabelProps = {{
                                shrink:
                                  pfi?.Product_Id &&
                                  productData?.some((product) => product.id === pfi?.Product_Id.id && product.Unit_of_Measurement)
                              }}
                            />
                          </Grid>

                          <Grid item xs = {3}>
                            <VirtualizedAutocomplete
                              isMultiple = {false}
                              isObject = {true}
                              filterOn = "Packaging Type"
                              options = {packagingTypes}
                              selectedOptions = {pfi?.Packaging_Type_Id || null}
                              handleSelectChange = {(filterOn, newValue) => handlePFIChange(pfiIndex, newValue, "Packaging_Type_Id")}
                            />
                          </Grid>

                          <Grid item xs = {12}>
                            <div className = "w-100 d-flex justify-content-between align-items-center mb-2">
                              <h6 className = "mb-0">Product Grades</h6>

                              <IconButton
                                className = "add_button"
                                color = "primary"
                                onClick = {() => handleAddWrapper("PFI Product Grade", pfiIndex)}
                              >
                                <AddIcon />
                              </IconButton>
                            </div>

                            {pfi?.Product_Grades?.map((pfiProductGrade, pfiProductGradeIndex) => (
                              <Grid item key = {pfiProductGradeIndex}>
                                <Accordion
                                  ref = {(element) => (pfiProductGradeAccordionRef.current[pfiProductGradeIndex] = element)}
                                  expanded = {isPFIProductGradeAccordionExpanded === pfiProductGradeIndex}
                                  onChange = {handleAccordionChange(pfiProductGradeIndex, "PFI Product Grade")}
                                >
                                  <AccordionSummary
                                    expandIcon = {<ExpandMoreIcon />}
                                    aria-controls = {`product-grade-content-${pfiProductGradeIndex}`}
                                    id = {`product-grade-header-${pfiProductGradeIndex}`}
                                  >
                                    <h6 className = "mb-0">Product Grade {pfiProductGradeIndex + 1}</h6>

                                    <IconButton
                                      color = "error"
                                      size = "medium"
                                      onClick = {(event) => handleDeletePFIProductGrade(event, pfiIndex, pfiProductGradeIndex)}
                                    >
                                      <DeleteIcon fontSize = "medium" />
                                    </IconButton>
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    <Grid container spacing = {2} className = "w-100">
                                      <Grid item xs = {3}>
                                        <VirtualizedAutocomplete
                                          isMultiple = {false}
                                          isObject = {true}
                                          isRequired = {true}
                                          filterOn = "Product Grade"
                                          options = {productGrades.filter(productGrade => !pfiProductGradeIdSet.has(productGrade.id)) || []}
                                          selectedOptions = {pfiProductGrade?.Grade_Id || null}
                                          handleSelectChange = {(filterOn, newValue) => handlePFIChange(pfiIndex, newValue, "Grade_Id", "Product Grade", pfiProductGradeIndex)}
                                        />
                                      </Grid>

                                      <Grid item xs = {3} className = "form-text-field">
                                        <TextField
                                          label = "Buying Price"
                                          id = "Buying_Price"
                                          type = "number"
                                          required
                                          value = {pfiProductGrade.Buying_Price || ""}
                                          onChange = {(event) => handlePFIChange(pfiIndex, event.target.value, "Buying_Price", "Product Grade", pfiProductGradeIndex)}  
                                          InputProps = {{ 
                                            inputProps: { min: 0, max: 2147483647, step: "0.01" } 
                                          }}
                                          onKeyDown = {(event) => 
                                          {
                                            if (event?.key === "e" || event?.key === "E" || event?.key === "-" || event?.key === "+") 
                                            {
                                              event.preventDefault();
                                            }
                                          }}                                   
                                        />
                                      </Grid>

                                      <Grid item xs = {3} className = "form-text-field">
                                        <TextField
                                          label = "Quantity"
                                          id = "PFI_Quantity"
                                          type = "number"
                                          value = {pfiProductGrade.PFI_Quantity || ""}
                                          onChange = {(event) => handlePFIChange(pfiIndex, event.target.value, "PFI_Quantity", "Product Grade", pfiProductGradeIndex)}
                                          InputProps = {{ 
                                            inputProps: { min: 0, max: 2147483647, step: "0.001" } 
                                          }}
                                          onKeyDown = {(event) => 
                                          {
                                            if (event?.key === "e" || event?.key === "E" || event?.key === "-" || event?.key === "+") 
                                            {
                                              event.preventDefault();
                                            }
                                          }}  
                                        />
                                      </Grid>
                                    </Grid>
                                  </AccordionDetails>
                                </Accordion>
                              </Grid>
                            ))}
                          </Grid>

                          <Grid item xs = {12} className = "mt-4">
                            <hr />
                            <h4>Shipment Instructions</h4>

                            <Grid container spacing = {2} className = "pt-1 w-100">
                              {pfi?.SI?.SI_Id && (
                                <Grid item xs = {3} className = "form-text-field">
                                  <TextField
                                    label = "SI Number"
                                    id = "SI_Number"
                                    value = {siNumber || ""}
                                    disabled
                                    InputLabelProps = {{
                                      shrink: siNumber ? true : false
                                    }}
                                  />
                                </Grid>
                              )}

                              <Grid item xs = {3} className = "form-text-field">
                                <TextField
                                  label = "Total Quantity"
                                  id = "Total_Quantity"
                                  type = "number"
                                  value = {siTotalQuantity || ""}
                                  disabled
                                  InputLabelProps = {{
                                    shrink: siTotalQuantity ? true : false
                                  }}
                                />
                              </Grid>

                              <Grid item xs = {3} className = "form-text-field">
                                <TextField
                                  label = "Total Amount"
                                  id = "Total_Amount"
                                  type = "number"
                                  value = {siTotalAmount || ""}
                                  disabled
                                  InputLabelProps = {{
                                    shrink: siTotalAmount ? true : false
                                  }}
                                />
                              </Grid>

                              <Grid item xs = {3}></Grid>

                              <Grid item xs = {6} md = {4.5} className = "form-text-field">
                                <TextField
                                  label = "Additional Conditions"
                                  id = "Additional_Conditions"
                                  value = {pfi?.SI?.Additional_Conditions || ""}
                                  multiline
                                  rows = {4}
                                  onChange = {(event) => handleSIChange(pfiIndex, event.target.value, "Additional_Conditions")}
                                />
                              </Grid>

                              <Grid item xs = {6} md = {4.5} className = "form-text-field">
                                <TextField
                                  label = "Remarks"
                                  id = "Remarks"
                                  value = {pfi?.SI?.Remarks || ""}
                                  multiline
                                  rows = {4}
                                  onChange = {(event) => handleSIChange(pfiIndex, event.target.value, "Remarks")}
                                />
                              </Grid>

                              <Grid item xs = {12}>
                                <div className = "w-100 d-flex justify-content-between align-items-center mb-2">
                                  <h6 className = "mb-0">Product Grades</h6>

                                  <IconButton
                                    className = "add_button"
                                    color = "primary"
                                    onClick = {() => handleAddWrapper("SI Product Grade", pfiIndex)}
                                  >
                                    <AddIcon />
                                  </IconButton>
                                </div>

                                {pfi?.SI?.Product_Grades?.map((siProductGrade, siProductGradeIndex) => (
                                  <Grid item key = {siProductGradeIndex}>
                                    <Accordion
                                      ref = {(element) => (siProductGradeAccordionRef.current[siProductGradeIndex] = element)}
                                      expanded = {isSIProductGradeAccordionExpanded === siProductGradeIndex}
                                      onChange = {handleAccordionChange(siProductGradeIndex, "SI Product Grade")}
                                    >
                                      <AccordionSummary
                                        expandIcon = {<ExpandMoreIcon />}
                                        aria-controls = {`product-grade-content-${siProductGradeIndex}`}
                                        id = {`product-grade-header-${siProductGradeIndex}`}
                                      >
                                        <h6 className = "mb-0">Product Grade {siProductGradeIndex + 1}</h6>

                                        <IconButton
                                          color = "error"
                                          size = "medium"
                                          onClick = {(event) => handleDeleteSIProductGrade(event, pfiIndex, siProductGradeIndex)}
                                        >
                                          <DeleteIcon fontSize = "medium" />
                                        </IconButton>
                                      </AccordionSummary>
                                      <AccordionDetails>
                                        <Grid container spacing = {2} className = "w-100">
                                          <Grid item xs = {3}>
                                            <VirtualizedAutocomplete
                                              isMultiple = {false}
                                              isObject = {true}
                                              isRequired = {true}
                                              filterOn = "Product Grade"
                                              options = {pfiFilteredProductGrades.filter(productGrade => !siProductGradeIdSet.has(productGrade.id)) || []}
                                              selectedOptions = {siProductGrade?.Grade_Id || null}
                                              handleSelectChange = {(filterOn, newValue) => handleSIChange(pfiIndex, newValue, "Grade_Id", "Product Grade", siProductGradeIndex)}
                                            />
                                          </Grid>

                                          <Grid item xs = {3} className = "form-text-field">
                                            <TextField
                                              label = "Quantity"
                                              id = "SI_Quantity"
                                              type = "number"
                                              value = {siProductGrade.SI_Quantity || ""}
                                              onChange = {(event) => handleSIChange(pfiIndex, event.target.value, "SI_Quantity", "Product Grade", siProductGradeIndex)}
                                              InputProps = {{ 
                                                inputProps: { min: 0, max: 2147483647, step: "0.001" } 
                                              }}
                                              onKeyDown = {(event) => 
                                              {
                                                if (event?.key === "e" || event?.key === "E" || event?.key === "-" || event?.key === "+") 
                                                {
                                                  event.preventDefault();
                                                }
                                              }}  
                                            />
                                          </Grid>
                                        </Grid>
                                      </AccordionDetails>
                                    </Accordion>
                                  </Grid>
                                ))}
                              </Grid>

                              <Grid item xs = {12} className = "mt-4">
                                <hr />

                                <div className = "w-100 d-flex justify-content-between align-items-center mb-2">
                                  <h4 className = "mb-0">
                                    Commercial Invoices
                                  </h4>

                                  <IconButton
                                    className = "add_button"
                                    color = "primary"
                                    onClick = {() => handleAddWrapper("CI", pfiIndex)}
                                  >
                                    <AddIcon />
                                  </IconButton>
                                </div>
                                
                                {pfi?.SI?.CIs?.map((ci, ciIndex) => 
                                {
                                  // Create a Set of ids for CI product grades for fast lookup
                                  const ciProductGradeIdSet = new Set(ci?.Product_Grades?.map(ciProductGrade => ciProductGrade?.Grade_Id?.id) || []);

                                  // Calculate total quantity and amount for CI product grades
                                  const ciTotalQuantity = ci?.Product_Grades?.reduce((total, ciProductGrade) => 
                                  {
                                    const quantity = parseFloat(ciProductGrade?.CI_Quantity) || 0;
                                    const sum = total + quantity;
                  
                                    return sum % 1 !== 0 ? parseFloat(sum?.toFixed(2)) : sum;
                                  }, 0) || 0;
                                  const ciTotalAmount = ci?.Product_Grades?.reduce((total, ciProductGrade) => 
                                  {
                                    const quantity = parseFloat(ciProductGrade?.CI_Quantity) || 0;
                                    const price = parseFloat(pfi?.Product_Grades?.find(pfiProductGrade => pfiProductGrade?.Grade_Id?.id === ciProductGrade?.Grade_Id?.id)?.Buying_Price) || 0;
                                    const sum = total + (quantity * price);
                  
                                    return sum % 1 !== 0 ? parseFloat(sum?.toFixed(2)) : sum;
                                  }, 0) || 0;

                                  // Get list of product grades selected in SI
                                  const siFilteredProductGrades = pfi?.SI?.Product_Grades?.reduce((accumulator, currentProductGrade) => 
                                  {
                                    const productGrade = currentProductGrade?.Grade_Id;
                                    return (productGrade !== undefined && productGrade !== null && productGrade !== "") ? [...accumulator, productGrade] : accumulator;
                                  }, []) || [];
                                  
                                  return (
                                    <Grid item key = {ciIndex}>
                                      <Accordion
                                        ref = {(element) => (ciAccordionRef.current[ciIndex] = element)}
                                        expanded = {isCIAccordionExpanded === ciIndex}
                                        onChange = {handleAccordionChange(ciIndex, "CI")}
                                      >
                                        <AccordionSummary
                                          expandIcon = {<ExpandMoreIcon />}
                                          aria-controls = {`ci-content-${ciIndex}`}
                                          id = {`ci-header-${ciIndex}`}
                                        >
                                          <h4 className = "mb-0">Commercial Invoice {ciIndex + 1}</h4>

                                          <IconButton
                                            color = "error"
                                            size = "medium"
                                            onClick = {(event) => handleDeleteCI(event, pfiIndex, ciIndex)}
                                          >
                                            <DeleteIcon fontSize = "medium" />
                                          </IconButton>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                          <Grid container spacing = {2} className = "w-100">
                                            <Grid item xs = {3} className = "form-text-field">
                                              <TextField
                                                label = "CI Number"
                                                id = "CI_Number"
                                                value = {ci?.CI_Number || ""}
                                                required
                                                onChange = {(event) => handleCIChange(pfiIndex, event.target.value, "CI_Number", null, ciIndex)}
                                              />
                                            </Grid>

                                            <Grid item xs = {3} className = "form-text-field">
                                              <TextField
                                                label = "Total Quantity"
                                                id = "Total_Quantity"
                                                type = "number"
                                                value = {ciTotalQuantity || ""}
                                                disabled
                                                InputLabelProps = {{
                                                  shrink: ciTotalQuantity ? true : false
                                                }}
                                              />
                                            </Grid>

                                            <Grid item xs = {3} className = "form-text-field">
                                              <TextField
                                                label = "Total Amount"
                                                id = "Total_Amount"
                                                type = "number"
                                                value = {ciTotalAmount || ""}
                                                disabled
                                                InputLabelProps = {{
                                                  shrink: ciTotalAmount ? true : false
                                                }}
                                              />
                                            </Grid>

                                            <Grid item xs = {3} className = "form-text-field">
                                              <LocalizationProvider dateAdapter = {AdapterDayjs}>
                                                <DesktopDatePicker
                                                  inputFormat = "MM/DD/YYYY"
                                                  label = "Shipment Date"
                                                  disableMaskedInput
                                                  className = "date-picker w-full"
                                                  value = {convertToDayJsObject(ci?.Shipment_Date)}
                                                  onChange = {(newDate) => handleCIChange(pfiIndex, newDate, "Shipment_Date", null, ciIndex, null, true)}
                                                  slotProps = {{
                                                    textField: {
                                                      InputProps: {
                                                        size: "small",
                                                        disabled: true
                                                      }
                                                    }
                                                  }}
                                                />
                                              </LocalizationProvider>
                                            </Grid>
                                            
                                            <Grid item xs = {3} className = "form-text-field">
                                              <TextField
                                                label = "Master BL Number"
                                                id = "Master_BL_Number"
                                                value = {ci?.Master_BL_Number || ""}
                                                onChange = {(event) => handleCIChange(pfiIndex, event.target.value, "Master_BL_Number", null, ciIndex)}
                                              />
                                            </Grid>

                                            <Grid item xs = {12}>
                                              <FormControlLabel 
                                                label = "Mark as Paid"
                                                control = {
                                                  <div className = "d-flex flex-column">
                                                    <Checkbox 
                                                      size = "small"
                                                      checked = {ci?.Is_Paid || false}
                                                      disabled = {!ciTotalQuantity}
                                                      onChange = {(event) => handleCIChange(pfiIndex, event.target.checked, "Is_Paid", null, ciIndex)}
                                                    />

                                                    {ci?.isAmountExceeded && (
                                                      <HTMLToolTip
                                                        title = {
                                                          <Fragment>
                                                            <Typography color = "error">Exceeded SBLC Amount!</Typography>
                                                            <span className = "amount-error-message">
                                                              Please update the quantity of the commercial invoice product grade to ensure that the available amount does not exceed the SBLC amount.
                                                            </span>
                                                          </Fragment>
                                                        }
                                                      />
                                                    )}
                                                  </div>
                                                } 
                                              />
                                            </Grid>

                                            <Grid item xs = {12}>
                                              <div className = "w-100 d-flex justify-content-between align-items-center mb-2">
                                                <h6 className = "mb-0">Product Grades</h6>

                                                <IconButton
                                                  className = "add_button"
                                                  color = "primary"
                                                  disabled = {ci?.Is_Paid}
                                                  onClick = {() => handleAddWrapper("CI Product Grade", pfiIndex, ciIndex)}
                                                >
                                                  <AddIcon />
                                                </IconButton>
                                              </div>

                                              {ci?.Product_Grades?.map((ciProductGrade, ciProductGradeIndex) => (
                                                <Grid item key = {ciProductGradeIndex}>
                                                  <Accordion
                                                    ref = {(element) => (ciProductGradeAccordianRef.current[ciProductGradeIndex] = element)}
                                                    expanded = {isCIProductGradeAccordionExpanded === ciProductGradeIndex}
                                                    onChange = {handleAccordionChange(ciProductGradeIndex, "CI Product Grade")}
                                                  >
                                                    <AccordionSummary
                                                      expandIcon = {<ExpandMoreIcon />}
                                                      aria-controls = {`product-grade-content-${ciProductGradeIndex}`}
                                                      id = {`product-grade-header-${ciProductGradeIndex}`}
                                                    >
                                                      <h6 className = "mb-0">Product Grade {ciProductGradeIndex + 1}</h6>

                                                      <IconButton
                                                        color = "error"
                                                        size = "medium"
                                                        disabled = {ci?.Is_Paid}
                                                        onClick = {(event) => handleDeleteCIProductGrade(event, pfiIndex, ciIndex, ciProductGradeIndex)}
                                                      >
                                                        <DeleteIcon fontSize = "medium" />
                                                      </IconButton>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                      <Grid container spacing = {2} className = "w-100">
                                                        <Grid item xs = {3}>
                                                          <VirtualizedAutocomplete
                                                            isMultiple = {false}
                                                            isObject = {true}
                                                            isRequired = {true}
                                                            isDisabled = {ci?.Is_Paid}
                                                            filterOn = "Product Grade"
                                                            options = {siFilteredProductGrades.filter(productGrade => !ciProductGradeIdSet.has(productGrade.id)) || []}
                                                            selectedOptions = {ciProductGrade?.Grade_Id || null}
                                                            handleSelectChange = {(filterOn, newValue) => handleCIChange(pfiIndex, newValue, "Grade_Id", "Product Grade", ciIndex, ciProductGradeIndex)}
                                                          />
                                                        </Grid>

                                                        <Grid item xs = {3} className = "form-text-field">
                                                          <TextField
                                                            label = "Quantity"
                                                            id = "CI_Quantity"
                                                            type = "number"
                                                            disabled = {ci?.Is_Paid}
                                                            value = {ciProductGrade.CI_Quantity || ""}
                                                            onChange = {(event) => handleCIChange(pfiIndex, event.target.value, "CI_Quantity", "Product Grade", ciIndex, ciProductGradeIndex)}
                                                            InputProps = {{ 
                                                              inputProps: { min: 0, max: 2147483647, step: "0.001" } 
                                                            }}
                                                            onKeyDown = {(event) => 
                                                            {
                                                              if (event?.key === "e" || event?.key === "E" || event?.key === "-" || event?.key === "+") 
                                                              {
                                                                event.preventDefault();
                                                              }
                                                            }}
                                                          />
                                                        </Grid>
                                                      </Grid>
                                                    </AccordionDetails>
                                                  </Accordion>
                                                </Grid>
                                              ))}
                                            </Grid>
                                          </Grid>
                                        </AccordionDetails>
                                      </Accordion>
                                    </Grid>
                                  );
                                })}
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>

          <button ref = {submitButtonRef} type = "submit" className = "hidden"></button>
        </div>
      </form>

      <div className = "d-flex gap-2 justify-content-end">
        <button
          type = "button"
          variant = "contained"
          color = "primary"
          disabled = {isLoading}
          className = "btn btn_secondary custom_btn"
          onClick = {handleSubmitWrapper}
        >
          {isExistingRecord ? "Update" : "Save"}
        </button>
      </div>

      <Dialog open = {showWarningDialog} onClose = {handleCloseDialog}>
        <DialogContent>
          <DialogContentText className = "text-center">
            Please fill in at least one field in the current SBLC details
            before adding a new one.
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <button
            type = "button"
            variant = "contained"
            className = "btn btn-primary custom_btn"
            onClick = {handleCloseDialog}
          >
            Ok
          </button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default SBLCForm;