import { forwardRef, useState } from 'react';
import { FiDownload } from 'react-icons/fi';
import { WithModal } from './WithModal';
import axios from 'axios';
import '../styles/general.scss';

const ReportDownloader = forwardRef(({ isReport = false, apiMethod = "", api = "", apiData = {}, buttonType = "button", buttonText = "", fileName = "", modalTitle = "", modalContent = null, isDisabled = false, isModalRequired = false, isButtonDisabled = false, isValidateForm = false, handleFormValidation = () => {}, handleResetSelectedCOO = () => {}, setIsLoading = () => {}, setShowAlert = () => {}, setAlertMessage = () => {}, setAlertType = () => {} }, ref) => 
{
  /* MODAL VARIABLES */
  const [showModal, setShowModal] = useState(false);

  const handleDownloadWrapper = (event) => 
  {
    const allowDownload = isReport ? apiData?.businessUnit : true;

    if (allowDownload)
    {
      event.preventDefault();

      if (isValidateForm) 
      {
        if (!handleFormValidation()) 
        {
          return;
        }
      }

      if (isModalRequired) 
      {
        setShowModal(true);
      } 
      else 
      {
        handleDownload();
      }
    }
  }
  
  const handleDownload = async () => 
  {
    setIsLoading(true);
    setShowAlert(false);
    setAlertMessage("");
    setAlertType("");

    await axios({
      method: apiMethod,
      url: `/${api}`,
      responseType: "blob",
      timeout: 240000,
      data: apiData
    })
    .then(async (response) => 
    {
      setIsLoading(false);
      const { status, data, headers } = response;
    
      if (status === 200) 
      {
        const blob = new Blob([data], { type: data.type });
        const blobURL = window.URL.createObjectURL(blob);
    
        // Extract the filename from the Content-Disposition header
        const contentDisposition = headers['content-disposition'];
        let headerFileName = 'download.pdf';

        if (contentDisposition) 
        {
          const match = contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
        
          if (match != null && match[1]) 
          {
            headerFileName = match[1].replace(/['"]/g, '').trim();
          }
        }
    
        const anchor = document.createElement("a");
        anchor.download = headerFileName || fileName;
        anchor.href = blobURL;
        anchor.dataset.downloadurl = [data.type, anchor.download, anchor.href].join(":");
        anchor.click();
    
        setTimeout(() => 
        {
          URL.revokeObjectURL(blobURL);
        }, 100);

        handleCloseModal();
      } 
      else if (status === 202) 
      {
        const reader = new FileReader();
        const messageData = await new Promise((resolve) => 
        {
          reader.onload = () => resolve(reader.result);
          reader.readAsText(data);
        });
        const message = JSON.parse(messageData)?.data;
        const isReport = JSON.parse(messageData)?.is_report;

        setAlertMessage(message);
        setAlertType(isReport ? "info" : "error");
        setShowAlert(true);
      } 
      else 
      {
        setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
        setAlertType("error");
        setShowAlert(true);
      }
    })
    .catch((error) => 
    {
      console.log("Download Report Api: ", error);
      setIsLoading(false);
      setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
      setAlertType("error");
      setShowAlert(true);
    });
  }

  const handleCloseModal = () => 
  {
    setShowModal(false);
    handleResetSelectedCOO();
  }

  return (
    <>
      <button  
        ref = {ref}
        type = {buttonType} 
        className = {`btn csv_button ${isDisabled ? 'disabled' : ''} ${ref ? 'hidden' : ''}`} 
        onClick = {handleDownloadWrapper}
      >
        <FiDownload />
        {buttonText}
      </button>

      <WithModal 
        title = {modalTitle} 
        showModal = {showModal} 
        isButtonDisabled = {isButtonDisabled}
        handleCSVDownload = {handleDownload} 
        handleCloseModal = {handleCloseModal}  
      >
        {modalContent}
      </WithModal>
    </>
  );
});

export default ReportDownloader;