import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setUser } from '../actions/user';
import axios from 'axios'; 

const useLogout = () => 
{
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = async () => 
  {  
    dispatch(setUser(null, null, null, null, false));
    localStorage.setItem("isLoggedIn", false);
    localStorage.setItem("isLoggedOut", true);
    navigate("/login");

    try 
    {
      await axios.post('/logout', {}, 
      {
        withCredentials: true // Ensure cookies are sent with the request
      });
    } 
    catch (error) 
    {
      console.error('Logout Failed:', error);
      
      // Optionally, you might want to handle errors here,
      // e.g., show a message to the user or retry the API call.
    }
  }

  return handleLogout;
}

export default useLogout;