const initial_state = {};

export default function GetUser(state = initial_state, action)
{
    const { type, payload } = action;

    switch (type)
    {
        case "SET_USER":
            return payload;
        default:
            return state;
    }
}