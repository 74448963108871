import { Fragment, useState, useEffect, useRef } from 'react';
import TextField from '@mui/material/TextField';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import VirtualizedAutocomplete from '../../components/Autocomplete';
import CustomTextMask from '../../components/CustomTextMask';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';

const DealSBLC = ({ selectedTab = null, selectedPFI = null, sblcFormData = {}, isPFIAccordionExpanded = null, isProductGradeAccordionExpanded = null, logOut = () => {}, setShowAlert = () => {}, setAlertMessage = () => {}, setAlertType = () => {}, setSBLCFormData = () => {}, setIsPFIAccordionExpanded = () => {}, setIsProductGradeAccordionExpanded = () => {} }) => 
{
  /* LOADING VARIABLES */
  const [isSBLCListLoading, setIsSBLCListLoading] = useState(false);
  const [isPFIListLoading, setIsPFIListLoading] = useState(false);
  const [isSelectedPFIDataLoading, setIsSelectedPFIDataLoading] = useState(false);

  /* LIST VARIABLES */
  const [sblcs, setSBLCs] = useState([]);

  /* ACCORDION VARIABLES */
  const pfiAccordionRef = useRef({});
  const productGradeAccordionRef = useRef({});

  /* DIALOG VARIABLES */
  const [showWarningDialog, setShowWarningDialog] = useState(false);

  useEffect(() =>
  {
    const fetchSBLCList = async () =>
    {
      setIsSBLCListLoading(true);

      await axios({
        method: "post",
        url: "/GetSBLCList",
        data: {
          selected_tab: selectedTab,
          Deal_PFI_Id: selectedPFI
        }
      })
      .then((response) => 
      {
        setIsSBLCListLoading(false);
        const { status, data } = response;

        if (status === 200) 
        {
          const sblcs = data?.sblc || [];
          setSBLCs(sblcs);
        }
      })
      .catch((error) => 
      {
        console.log("Get SBLC Api: ", error);
        setIsSBLCListLoading(false);

        if (error?.response?.status === 403) 
        {
          logOut();
        }
        else
        {
          setAlertMessage(
            error?.response?.status === 429
              ? "Request limit exceeded. Please try again later."
              : "An error occurred while processing your request. Please try again later or contact the site administrator."
          );
          setAlertType("error");
          setShowAlert(true);
        }
      });
    }

    fetchSBLCList();

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [selectedTab, selectedPFI]);

  useEffect(() =>
  {
    const setSBLCData = async () =>
    {
      if (selectedTab === "Shipment Details" && !sblcFormData.SBLC_Id)
      {
        const sblc = { ...sblcFormData };

        sblc.SBLC_Id = sblcs?.[0] || null;
        sblc.PFI_Id = [];
        
        const pfis = sblc?.SBLC_Id?.id ? await fetchPFIList(sblc?.SBLC_Id?.id) : [];
        sblc.PFIs = pfis;

        setSBLCFormData(sblc);
      }
    }

    setSBLCData();

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [sblcs, selectedTab, sblcFormData]);

  const fetchPFIList = async (sblcId) => 
  {
    try
    {
      setIsPFIListLoading(true);

      const response = await axios({
        method: "post",
        url: "/GetSBLCPFIList",
        data: { 
          sblc_id: sblcId,
          selected_tab: selectedTab
        }
      });

      setIsPFIListLoading(false);
      const { status, data } = response;

      if (status === 200) 
      {
        const pfis = data?.pfis || [];
        return pfis;
      }
      else if (status === 202)
      {
        setAlertMessage(data?.error || "");
        setAlertType("info");
        setShowAlert(true);
      }
    }
    catch (error)
    { 
      console.log("Get Selected SBLC PFIs Api: ", error);
      setIsPFIListLoading(false);

      if (error?.response?.status === 403) 
      {
        logOut();
      }
      else
      {
        setAlertMessage(
          error?.response?.status === 429
            ? "Request limit exceeded. Please try again later."
            : "An error occurred while processing your request. Please try again later or contact the site administrator."
        );
        setAlertType("error");
        setShowAlert(true);
      }
    }
  }

  const fetchSelectedPFIData = async (sblcId, pfiId) =>
  {
    try
    {
      setIsSelectedPFIDataLoading(true);

      const response = await axios({
        method: "post",
        url: "/GetSelectedSBLCPFIData",
        data: { 
          Deal_PFI_Id: selectedPFI,
          sblc_id: sblcId,
          pfi_id: pfiId,
          selected_tab: selectedTab
        }
      });

      setIsSelectedPFIDataLoading(false);
      const { status, data } = response;

      if (status === 200) 
      {
        const pfiData = data?.pfi_data || {};
        return pfiData;
      }
      else if (status === 202)
      {
        setAlertMessage(data?.error || "");
        setAlertType("info");
        setShowAlert(true);
      }
    }
    catch (error) 
    {
      console.log("Get Selected PFI Data Api: ", error);
      setIsSelectedPFIDataLoading(false);

      if (error?.response?.status === 403) 
      {
        logOut();
      }
      else
      {
        setAlertMessage(
          error?.response?.status === 429
            ? "Request limit exceeded. Please try again later."
            : "An error occurred while processing your request. Please try again later or contact the site administrator."
        );
        setAlertType("error");
        setShowAlert(true);
      }
    }
  }
  
  const handleAccordionChange = (index, flag) => (_, newExpanded) => 
  {
    if (flag === "PFI") 
    {
      setIsPFIAccordionExpanded(newExpanded ? index : null);
    }
    else if (flag === "Product Grade") 
    {
      setIsProductGradeAccordionExpanded(newExpanded ? index : null);
    } 
  }

  const handleSelectSBLC = async (newValue) =>
  {
    const sblc = { ...sblcFormData };

    sblc.SBLC_Id = newValue;
    sblc.PFI_Id = [];
    sblc.isUpdated = true;

    if (newValue)
    {
      const pfis = await fetchPFIList(sblc.SBLC_Id.id) || [];
      sblc.PFIs = pfis;
    }
    else
    {
      sblc.PFIs = [];
    }

    setSBLCFormData(sblc);
    setIsPFIAccordionExpanded(null);
    setIsProductGradeAccordionExpanded(null);
  }

  const handleAddWrapper = (type, pfiIndex = null) => 
  {
    if (type === "PFI") 
    {
      handleAddPFI();
    } 
    else if (type === "Product Grade") 
    {
      handleAddProductGrade(pfiIndex);
    }
  }

  const handleSelectPFI = async (pfiIndex, newValue) => 
  {
    const pfis = [...(sblcFormData?.PFI_Id || [])];

    if (!pfis[pfiIndex]?.isNew) 
    {
      pfis[pfiIndex].isUpdated = true;
    }

    pfis[pfiIndex].PFI_Number = newValue;
    pfis[pfiIndex].Product_Grade_Id = [];

    if (newValue)
    {
      const pfiData = await fetchSelectedPFIData(sblcFormData?.SBLC_Id?.id, newValue.id) || {};
      const { Product_Id: productId = null, Product_Name: productName = null, Shipment_Instruction_Id: siId = null, Product_Grades: productGrades = [] } = pfiData;
      const siNumber = `${newValue.value}/SI${siId?.toString()?.padStart(3, '0')?.slice(-3)}`;

      pfis[pfiIndex].Product_Id = { id: productId, value: productName };
      pfis[pfiIndex].SI_Id = { id: siId, value: siNumber };
      pfis[pfiIndex].Product_Grades = productGrades;
    }
    else
    {
      pfis[pfiIndex].Product_Id = null;
      pfis[pfiIndex].SI_Id = null;
      pfis[pfiIndex].Product_Grades = [];
    }

    setSBLCFormData((previousSBLCFormData) => ({
      ...previousSBLCFormData,
      PFI_Id: pfis
    }));
  }
  
  const handleAddPFI = () => 
  {
    const pfis = [...(sblcFormData?.PFI_Id || [])];
    const isAnyPFIFieldFilled = pfis?.length > 0 
      ? pfis.every((pfi) => 
        Object.entries(pfi)
          .filter(([key]) => key !== "isNew" && key !== "isUpdated")
          .some(([_, value]) => 
          {
            if (typeof value === "object" && value !== null) 
            {
              return Array.isArray(value)
                ? value.length > 0
                : Object.values(value).some(subValue => 
                    subValue !== undefined && subValue !== "" && subValue !== null &&
                    (typeof subValue !== "object" || (Array.isArray(subValue) ? subValue.length > 0 : true))
                  );
            }

            return value !== undefined && value !== "" && value !== null &&
              (typeof value !== "object" || (Array.isArray(value) ? value.length > 0 : true));
          })
      )
    : true;

    if (isAnyPFIFieldFilled)
    {
      const newPFIData = {
        PFI_Number: null,
        SI_Id: null,
        Product_Id: null,
        Product_Grades: [],
        Product_Grade_Id: [],
        isNew: true,
        isUpdated: false
      };

      setIsPFIAccordionExpanded(pfis?.length);
      setSBLCFormData((previousSBLCFormData) => ({
        ...previousSBLCFormData,
        PFI_Id: [...pfis, newPFIData]
      }));
    }
    else
    {
      setShowWarningDialog(true);
    }
  }

  const handleDeletePFI = (event, pfiIndex) =>
  {
    event.stopPropagation();
    const pfis = [...(sblcFormData?.PFI_Id || [])];

    setIsPFIAccordionExpanded(pfis?.length > 0);
    setSBLCFormData((previousSBLCFormData) => ({
      ...previousSBLCFormData,
      PFI_Id: pfis.filter((_, index) => index !== pfiIndex)
    }));
  }

  const handleSelectProductGrade = async (pfiIndex, pfiProductGradeIndex, newValue) => 
  {
    const pfis = [...(sblcFormData?.PFI_Id || [])];
    const pfiProductGrades = [...(pfis[pfiIndex]?.Product_Grade_Id || [])];

    if (!pfiProductGrades[pfiProductGradeIndex]?.isNew) 
    {
      pfiProductGrades[pfiProductGradeIndex].isUpdated = true;
    }

    pfiProductGrades[pfiProductGradeIndex].Grade_Id = newValue;

    if (selectedTab === "Deal Header")
    {
      pfiProductGrades[pfiProductGradeIndex].Quantity = newValue?.Quantity;
      pfiProductGrades[pfiProductGradeIndex].Buying_Price = newValue?.Buying_Price;
    }

    pfis[pfiIndex].Product_Grade_Id = pfiProductGrades;

    setSBLCFormData((previousSBLCFormData) => ({
      ...previousSBLCFormData,
      PFI_Id: pfis
    }));
  }

  const handleProductGradeChange = (pfiIndex, pfiProductGradeIndex, field, value) =>
  {
    const pfis = [...(sblcFormData?.PFI_Id || [])];
    const pfiProductGrades = [...(pfis[pfiIndex]?.Product_Grade_Id || [])];
    let formattedValue = value;

    if (field === "HS_Code") 
    {
      const hsCode = pfiProductGrades[pfiProductGradeIndex]?.HS_Code || null;
      
      if (hsCode === formattedValue) 
      {
        return;
      }
    }    
  
    if (field === "Quantity" || field === "Buying_Price" || field === "Selling_Price") 
    {
      // Remove any non-numeric characters except the decimal point
      formattedValue = value.replace(/[^0-9.]/g, "");

      // Ensure the value has at most 3 decimal places
      if (formattedValue.includes(".")) 
      {
        const [integerPart, decimalPart] = formattedValue.split(".");

        if (field === "Quantity") 
        {
          formattedValue = `${integerPart}.${decimalPart.substring(0, 3)}`;
        } 
        else 
        {
          formattedValue = `${integerPart}.${decimalPart.substring(0, 2)}`;
        }
      }
    }
    
    if (!pfiProductGrades[pfiProductGradeIndex]?.isNew) 
    {
      pfiProductGrades[pfiProductGradeIndex].isUpdated = true;
    }

    pfiProductGrades[pfiProductGradeIndex][field] = formattedValue;
    pfis[pfiIndex].Product_Grade_Id = pfiProductGrades;

    setSBLCFormData((previousSBLCFormData) => ({
      ...previousSBLCFormData,
      PFI_Id: pfis
    }));
  }

  const handleAddProductGrade = (pfiIndex) => 
  {
    const pfis = [...(sblcFormData?.PFI_Id || [])];
    const pfiProductGrades = [...(pfis[pfiIndex]?.Product_Grade_Id || [])];
    const isAnyProductGradeFieldFilled = pfiProductGrades?.length > 0 
      ? pfiProductGrades.every((pfiProductGrade) => 
        Object.entries(pfiProductGrade)
          .filter(([key]) => key !== "isNew" && key !== "isUpdated")
          .some(([_, value]) => 
          {
            if (typeof value === "object" && value !== null) 
            {
              return Array.isArray(value)
                ? value.length > 0
                : Object.values(value).some(subValue => 
                    subValue !== undefined && subValue !== "" && subValue !== null &&
                    (typeof subValue !== "object" || (Array.isArray(subValue) ? subValue.length > 0 : true))
                  );
            }

            return value !== undefined && value !== "" && value !== null &&
              (typeof value !== "object" || (Array.isArray(value) ? value.length > 0 : true));
          })
      )
    : true;

    if (isAnyProductGradeFieldFilled)
    {
      const newProductGradeData = {
        Grade_Id: null,
        Quantity: null,
        Selling_Price: null,
        Buying_Price: null,
        HS_Code: null,
        Specification: "",
        Description: "",
        isNew: true,
        isUpdated: false
      };
      
      pfis[pfiIndex].Product_Grade_Id = [...pfiProductGrades, newProductGradeData];
      
      setIsProductGradeAccordionExpanded(pfiProductGrades?.length);
      setSBLCFormData((previousSBLCFormData) => ({
        ...previousSBLCFormData,
        PFI_Id: pfis
      }));
    }
    else
    {
      setShowWarningDialog(true);
    }
  }

  const handleDeleteProductGrade = (event, pfiIndex, pfiProductGradeIndex) =>
  {
    event.stopPropagation();

    const pfis = [...(sblcFormData?.PFI_Id || [])];
    const pfiProductGrades = [...(pfis[pfiIndex]?.Product_Grade_Id || [])];

    pfiProductGrades?.splice(pfiProductGradeIndex, 1);
    pfis[pfiIndex].Product_Grade_Id = pfiProductGrades;

    setIsProductGradeAccordionExpanded(pfiProductGrades?.length - 1);
    setSBLCFormData((previousSBLCFormData) => ({
      ...previousSBLCFormData,
      PFI_Id: pfis
    }));
  }

  const handleCloseDialog = () => 
  {
    setShowWarningDialog(false);
  }

  return (
    <Grid item xs = {12} className = "mb-1">
      <h3>SBLC</h3>

      <Grid item xs = {3} className = "form-text-field mb-2">
        {selectedTab === "Shipment Details" ? (
          <TextField
            label = "SBLC"
            id = "SBLC_Id"
            value = {sblcFormData?.SBLC_Id?.value || ""}
            disabled
            InputLabelProps = {{
              shrink: sblcFormData?.SBLC_Id ? true : false
            }}
            InputProps = {{
              endAdornment: (
                <Fragment>
                  {isSBLCListLoading ? 
                    <CircularProgress 
                      color = "inherit" 
                      size = {20} 
                      sx = {{  
                        marginRight: '1em'
                      }}
                    /> 
                  : null}
                </Fragment>
              )
          }}
          />
        ) : (
          <VirtualizedAutocomplete
            isLoading = {isPFIListLoading}
            isMultiple = {false}
            isObject = {true}
            isRequired = {true}
            isDisabled = {isPFIListLoading}
            filterOn = "SBLC"
            options = {sblcs}
            selectedOptions = {sblcFormData?.SBLC_Id || null}
            handleSelectChange = {(filterOn, newValue) => handleSelectSBLC(newValue)}
          />
        )}
      </Grid>
      
      {sblcFormData?.PFIs?.length > 0 ? (
        <Grid item xs = {12} className = "mt-2">
          <div className = "w-100 d-flex justify-content-between align-items-center mb-2">
            <h3 className = "mb-0">Proforma Invoices</h3>
            
            <IconButton
              className = "add_button"
              color = "primary"
              onClick = {() => handleAddWrapper("PFI")}
            >
              <AddIcon />
            </IconButton>
          </div>

          {sblcFormData?.PFI_Id?.map((pfi, pfiIndex) => {
            // Create a Set of ids for PFIs for fast lookup
            const pfiIdSet = new Set(sblcFormData?.PFI_Id?.map(sblcPFI => sblcPFI?.PFI_Number?.id) || []);
      
            return (
              <Grid item key = {pfiIndex}>
                <Accordion
                  expanded = {isPFIAccordionExpanded === pfiIndex}
                  ref = {(element) => (pfiAccordionRef.current[pfiIndex] = element)}
                  onChange = {handleAccordionChange(pfiIndex, "PFI")}
                >
                  <AccordionSummary
                    expandIcon = {<ExpandMoreIcon />}
                    aria-controls = {`pfi-content-${pfiIndex}`}
                    id = {`pfi-header-${pfiIndex}`}
                  >
                    <Typography>PFI {pfiIndex + 1}</Typography>

                    <IconButton
                      color = "error"
                      size = "medium"
                      onClick = {(event) => handleDeletePFI(event, pfiIndex)}
                    >
                      <DeleteIcon fontSize = "medium" />
                    </IconButton>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing = {2} className = "w-100">
                      <Grid item xs = {3}>
                        <VirtualizedAutocomplete
                          isLoading = {isSelectedPFIDataLoading}
                          isMultiple = {false}
                          isObject = {true}
                          isRequired = {true}
                          isDisabled = {isSelectedPFIDataLoading}
                          filterOn = "PFI"
                          options = {sblcFormData?.PFIs?.filter(sblcPFI => !pfiIdSet.has(sblcPFI.id)) || []}
                          selectedOptions = {pfi?.PFI_Number || null}
                          handleSelectChange = {(filterOn, newValue) => handleSelectPFI(pfiIndex, newValue)}
                        />
                      </Grid>

                      <Grid item xs = {3} className = "form-text-field">
                        <TextField
                          label = "Product"
                          id = "Product_Id"
                          value = {pfi?.Product_Id?.value || ""}
                          disabled
                          InputLabelProps = {{
                            shrink: pfi?.Product_Id ? true : false
                          }}
                        />
                      </Grid>

                      <Grid item xs = {3} className = "form-text-field">
                        <TextField
                          label = "Shipment Instructions"
                          id = "Shipment_Instructions"
                          value = {pfi?.SI_Id?.value || ""}
                          disabled
                          InputLabelProps = {{
                            shrink: pfi?.Product_Id ? true : false
                          }}
                        />
                      </Grid>

                      {pfi?.Product_Grades?.length > 0 ? (
                        <Grid item xs = {12}>
                          <hr />

                          <div className = "d-flex justify-content-between align-items-center mb-2">
                            <h4 className = "mb-0">Product Grades</h4>

                            <IconButton
                              className = "add_button"
                              color = "primary"
                              onClick = {() => handleAddWrapper("Product Grade", pfiIndex)}
                            >
                              <AddIcon />
                            </IconButton>
                          </div>

                          {pfi?.Product_Grade_Id?.map((pfiProductGrade, pfiProductGradeIndex) => {
                            // Create a Set of ids for PFI product grades for fast lookup
                            const pfiProductGradeIdSet = new Set(pfi?.Product_Grade_Id?.map(pfiProductGrade => pfiProductGrade?.Grade_Id?.id) || []);
                  
                            return (
                              <Grid item key = {pfiProductGradeIndex}>
                                <Accordion
                                  expanded = {isProductGradeAccordionExpanded === pfiProductGradeIndex}
                                  ref = {(element) => (productGradeAccordionRef.current[pfiProductGradeIndex] = element)}
                                  onChange = {handleAccordionChange(pfiProductGradeIndex, "Product Grade")}
                                >
                                  <AccordionSummary
                                    expandIcon = {<ExpandMoreIcon />}
                                    aria-controls = {`product-grade-content-${pfiProductGradeIndex}`}
                                    id = {`product-grade-header-${pfiProductGradeIndex}`}
                                  >
                                    <Typography>Product Grade {pfiProductGradeIndex + 1}</Typography>

                                    <IconButton
                                      color = "error"
                                      size = "medium"
                                      onClick = {(event) => handleDeleteProductGrade(event, pfiIndex, pfiProductGradeIndex)}
                                    >
                                      <DeleteIcon fontSize = "medium" />
                                    </IconButton>
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    <Grid container spacing = {2} className = "w-100">
                                      <Grid item xs = {3}>
                                        <VirtualizedAutocomplete
                                          isMultiple = {false}
                                          isObject = {true}
                                          isRequired = {true}
                                          filterOn = "Product Grade"
                                          options = {pfi?.Product_Grades?.filter(productGrade => !pfiProductGradeIdSet.has(productGrade.id)) || []}
                                          selectedOptions = {pfiProductGrade?.Grade_Id || null}
                                          handleSelectChange = {(filterOn, newValue) => handleSelectProductGrade(pfiIndex, pfiProductGradeIndex, newValue)}
                                        />
                                      </Grid>

                                      <Grid item xs = {3} className = "form-text-field">
                                        <TextField
                                          label = {`${selectedTab === "Shipment Details" ? "Shipped " : ""}Quantity`}
                                          id = "Quantity"
                                          type = "number"
                                          value = {pfiProductGrade?.Quantity || ""}
                                          onChange = {(event) => handleProductGradeChange(pfiIndex, pfiProductGradeIndex, "Quantity", event.target.value)}
                                          InputProps = {{ 
                                            inputProps: { min: 0, max: 2147483647, step: "0.001" } 
                                          }}
                                          onKeyDown = {(event) => 
                                          {
                                            if (event?.key === "e" || event?.key === "E" || event?.key === "-" || event?.key === "+") 
                                            {
                                              event.preventDefault();
                                            }
                                          }}
                                        />
                                      </Grid>

                                      {selectedTab === "Deal Header" && (
                                        <>
                                          <Grid item xs = {3} className = "form-text-field">
                                            <TextField
                                              label = "Selling Price"
                                              id = "Selling_Price"
                                              type = "number"
                                              value = {pfiProductGrade?.Selling_Price || ""}
                                              onChange = {(event) => handleProductGradeChange(pfiIndex, pfiProductGradeIndex, "Selling_Price", event.target.value)}
                                              InputProps = {{ 
                                                inputProps: { min: 0, max: 2147483647, step: "0.01" } 
                                              }}
                                              onKeyDown = {(event) => 
                                              {
                                                if (event?.key === "e" || event?.key === "E" || event?.key === "-" || event?.key === "+") 
                                                {
                                                  event.preventDefault();
                                                }
                                              }}
                                            />
                                          </Grid>

                                          <Grid item xs = {3} className = "form-text-field">
                                            <TextField
                                              label = "Buying Price"
                                              id = "Buying_Price"
                                              type = "number"
                                              disabled
                                              value = {pfiProductGrade?.Buying_Price || ""}
                                              InputLabelProps = {{
                                                shrink: pfiProductGrade?.Buying_Price ? true : false
                                              }}
                                            />
                                          </Grid>

                                          <Grid item xs = {3} className = "form-text-field">
                                            <TextField
                                              label = "HS Code"
                                              id = "HS_Code"
                                              name = "HS_Code"
                                              value = {pfiProductGrade?.HS_Code ? pfiProductGrade?.HS_Code?.toString() : ""}
                                              onChange = {(event) => handleProductGradeChange(pfiIndex, pfiProductGradeIndex, "HS_Code", event.target.value)}
                                              InputProps = {{ inputComponent: CustomTextMask }}
                                            />
                                          </Grid>

                                          <Grid item xs = {4} className = "form-text-field">
                                            <TextField
                                              label = "Specification"
                                              id = "Specification"
                                              multiline
                                              rows = {3}
                                              value = {pfiProductGrade?.Specification || ""}
                                              onChange = {(event) => handleProductGradeChange(pfiIndex, pfiProductGradeIndex, "Specification", event.target.value)}
                                            />
                                          </Grid>

                                          <Grid item xs = {4} className = "form-text-field">
                                            <TextField
                                              label = "Description"
                                              id = "Description"
                                              multiline
                                              rows = {3}
                                              value = {pfiProductGrade?.Description || ""}
                                              onChange = {(event) => handleProductGradeChange(pfiIndex, pfiProductGradeIndex, "Description", event.target.value)}
                                            />
                                          </Grid>
                                        </>
                                      )}
                                    </Grid>
                                  </AccordionDetails>
                                </Accordion>
                              </Grid>
                            );
                          })}
                        </Grid>
                      ) : (pfi?.PFI_Number && !isSelectedPFIDataLoading) && (
                        <span className = "px-3 mt-3">No product grades found against selected PFI.</span>
                      )}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            );
          })}
        </Grid>
      ) : (sblcFormData?.SBLC_Id && !isPFIListLoading) && (
        <span>No PFIs found against selected SBLC.</span>
      )}

      <Dialog open = {showWarningDialog} onClose = {handleCloseDialog}>
        <DialogContent>
          <DialogContentText className = "text-center">
            Please fill in at least one field in the current SBLC details
            before adding a new one.
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <button
            type = "button"
            variant = "contained"
            className = "btn btn-primary custom_btn"
            onClick = {handleCloseDialog}
          >
            Ok
          </button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}

export default DealSBLC;