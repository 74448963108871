import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import VirtualizedAutocomplete from '../../components/Autocomplete';
import axios from 'axios';
import '../../styles/general.scss';

const DealNo = ({ isLoading, selectedTab, selectedDeal, setActiveTab, setSelectedDeal, setIsLoading, setShowAlert, setAlertMessage, setAlertType, logOut }) => 
{
  const location = useLocation();
  const { selectedBusinessUnit } = location.state || {};

  /* CURRENT USER VARIABLES */
  const currentUser = useSelector(state => state.user);

  /* LIST VARIABLES */
  const [deals, setDeals] = useState([]);

  useEffect(() => 
  {
    const fetchData = async () => 
    {
      setIsLoading(true);
      setShowAlert(false);
      setAlertMessage("");
      setAlertType("");

      await axios({
        method: "post",
        url: "/GetDealList",
        data: {
          business_unit_id: selectedBusinessUnit?.id
        }
      })
      .then((response) => 
      {
        setIsLoading(false);
        const { status, data } = response;

        if (status === 200) 
        {
          const dealData = data?.data || [];
          setDeals(dealData);
        } 
        else 
        {
          setShowAlert(true);
          setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
          setAlertType("error");
        }
      })
      .catch((error) => 
      {
        console.error("Get Deal Numbers Api: ", error);
        setIsLoading(false);
        
        if (error?.response?.status === 403) 
        {
          logOut();
        }
        else
        {
          setAlertMessage(
            error?.response?.status === 429
              ? "Request limit exceeded. Please try again later."
              : "An error occurred while processing your request. Please try again later or contact the site administrator."
          );
          setAlertType("error");
          setShowAlert(true);
        }
      });
    }

    if (selectedBusinessUnit) 
    {
      fetchData();
    }

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [selectedBusinessUnit]);

  const handleGenerateDealNo = async () => 
  {
    setIsLoading(true);
    setShowAlert(false);
    setAlertMessage("");
    setAlertType("");

    await axios({
      method: "POST",
      url: "/GenerateDealNo",
      data: {
        business_unit_id: selectedBusinessUnit?.id,
        user_id:currentUser?.user_id
      }
    })
    .then((response) => 
    {
      setIsLoading(false);
      const { status, data } = response;

      if (status === 200) 
      {
        setSelectedDeal(data);

        if (selectedTab) 
        {
          setActiveTab(selectedTab);
        } 
        else 
        {
          setActiveTab("PFI");
        }
      } 
      else 
      {
        setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
        setAlertType("error");
        setShowAlert(true);
      }
    })
    .catch((error) => 
    {
      console.error("Generate Deal No. Api: ", error);
      setIsLoading(false);
      
      if (error?.response?.status === 403) 
      {
        logOut();
      }
      else
      {
        setAlertMessage(
          error?.response?.status === 429
            ? "Request limit exceeded. Please try again later."
            : "An error occurred while processing your request. Please try again later or contact the site administrator."
        );
        setAlertType("error");
        setShowAlert(true);
      }
    });
  }

  return (
    <div className = "d-flex flex-column gap-2 deal-no justify-content-center align-items-center">
      <button
        className = "btn btn-primary generate-button"
        onClick = {handleGenerateDealNo}
        disabled = {isLoading || !selectedBusinessUnit}
      >
        Generate Deal No.
      </button>

      <strong className = "d-flex flex-column justify-content-center align-items-center">
        OR
      </strong>

      <div className = "autocomplete dropdown">
        <VirtualizedAutocomplete
          isMultiple = {false}
          isObject = {true}
          filterOn = "Select Existing Deal No."
          options = {deals}
          selectedOptions = {selectedDeal}
          handleSelectChange = {(filterOn, newValue) => 
          {
            setSelectedDeal(newValue);

            if (newValue) 
            {
              setActiveTab("PFI");
            }
          }}
        />
      </div>
    </div>
  );
}

export default DealNo;