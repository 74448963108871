import { Navigate, Outlet, useLocation } from 'react-router-dom';

const ProtectedRoute = ({ isAllowed, redirectPath = '/login', children }) => 
{
    const { pathname } = useLocation();

    if (!isAllowed) 
    {
        return <Navigate to = {redirectPath} replace = {true} state = {{ path: pathname }} />;
    }

    return children ? children : <Outlet />;
}

export default ProtectedRoute;