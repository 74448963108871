import { memo, useEffect, useState } from 'react';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { RenderCustomCell } from './DataTableCustomCell';
import Button from '@mui/material/Button';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Tooltip from '@mui/material/Tooltip';

const ReportDataTable = ({ title = "", isLoading = false, isBusinessUnitSelected = false, isReportExportable = false, reportData = {}, handleExportRows = () => {} }) => 
{
    /* DATATABLE VARIABLES */
    const [columns, setColumns] = useState([]);
    const [data, setData] = useState([]);

    useEffect(() =>
    {
        const { columns: reportColumns = [], rows: reportRows = [], data_types: reportColumnDataTypes = {} } = reportData;
        const tempColumns = [];

        for (let i = 0; i < reportColumns.length; i++)
        {
            const column = reportColumns[i];
            const dataType = reportColumnDataTypes[column];
            const width = 150; // (parseInt(reportColumnWidths[column]) || 0) * 0.5 * 13;

            if (dataType.includes("object"))
            {
                tempColumns.push({
                    accessorKey: column,
                    header: column === "Deal No" ? "Deal No." : column,
                    size: width,
                    filterVariant: 'autocomplete',
                    Header: ({ column }) => (
                        <Tooltip title = {column.columnDef.header} arrow>
                            <div>{column.columnDef.header}</div>
                        </Tooltip>
                    ),
                    Cell: ({ column: col, row }) => {
                        const string = row.original?.[column];

                        return (
                            <RenderCustomCell 
                                value = {string}  
                                width = {col.getSize()} 
                                isNumber = {false} 
                            />
                        );
                    }
                });
            }
            else if (dataType.includes("int") || dataType.includes("float"))
            {
                tempColumns.push({
                    accessorKey: column,
                    header: column,
                    size: width,
                    filterVariant: 'range',
                    filterFn: 'between',
                    Header: ({ column }) => (
                        <Tooltip title = {column.columnDef.header} arrow>
                            <div>{column.columnDef.header}</div>
                        </Tooltip>
                    ),
                    Cell: ({ column: col, row }) => {
                        const number = isNaN(parseFloat(row.original?.[column])) ? null : parseFloat(row.original?.[column]);

                        return (
                            <RenderCustomCell 
                                value = {number}  
                                width = {col.getSize()} 
                                isNumber = {true} 
                            />
                        );
                    }
                });
            }
            else if (dataType.includes("datetime"))
            {
                tempColumns.push({
                    accessorFn: (originalRow) => new Date(originalRow[column]),
                    id: column,
                    header: column,
                    size: width,
                    filterVariant: 'date-range',
                    filterFn: 'betweenInclusive',
                    Header: ({ column }) => (
                        <Tooltip title = {column.columnDef.header} arrow>
                            <div>{column.columnDef.header}</div>
                        </Tooltip>
                    ),
                    Cell: ({ column: col, cell, row }) => {
                        const date = row.original[column] ? cell.getValue().toLocaleDateString() : null;
                        
                        return (
                            <RenderCustomCell 
                                value = {date}  
                                width = {col.getSize()} 
                                isNumber = {false} 
                            />
                        );
                    }
                });
            }
        }

        setColumns(tempColumns);
        setData(reportRows);
    }, [reportData]);

    const table = useMaterialReactTable({
        columns,
        data,
        enableFacetedValues: true,
        enableColumnResizing: true,
        enableDensityToggle: false,
        enableFullScreenToggle: false,
        enableColumnVirtualization: true,
        enableRowVirtualization: true,
        state: { showProgressBars: isLoading },
        columnVirtualizerOptions: { overscan: columns.length - 1 },
        columnFilterDisplayMode: 'popover',
        renderTopToolbarCustomActions: ({ table }) => (
            <>
                {isReportExportable && (
                    <Button
                        startIcon = {<FileDownloadIcon />}
                        disabled = {table.getPrePaginationRowModel().rows.length === 0}
                        onClick = {() => handleExportRows(table.getPrePaginationRowModel().rows)}
                    >
                        Export {title}
                    </Button>
                )}
            </>
        ),
        renderEmptyRowsFallback: () => (
            <p className = "empty-table">
                {isLoading ? "Loading data, please wait..." : isBusinessUnitSelected ? "No records to display." : "Select a business unit to display the table data."}
            </p>
        ),
        muiTableProps: {
            sx: {
                caption: {
                    captionSide: 'top'
                }
            }
        },
        muiTableContainerProps: {
            sx: { 
                height: 'calc(100vh - 285px)' 
            }
        },
        muiTableBodyProps: {
            sx: { 
                height: 'calc(100vh - 285px)' 
            }
        },
        muiTableHeadProps: {
            sx: {
                '& .MuiTableCell-head': {
                    paddingTop: 0
                }
            }
        },
        muiTableHeadCellProps: {
            sx: {
                border: '1px solid rgba(81, 81, 81, .5)',
                fontSize: '13px',
                paddingTop: 0,
                paddingBottom: 0
            }
        },
        muiTableBodyRowProps: ({ row }) => {
            const isTotalRow = row.original["Supplier Contract"]?.includes("Total");
            const backgroundColor = isTotalRow ? '#d7e5ef' : 'transparent';
            
            return {
                sx: {
                    height: '25px',
                    fontSize: '13px',
                    backgroundColor
                }
            };
        },
        muiTableBodyCellProps: ({ column, row }) => {
            const { 
                "Transaction Status": transactionStatusRaw, 
                "TAT Cust. PFI": tatCustPFI, 
                "Status Cust. LC": statusCustLCRaw,
                "Aging/TAT Cust. LC": agingTATCustLC,
                "Status Sup. LC": statusSupLCRaw,
                "Aging/TAT Sup. LC": agingTATSupLC,
                "Aging/TAT Sup. Docs (Copy)": agingTATSupDocsCopy,
                "Aging/TAT Cust. Docs (Copy)": agingTATCustDocsCopy,
                "Aging/TAT Sup. Docs (Original)": agingTATSupDocsOriginal,
                "Aging/TAT Cust. Docs (Original)": agingTATCustDocsOriginal,
                "Supplier Contract": supplierContract
            } = row.original;    
            const statusCustLC = statusCustLCRaw?.toLowerCase();
            const statusSupLC = statusSupLCRaw?.toLowerCase();
            const transactionStatus = transactionStatusRaw?.toLowerCase();

            let color = "black";
            let backgroundColor = "transparent";
            let fontWeight = "normal";
        
            if (column.id === "Transaction Status" && transactionStatus === "transaction completed") 
            {
                color = "white";
                backgroundColor = "#92D050";
            } 
            else if (column.id === "Status Cust. LC" || column.id === "Status Sup. LC") 
            {
                if (statusCustLC === "pending" || statusSupLC === "pending") 
                {
                    color = "#9C0006";
                    backgroundColor = "#FFC7CE";
                } 
                else if (statusCustLC === "received" || statusSupLC === "transmitted")
                {
                    color = "#006100";
                    backgroundColor = "#C6EFCE";
                }
            }
            else if (
                (column.id === "TAT Cust. PFI" && tatCustPFI > 1) || 
                (column.id === "Aging/TAT Cust. LC" && agingTATCustLC > 10) ||
                (column.id === "Aging/TAT Sup. LC" && agingTATSupLC > 3) ||
                (column.id === "Aging/TAT Sup. Docs (Copy)" && agingTATSupDocsCopy > 10) ||
                (column.id === "Aging/TAT Cust. Docs (Copy)" && agingTATCustDocsCopy > 11) ||
                (column.id === "Aging/TAT Sup. Docs (Original)" && agingTATSupDocsOriginal > 21) ||
                (column.id === "Aging/TAT Cust. Docs (Original)" && agingTATCustDocsOriginal > 30))
            {
                color = "white";
                backgroundColor = "red";
            }
            else if (supplierContract?.includes("Total"))
            {
                fontWeight = "600";
            }
        
            return {
                sx: {
                    border: '1px solid rgba(81, 81, 81, .5)',
                    color,
                    backgroundColor,
                    fontWeight
                }
            };
        },
        muiFilterAutocompleteProps: {
            sx: {
                '& .MuiAutocomplete-input': {
                    width: '100% !important'
                }
            }
        },
        muiLinearProgressProps: {
            sx: {
                color: 'secondary',
                variant: 'indeterminate'
            }
        },
        muiPaginationProps: {
            showRowsPerPage: false
        },
        initialState: { 
            density: 'compact',
            pagination: { pageSize: 20 }
        }
    });

    return (
        <div style = {{ height: '100%' }}>
            <MaterialReactTable table = {table} />
        </div>
    );
}

export default memo(ReportDataTable);